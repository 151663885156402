import { faAddressCard, faBuilding, faCalendar, faCircle, faClipboard, faUser, } from '@fortawesome/free-regular-svg-icons';
import { faAt, faBed, faCheck, faCircleMinus, faCirclePlus, faGlobe, faHashtag, faLocationDot, faMapLocationDot, faMobileScreen, faPassport, faPenClip, faPenToSquare, faPhone, faPlusCircle, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import axios from 'axios';

const Vendor = () => {

    const api = axiosRequestInterceptor(true);
    const storedSubmit = sessionStorage.getItem('submit');
    const [submitted, setSubmitted] = useState(storedSubmit ? storedSubmit : false);

    const storedStep = sessionStorage.getItem('step');
    const [step, setStep] = useState(storedStep ? Number(storedStep) : 0);

    const storedVendor = sessionStorage.getItem('vendor');
    const [vendor, setVendor] = useState(storedVendor ? Number(storedVendor) : 0);
    const [validate, setValidate] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const storedVendorState = JSON.parse(sessionStorage.getItem('vendorData'));
    const storedSelAddr = JSON.parse(sessionStorage.getItem('selAddr'));
    const storedStepTwoData = JSON.parse(sessionStorage.getItem('stepTwoData'));
    const storedStepThreeData = JSON.parse(sessionStorage.getItem('stepThreeData'));
    const storedStepFourData = JSON.parse(sessionStorage.getItem('stepFourData'));
    const storedVendorFiles = JSON.parse(sessionStorage.getItem('vendorFiles'));

    const vendorState = {
        surname: '', given_name: '', middle_name: '', diff_name_used: 'No', surname_diff: '', given_name_diff: '', middle_name_diff: '', title: '',
        address: '', phone: '', mobile: '', email: '', dob: '', aus_res: '', citizen: '', visa_name: '', visa_dob: '', passport_no: '', behalf_trust: '', trust_name: ''
    }

    const [vendorData, setVendorData] = useState(storedVendorState ? storedVendorState : [vendorState]);
    const [validVendor, setValidVendor] = useState(vendorState);
    const [addrResults, setAddrResults] = useState([]);
    const [autoAddress, setAutoAddress] = useState('');
    const [selAddr, setSelAddr] = useState(storedSelAddr ? storedSelAddr : { vendor1: null, vendor2: null, vendor3: null, vendor4: null, vendor5: null, agency: null, property: null });
    const storedSameAddr = sessionStorage.getItem('sameAddr');
    const [sameAddr, setSameAddr] = useState(storedSameAddr ? storedSameAddr : Array(5).fill(false));
    const [countries, setCountries] = useState([]);

    const stepTwoState = { agent_name: '', agent_mobile: '', agent_email: '', agency_name: '', agency_address: '', agency_email: '', agency_phone: '', prop_address: '', title_ref_known: '', volume: '', folio: '' }
    const [stepTwoData, setStepTwoData] = useState(storedStepTwoData ? storedStepTwoData : stepTwoState);
    const [validStepTwo, setValidStepTwo] = useState(stepTwoState);

    const stepThreeState = {
        property_type: '', resale_permission: '', sale_price_include_gst: '', no_bedrooms: '', material_of_building: '', swimming_pool: '', pool_permit_doc: false,
        fixtures_chattels: '', fixtures_chattels_list: Array(1).fill({ fix: '' }), special_condition_mentioned: '', special_condition: ''
    }
    const [stepThreeData, setStepThreeData] = useState(storedStepThreeData ? storedStepThreeData : stepThreeState);
    const [validStepThree, setValidStepThree] = useState({ ...stepThreeState, swimming_pool_permit: '' });

    const stepFourState = {
        mortgagee_bank: '', loan_account_no: Array(1).fill({ acnt: '' }), seven_years_old: '', additional_buildings: '', property_tenanted: '', occ_applicable: '', signature: '',
        council_rates: '', council_rates_doc: false, water_bill: '', water_bill_doc: false, building_permit: '', building_permit_doc: false,
        occupancy_certificate: '', occupancy_certificate_doc: false, building_warranty_insurance: '', building_warranty_insurance_doc: false,
        additional_buildings_approvals: '', additional_buildings_approvals_doc: false, building_permit_issued: '', building_permit_latest: '',
        building_permit_latest_doc: false, construction_by_owner: '', owner_builder_inspection: '', owner_builder_inspection_doc: false, warranty_insurance_owner: '',
        warranty_insurance_owner_doc: false, lease_agreement: '', lease_agreement_doc: false, oc_management_details: '', oc_management_details_doc: false
    }
    const [stepFourData, setStepFourData] = useState(storedStepFourData ? storedStepFourData : stepFourState);
    const [validStepFour, setValidStepFour] = useState(stepFourState);

    const vendorFilesState = {
        swimming_pool_permit: '', council_rates: '', water_bill: '', building_permit: '', occupancy_certificate: '',
        building_warranty_insurance: '', additional_buildings_approvals: '', building_permit_latest: '', owner_builder_inspection: '',
        warranty_insurance_owner: '', lease_agreement: '', oc_management_details: ''
    };
    const [vendorFiles, setVendorFiles] = useState(storedVendorFiles ? storedVendorFiles : vendorFilesState);

    const todayDate = moment().format('DD-MM-YYYY');

    const handleVendorChange = (e) => {
        const restData = vendorData[vendor];
        const { name, value } = e.target;
        let tempData = null;
        if (name === 'mobile') {
            let tempMob = '';
            if (value[0] === '+') {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 5 || value.length === 9) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...restData,
                [name]: tempMob
            }
        } else if (name === 'diff_name_used') {
            tempData = {
                ...restData,
                [name]: value, given_name_diff: '', middle_name_diff: '', surname_diff: ''
            }
        } else if (name === 'aus_res' && value === 'Australian citizen') {
            tempData = {
                ...restData,
                [name]: value,
                citizen: 'Australia'
            }
        } else if (name === 'citizen') {
            tempData = {
                ...restData,
                [name]: value, visa_name: '', visa_dob: '', passport_no: ''
            }
        } else if (name === 'behalf_trust') {
            tempData = {
                ...restData,
                [name]: value, trust_name: ''
            }
        } else {
            tempData = {
                ...restData,
                [name]: value
            }
        }
        const tempState = [...vendorData];
        tempState[vendor] = tempData;
        setVendorData(tempState);
    }

    const handleFiles = async (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const tempFiles = { ...vendorFiles }
            tempFiles[name] = files[0];
            setVendorFiles(tempFiles);
        } else {
            const tempFiles = { ...vendorFiles }
            tempFiles[name] = '';
            setVendorFiles(tempFiles);
        }
    };

    const fetchAddress = async (word) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ADDRESS_AUTO}text=${word}&filter=countrycode:au&format=json&apiKey=${process.env.REACT_APP_ADDRESS_API_KEY}`)
            if (response.data?.results?.length > 0) {
                setAddrResults(response.data?.results);
            }
        } catch (err) {
            console.error(err)
            setAddrResults([]);
        }
    }

    const handleAddressChange = (e, type) => {
        const tempData = { ...selAddr };
        const tempSameAddr = { ...sameAddr };
        if (type === 'vendor') {
            handleVendorChange(e);
            tempData[`vendor${vendor + 1}`] = null
            tempSameAddr[vendor] = false
        } else if (type === 'property') {
            handleStepTwoDataChange(e);
            tempData.property = null
        } else if (type === 'agency') {
            handleStepTwoDataChange(e);
            tempData.agency = null
        }
        setSameAddr(tempSameAddr);
        setSelAddr(tempData)
        if (e.target.value.length > 2) {
            fetchAddress(e.target.value);
        } else {
            setAddrResults([]);
        }
    }

    const addAddress = (address, type) => {
        let tempAddr = null;
        const tempSameAddr = { ...sameAddr };
        if (type === 'vendor') {
            const tempData = vendorData[vendor];
            tempData.address = `${address.address_line1}, ${address.address_line2}`
            const tempState = [...vendorData];
            tempState[vendor] = tempData;
            setVendorData(tempState);
            tempAddr = { ...selAddr };
            tempAddr[`vendor${vendor + 1}`] = address;
            tempSameAddr[vendor] = false
        } else if (type === 'property') {
            const tempData = stepTwoData;
            tempData.prop_address = `${address.address_line1}, ${address.address_line2}`
            setStepTwoData(tempData);
            tempAddr = { ...selAddr };
            tempAddr.property = address;
        } else if (type === 'agency') {
            const tempData = stepTwoData;
            tempData.agency_address = `${address.address_line1}, ${address.address_line2}`
            setStepTwoData(tempData);
            tempAddr = { ...selAddr };
            tempAddr.agency = address;
        }
        setSameAddr(tempSameAddr);
        setSelAddr(tempAddr);
        setAddrResults([]);
        setAutoAddress('');
    }

    const handleSameAddr = () => {
        const tempData = [...vendorData];
        const tempSelAddr = { ...selAddr };
        const tempSameAddr = { ...sameAddr };
        if (sameAddr[vendor]) {
            tempData[vendor].address = '';
            setVendorData(tempData);
            tempSelAddr[`vendor${vendor - 1}`] = null;
            tempSameAddr[vendor] = false
        } else {
            tempData[vendor].address = tempData[0].address;
            setVendorData(tempData);
            tempSelAddr[`vendor${vendor - 1}`] = tempSelAddr.vendor1;
            tempSameAddr[vendor] = true
        }
        sessionStorage.setItem('sameAddr', tempSameAddr);
        setSelAddr(tempSelAddr);
        setSameAddr(tempSameAddr);
    }

    const addVendor = () => {
        if (vendorData.length !== 5) {
            if (validVendor.error) {
                setValidate(true);
            } else {
                const tempValue = vendorData.length;
                const tempState = [...vendorData, vendorState];
                setVendorData(tempState);
                sessionStorage.setItem('vendor', tempValue);
                setVendor(tempValue);
                setValidate(false);
            }
        }
    }

    const removeVendor = () => {
        if (vendorData.length > 1) {
            const tempValue = vendorData.length;
            const tempState = vendorData.filter((item, index) => index !== vendor);
            setVendorData(tempState);
            sessionStorage.setItem('vendor', tempValue - 2);
            setVendor(tempValue - 2);
            setValidate(false);
        }
    }

    const validateVendor = () => {
        if (validVendor.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(1);
            sessionStorage.setItem('step', 1);
        }
    }

    const handleStepTwoDataChange = (e) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'agent_mobile') {
            let tempMob = '';
            if (value[0] === '+') {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 5 || value.length === 9) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...stepTwoData,
                [name]: tempMob
            }
        } else if (name === 'title_ref_known') {
            tempData = {
                ...stepTwoData,
                [name]: value,
                volume: '',
                folio: ''
            }
        } else {
            tempData = {
                ...stepTwoData,
                [name]: value
            }
        }
        setStepTwoData(tempData);
    }

    const handleStepThreeDataChange = (e, index) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'property_type') {
            tempData = {
                ...stepThreeData,
                [name]: value,
                resale_permission: ''
            }
        } else if (name === 'fixtures_chattels') {
            tempData = {
                ...stepThreeData,
                [name]: value,
                fixtures_chattels_list: Array(1).fill({ fix: '' })
            }
        } else if (name === 'fixtures_chattels_list') {
            const tempObj = { fix: value }
            const tempArr = stepThreeData[name];
            tempArr[index] = tempObj;
            tempData = {
                ...stepThreeData,
                tempArr
            }
        } else if (name === 'special_condition_mentioned') {
            tempData = {
                ...stepThreeData,
                [name]: value,
                special_condition: ''
            }
        } else if (name === 'pool_permit_doc') {
            tempData = {
                ...stepThreeData,
                [name]: !stepThreeData.pool_permit_doc,
            }
        } else {
            tempData = {
                ...stepThreeData,
                [name]: value
            }
        }
        setStepThreeData(tempData);
    }

    const addList = () => {
        const tempData = [...stepThreeData.fixtures_chattels_list];
        tempData.push({ fix: '' });
        setStepThreeData({ ...stepThreeData, fixtures_chattels_list: tempData });
    }

    const removeFromList = (ind) => {
        if (Number(ind) !== 0) {
            const tempData = stepThreeData.fixtures_chattels_list.filter((item, index) => Number(ind) !== index);
            setStepThreeData({ ...stepThreeData, fixtures_chattels_list: tempData });
        }
    }

    const handleStepFourDataChange = (e, index) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'mortgagee_bank') {
            tempData = {
                ...stepFourData,
                [name]: value,
                loan_account_no: Array(1).fill({ acnt: '' })
            }
        } else if (name === 'loan_account_no') {
            const tempObj = { acnt: value };
            const tempArr = stepFourData[name];
            tempArr[index] = tempObj;
            tempData = {
                ...stepFourData,
                tempArr
            }
        } else if (name === 'council_rates_doc' || name === 'water_bill_doc' || name === 'building_permit_doc' || name === 'occupancy_certificate_doc' ||
            name === 'building_warranty_insurance_doc' || name === 'additional_buildings_approvals_doc' || name === 'lease_agreement_doc'
            || name === 'oc_management_details_doc' || name === 'building_permit_latest_doc' || name === 'owner_builder_inspection_doc' ||
            name === 'warranty_insurance_owner_doc') {
            tempData = {
                ...stepFourData,
                [name]: !stepFourData[name],
            }
        } else {
            tempData = {
                ...stepFourData,
                [name]: value
            }
        }
        setStepFourData(tempData);
    }

    const addAccntNumber = () => {
        const tempData = [...stepFourData.loan_account_no];
        tempData.push({ acnt: '' });
        setStepFourData({ ...stepFourData, loan_account_no: tempData });
    }

    const removeAccntNumber = (ind) => {
        if (Number(ind) !== 0) {
            const tempData = stepFourData.loan_account_no.filter((item, index) => Number(ind) !== index);
            setStepFourData({ ...stepFourData, loan_account_no: tempData });
        }
    }

    const goToStepOne = () => {
        setStep(0);
        setVendor(0);
        sessionStorage.setItem('step', 0);
        sessionStorage.setItem('vendor', 0);
    }

    const validateStepTwo = () => {
        if (validStepTwo.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(2);
            sessionStorage.setItem('step', 2);
        }
    }

    const goToStep = (num) => {
        setStep(num);
        sessionStorage.setItem('step', num);
    }

    const validateStepThree = () => {
        if (validStepThree.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(3);
            sessionStorage.setItem('step', 3);
        }
    }

    const submitData = async () => {
        try {
            setSubmitting(true);
            const convertedData = vendorData.map((item, index) => {
                return {
                    ...item,
                    vendorIndex: index + 1
                };
            })
            const payload = {
                vendorDetails: convertedData,
                ...stepTwoData,
                ...stepThreeData,
                ...stepFourData,
                filled_date: todayDate
            }
            const formData = new FormData();
            formData.append('data', JSON.stringify(payload));
            Object.keys(vendorFiles).forEach((fileType) => {
                if (vendorFiles[fileType]) {
                    formData.append(fileType, vendorFiles[fileType]);
                }
            });
            await api.post('/prospects/vendorForm', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            setValidate(false);
            setSubmitted(true);
            setSubmitting(false);
            sessionStorage.setItem('submit', true);
        } catch (err) {
            setSubmitting(false);
            console.error(err);
            if (err?.response?.data?.error) {
                setErrMsg(err.response.data.error);
            } else {
                setErrMsg('Failed to connect with server');
            }
        }
    }

    const validateStepFour = () => {
        if (validStepFour.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(4);
            sessionStorage.setItem('step', 4);
        }
    }

    const editVendor = (ven) => {
        if (validVendor.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(0);
            setVendor(ven);
            sessionStorage.setItem('vendor', ven);
            sessionStorage.setItem('step', 0);
        }
    }

    useEffect(() => {
        sessionStorage.setItem('vendorData', JSON.stringify(vendorData));
        sessionStorage.setItem('selAddr', JSON.stringify(selAddr));
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
        ];
        const phonePattern = /^[+0-9][0-9]{7,10}$/;
        let error = false;
        let surname = '';
        let given_name = '';
        let surname_diff = '';
        let given_name_diff = '';
        let title = '';
        let address = '';
        let phone = '';
        let mobile = '';
        let email = '';
        let dob = '';
        let aus_res = '';
        let citizen = '';
        let behalf_trust = '';
        let trust_name = '';

        if (!vendorData[vendor].surname) {
            error = true;
            surname = 'Surname is required';
        } else if (vendorData[vendor].surname.length < 2) {
            error = true;
            surname = 'Surname should contain more than 1 Character';
        }

        if (!vendorData[vendor].given_name) {
            error = true;
            given_name = 'Given name is required';
        } else if (vendorData[vendor].given_name.length < 3) {
            error = true;
            given_name = 'Given name should contain more than 2 Characters';
        }

        if (vendorData[vendor].diff_name_used === 'Yes' && !vendorData[vendor].surname_diff) {
            error = true;
            surname_diff = 'Surname is required';
        } else if (vendorData[vendor].diff_name_used === 'Yes' && vendorData[vendor].surname_diff.length < 2) {
            error = true;
            surname_diff = 'Surname should contain more than 1 Character';
        }

        if (vendorData[vendor].diff_name_used === 'Yes' && !vendorData[vendor].given_name_diff) {
            error = true;
            given_name_diff = 'Given name is required';
        } else if (vendorData[vendor].diff_name_used === 'Yes' && vendorData[vendor].given_name_diff.length < 2) {
            error = true;
            given_name_diff = 'Given name should contain more than 1 Character';
        }

        if (!vendorData[vendor].title) {
            error = true;
            title = 'Title is required';
        }

        if (!vendorData[vendor].address) {
            error = true;
            address = 'Address is required';
        } else if (vendorData[vendor].address.length < 3) {
            error = true;
            address = 'Invalid address';
        }

        if (vendorData[vendor].phone && !phonePattern.test(vendorData[vendor].phone)) {
            error = true;
            phone = 'Phone number is invalid'
        }

        if (!vendorData[vendor].mobile) {
            error = true;
            mobile = 'Mobile number is required'
        } else if (!mobilePatterns.some(pattern => pattern.test(vendorData[vendor].mobile))) {
            error = true;
            mobile = 'Mobile number is invalid'
        }

        if (!vendorData[vendor].email) {
            error = true;
            email = 'Email address is required'
        } else if (!emailPattern.test(vendorData[vendor].email)) {
            error = true;
            email = 'Email address is invalid'
        }

        if (!vendorData[vendor].dob) {
            error = true;
            dob = 'Date of birth is required';
        }

        if (!vendorData[vendor].aus_res) {
            error = true;
            aus_res = 'Option is required';
        }

        if (!vendorData[vendor].citizen) {
            error = true;
            citizen = 'Country of Citizenship is required';
        } else if (!countries.includes(vendorData[vendor].citizen)) {
            error = true;
            citizen = 'Invalid country name (select from the list)';
        }

        if (!vendorData[vendor].behalf_trust) {
            error = true;
            behalf_trust = 'Option is required';
        }

        if (vendorData[vendor].behalf_trust === 'Yes' && !vendorData[vendor].trust_name) {
            error = true;
            trust_name = 'Trust name is required';
        }

        setValidVendor({ error, surname, given_name, surname_diff, given_name_diff, title, address, phone, mobile, email, dob, aus_res, citizen, behalf_trust, trust_name })

    }, [vendorData, selAddr, countries]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepTwoData', JSON.stringify(stepTwoData));
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
        ];
        const phonePattern = /^[+0-9][0-9]{7,10}$/;
        let error = false;
        let agent_name = '';
        let agent_mobile = '';
        let agent_email = '';
        let agency_name = '';
        let agency_address = '';
        let agency_email = '';
        let agency_phone = '';
        let prop_address = '';
        let title_ref_known = '';

        if (!stepTwoData.agent_name) {
            error = true;
            agent_name = 'Agent name is required';
        } else if (stepTwoData.agent_name.length < 3) {
            error = true;
            agent_name = 'Agent name should contain more than 2 Characters';
        }

        if (!stepTwoData.agent_mobile) {
            error = true;
            agent_mobile = 'Mobile number is required';
        } else if (!mobilePatterns.some(pattern => pattern.test(stepTwoData.agent_mobile))) {
            error = true;
            agent_mobile = 'Mobile number is invalid';
        }

        if (!stepTwoData.agent_email) {
            error = true;
            agent_email = 'Email address is required'
        } else if (!emailPattern.test(stepTwoData.agent_email)) {
            error = true;
            agent_email = 'Email address is invalid'
        }

        if (!stepTwoData.agency_name) {
            error = true;
            agency_name = 'Agency name is required';
        } else if (stepTwoData.agency_name.length < 3) {
            error = true;
            agency_name = 'Agency name should contain more than 2 Characters';
        }

        if (!stepTwoData.agency_address) {
            error = true;
            agency_address = 'Agency address is required';
        } else if (stepTwoData.agency_address.length < 3) {
            error = true;
            agency_address = 'Agency address should contain more than 2 Characters';
        }

        if (!stepTwoData.agency_email) {
            error = true;
            agency_email = 'Email address is required'
        } else if (!emailPattern.test(stepTwoData.agency_email)) {
            error = true;
            agency_email = 'Email address is invalid'
        }

        if (stepTwoData.agency_phone && !phonePattern.test(stepTwoData.agency_phone)) {
            error = true;
            agency_phone = 'Phone number is invalid'
        }

        if (!stepTwoData.prop_address) {
            error = true;
            prop_address = 'Address is required';
        } else if (stepTwoData.prop_address.length < 3) {
            error = true;
            prop_address = 'Invalid address';
        }

        if (!stepTwoData.title_ref_known) {
            error = true;
            title_ref_known = 'Option is required';
        }

        setValidStepTwo({ error, agent_name, agent_mobile, agent_email, agency_name, agency_address, agency_email, agency_phone, prop_address, title_ref_known })

    }, [stepTwoData, selAddr]) //eslint-disable-line

    useEffect(() => {
        const fetchCountries = async () => {
            try{
                const response = await axios.get(`${process.env.REACT_APP_COUNTRIES}`);
                const results = response.data?.map(item => item.name.common);
                results.sort();
                setCountries(results);
            } catch(err){
                console.error(err);
                setCountries(['Australia']);
            }
        }
        fetchCountries();
    }, []) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepThreeData', JSON.stringify(stepThreeData));
        let error = '';
        let property_type = '';
        let resale_permission = '';
        let sale_price_include_gst = '';
        let no_bedrooms = '';
        let material_of_building = '';
        let swimming_pool = '';
        let swimming_pool_permit = '';
        let fixtures_chattels = '';
        let fixtures_chattels_list = [];
        let special_condition_mentioned = '';
        let special_condition = '';

        if (!stepThreeData.property_type) {
            error = true;
            property_type = 'Property Type is required';
        }

        if (stepThreeData.property_type === 'Vacant Land' && !stepThreeData.resale_permission) {
            error = true;
            resale_permission = 'Option is required';
        }

        if (!stepThreeData.sale_price_include_gst) {
            error = true;
            sale_price_include_gst = 'Option is required';
        }

        if (!stepThreeData.no_bedrooms) {
            error = true;
            no_bedrooms = 'Number of bedrooms is required';
        } else if (stepThreeData.no_bedrooms.length < 0) {
            error = true;
            no_bedrooms = 'Number of bedrooms must be positive integer';
        }

        if (!stepThreeData.material_of_building) {
            error = true;
            material_of_building = 'Material is required';
        }

        if (!stepThreeData.swimming_pool) {
            error = true;
            swimming_pool = 'Option is required';
        }

        if (stepThreeData.swimming_pool === 'Yes' && !stepThreeData.pool_permit_doc && !vendorFiles.swimming_pool_permit) {
            error = true;
            swimming_pool_permit = 'File is required';
        }

        if (!stepThreeData.fixtures_chattels) {
            error = true;
            fixtures_chattels = 'Option is required';
        }

        Object.keys(stepThreeData.fixtures_chattels_list).forEach(key => {
            if (stepThreeData.fixtures_chattels === 'Yes' && stepThreeData.fixtures_chattels_list[key] && stepThreeData.fixtures_chattels_list[key].length < 3) {
                error = true;
                fixtures_chattels_list[key] = 'Item name should contain more than 2 Characters'
            }
        })

        if (!stepThreeData.special_condition_mentioned) {
            error = true;
            special_condition_mentioned = 'Option is required';
        }

        if (stepThreeData.special_condition_mentioned === 'Yes' && !stepThreeData.special_condition) {
            error = true;
            special_condition = 'Condition is required';
        }

        setValidStepThree({
            error, property_type, resale_permission, sale_price_include_gst, no_bedrooms, material_of_building, swimming_pool, swimming_pool_permit, fixtures_chattels,
            fixtures_chattels_list, special_condition_mentioned, special_condition
        })

    }, [stepThreeData, vendorFiles]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepFourData', JSON.stringify(stepFourData));
        let error = '';
        let mortgagee_bank = '';
        let loan_account_no = [];
        let council_rates = '';
        let water_bill = '';
        let seven_years_old = '';
        let building_permit = '';
        let occupancy_certificate = '';
        let building_warranty_insurance = '';
        let additional_buildings = '';
        let additional_buildings_approvals = '';
        let building_permit_issued = ''
        let building_permit_latest = ''
        let construction_by_owner = ''
        let owner_builder_inspection = ''
        let warranty_insurance_owner = ''
        let property_tenanted = '';
        let lease_agreement = '';
        let occ_applicable = '';
        let oc_management_details = '';
        let signature = '';

        if (!stepFourData.mortgagee_bank) {
            error = true;
            mortgagee_bank = 'Option is required';
        }

        Object.keys(stepFourData.loan_account_no).forEach(key => {
            if (stepFourData.mortgagee_bank === 'Yes' && !stepFourData.loan_account_no[key]) {
                error = true;
                loan_account_no[key] = 'Account number is required'
            } else if (stepFourData.mortgagee_bank === 'Yes' && stepFourData.loan_account_no[key].length < 3) {
                error = true;
                loan_account_no[key] = 'Account number should contain more than 2 Characters'
            }
        })

        if (!vendorFiles.council_rates && !stepFourData.council_rates_doc) {
            error = true;
            council_rates = 'File is required';
        }

        if (!vendorFiles.water_bill && !stepFourData.water_bill_doc) {
            error = true;
            water_bill = 'File is required';
        }

        if (!stepFourData.seven_years_old) {
            error = true;
            seven_years_old = 'File is required';
        }

        if (stepFourData.seven_years_old === 'No' && !vendorFiles.building_permit && !stepFourData.building_permit_doc) {
            error = true;
            building_permit = 'File is required';
        }

        if (stepFourData.seven_years_old === 'No' && !vendorFiles.occupancy_certificate && !stepFourData.occupancy_certificate_doc) {
            error = true;
            occupancy_certificate = 'File is required';
        }

        if (stepFourData.seven_years_old === 'No' && !vendorFiles.building_warranty_insurance && !stepFourData.building_warranty_insurance_doc) {
            error = true;
            building_warranty_insurance = 'File is required';
        }

        if (!stepFourData.additional_buildings) {
            error = true;
            additional_buildings = 'Option is required';
        }

        if (stepFourData.additional_buildings === 'Yes' && !vendorFiles.additional_buildings_approvals && !stepFourData.additional_buildings_approvals_doc) {
            error = true;
            additional_buildings_approvals = 'File is required';
        }

        if (!stepFourData.building_permit_issued) {
            error = true;
            building_permit_issued = 'Option is required';
        }

        if (stepFourData.building_permit_issued === 'Yes' && !vendorFiles.building_permit_latest && !stepFourData.building_permit_latest_doc) {
            error = true;
            building_permit_latest = 'File is required';
        }

        if (!stepFourData.construction_by_owner) {
            error = true;
            construction_by_owner = 'Option is required';
        }

        if (stepFourData.construction_by_owner === 'Yes' && !vendorFiles.owner_builder_inspection && !stepFourData.owner_builder_inspection_doc) {
            error = true;
            owner_builder_inspection = 'File is required';
        }

        if (stepFourData.construction_by_owner === 'Yes' && !vendorFiles.warranty_insurance_owner && !stepFourData.warranty_insurance_owner_doc) {
            error = true;
            warranty_insurance_owner = 'File is required';
        }

        if (!stepFourData.property_tenanted) {
            error = true;
            property_tenanted = 'Option is required';
        }

        if (stepFourData.property_tenanted === 'Yes' && !vendorFiles.lease_agreement && !stepFourData.lease_agreement_doc) {
            error = true;
            lease_agreement = 'File is required';
        }

        if (!stepFourData.occ_applicable) {
            error = true;
            occ_applicable = 'Option is required';
        }

        if (stepFourData.occ_applicable === 'Yes' && !vendorFiles.oc_management_details && !stepFourData.oc_management_details_doc) {
            error = true;
            oc_management_details = 'File is required';
        }

        if (!stepFourData.signature) {
            error = true;
            signature = 'Full name is required';
        }

        setValidStepFour({
            error, mortgagee_bank, loan_account_no, council_rates, water_bill, seven_years_old, building_permit, occupancy_certificate, building_warranty_insurance,
            additional_buildings, additional_buildings_approvals, building_permit_issued, building_permit_latest, construction_by_owner, owner_builder_inspection,
            warranty_insurance_owner, property_tenanted, lease_agreement, occ_applicable, oc_management_details, signature
        })

    }, [stepFourData, vendorFiles]); //eslint-disable-line

    useEffect(() => {

        if (vendorFiles.swimming_pool_permit) {
            const tempState = { ...stepThreeData }
            tempState.swimming_pool_permit = vendorFiles.swimming_pool_permit.name;
            setStepThreeData(tempState);
        } else {
            const tempState = { ...stepThreeData }
            tempState.swimming_pool_permit = '';
            setStepThreeData(tempState);
        }

        const tempData = { ...stepFourData }

        if (vendorFiles.council_rates) {
            tempData.council_rates = vendorFiles.council_rates.name;
        } else {
            tempData.council_rates = ''
        }

        if (vendorFiles.water_bill) {
            tempData.water_bill = vendorFiles.water_bill.name;
        } else {
            tempData.water_bill = ''
        }

        if (vendorFiles.building_permit) {
            tempData.building_permit = vendorFiles.building_permit.name;
        } else {
            tempData.building_permit = ''
        }

        if (vendorFiles.occupancy_certificate) {
            tempData.occupancy_certificate = vendorFiles.occupancy_certificate.name;
        } else {
            tempData.occupancy_certificate = ''
        }

        if (vendorFiles.building_warranty_insurance) {
            tempData.building_warranty_insurance = vendorFiles.building_warranty_insurance.name;
        } else {
            tempData.building_warranty_insurance = ''
        }

        if (vendorFiles.additional_buildings_approvals) {
            tempData.additional_buildings_approvals = vendorFiles.additional_buildings_approvals.name;
        } else {
            tempData.additional_buildings_approvals = ''
        }

        if (vendorFiles.building_permit_latest) {
            tempData.building_permit_latest = vendorFiles.building_permit_latest.name;
        } else {
            tempData.building_permit_latest = ''
        }

        if (vendorFiles.owner_builder_inspection) {
            tempData.owner_builder_inspection = vendorFiles.owner_builder_inspection.name;
        } else {
            tempData.owner_builder_inspection = ''
        }

        if (vendorFiles.warranty_insurance_owner) {
            tempData.warranty_insurance_owner = vendorFiles.warranty_insurance_owner.name;
        } else {
            tempData.warranty_insurance_owner = ''
        }

        if (vendorFiles.lease_agreement) {
            tempData.lease_agreement = vendorFiles.lease_agreement.name;
        } else {
            tempData.lease_agreement = ''
        }

        if (vendorFiles.oc_management_details) {
            tempData.oc_management_details = vendorFiles.oc_management_details.name;
        } else {
            tempData.oc_management_details = ''
        }

        setStepFourData(tempData);

    }, [vendorFiles]); //eslint-disable-line

    useEffect(() => {
        if (stepThreeData.swimming_pool === 'Yes' && !stepThreeData.pool_permit_doc && !vendorFiles.swimming_pool_permit) {
            setValidate(true);
            setStep(2);
            sessionStorage.setItem('step', 2);
        } else if (((!vendorFiles.council_rates && !stepFourData.council_rates_doc) || (!vendorFiles.water_bill && !stepFourData.water_bill_doc)) && step === 4) {
            setValidate(true);
            setStep(3);
            sessionStorage.setItem('step', 3);
        }
    }, []);  //eslint-disable-line

    return (
        submitted ? (
            <div className='submit-page'>
                <span className='fw-bold'>Thank you for providing this information. Feel free to contact us if you wish to discuss further.</span>
            </div>) :
            submitting ? (
                <div className='submit-page'>
                    <span className='fw-bold'>Submitting the data, please wait.</span>
                </div>
            ) : (<>
                <div className='card mx-1 px-2 my-3 mx-md-5 px-md-5 py-4'>
                    <div className='fs-5 fw-bold text-center mb-3'>Vendor Details</div>
                    <div className='d-flex justify-content-between gap-2 mx-md-5'>
                        <div className='steps'>
                            <div className='step-box-active mb-1' />
                            Step 1: Personal Information
                        </div>
                        <div className='steps'>
                            <div className={`${step > 0 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 2: Real estate listing details
                        </div>
                        <div className='steps'>
                            <div className={`${step > 1 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 3: Property details
                        </div>
                        <div className='steps'>
                            <div className={`${step > 2 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 4: Others
                        </div>
                        <div className='steps'>
                            <div className={`${step > 3 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 5: Review
                        </div>
                    </div>
                </div>
                {step === 0 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    {window.innerWidth < 768 && <div className='fw-bold'>Vendor Details</div>}
                    <div className='mb-3'>
                        <span className={`fw-bold pb-1 me-5 ${vendor === 0 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editVendor(0)}>
                            {window.innerWidth < 768 ? '1st' : 'First Vendor Details'}
                        </span>
                        {vendorData.length > 1 && <span className={`fw-bold pb-1 me-5 ${vendor === 1 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editVendor(1)}>
                            {window.innerWidth < 768 ? '2nd' : 'Second Vendor Details'}</span>}
                        {vendorData.length > 2 && <span className={`fw-bold pb-1 me-5 ${vendor === 2 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editVendor(2)}>
                            {window.innerWidth < 768 ? '3rd' : 'Third Vendor Details'}</span>}
                        {vendorData.length > 3 && <span className={`fw-bold pb-1 me-5 ${vendor === 3 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editVendor(3)}>
                            {window.innerWidth < 768 ? '4th' : 'Fourth Vendor Details'}</span>}
                        {vendorData.length > 4 && <span className={`fw-bold pb-1 ${vendor === 4 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editVendor(4)}>
                            {window.innerWidth < 768 ? '5th' : 'Fifth Vendor Details'}</span>}
                    </div>
                    <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>First Name / Given Name *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <select name='title' value={vendorData[vendor].title} className='pro-input mx-2' onChange={handleVendorChange} onFocus={() => setAutoAddress('')}>
                                    <option disabled value=''></option>
                                    <option value='Mr'>Mr</option>
                                    <option value='Mrs'>Mrs</option>
                                    <option value='Ms'>Ms</option>
                                    <option value='Dr'>Dr</option>
                                </select>
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='given_name'
                                    value={vendorData[vendor].given_name || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='First Name / Given Name' />

                            </div>
                            {validate && <div className='newUi-err'>{validVendor.title ? validVendor.title : validVendor.given_name}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Middle Name</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='middle_name'
                                    value={vendorData[vendor].middle_name || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Middle Name (if any)' />
                            </div>
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Surname *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='surname'
                                    value={vendorData[vendor].surname || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Surname' />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.surname}</div>}
                        </div>
                    </div>
                    <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>Email *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faAt} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='email'
                                    value={vendorData[vendor].email || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Email' />

                            </div>
                            {validate && <div className='newUi-err'>{validVendor.email}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Mobile Number *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faMobileScreen} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='mobile'
                                    value={vendorData[vendor].mobile || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Mobile' />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.mobile}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Date of Birth *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faCalendar} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='date'
                                    name='dob'
                                    value={vendorData[vendor].dob || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')} />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.dob}</div>}
                        </div>
                    </div>
                    <div className='pf-row'>
                        <div className='pf-label'>Phone Number</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faPhone} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='phone'
                                value={vendorData[vendor].phone || ''}
                                onChange={handleVendorChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Phone number' />
                        </div>
                        {validate && <div className='newUi-err'>{validVendor.phone}</div>}
                    </div>
                    <div className='pf-row w-100 position-relative'>
                        <label className='pf-label'>Address *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='address'
                                value={vendorData[vendor].address || ''}
                                onChange={(e) => handleAddressChange(e, 'vendor')}
                                onFocus={() => setAutoAddress('vendor')}
                                autoComplete='off'
                                placeholder='Address' />
                        </div>
                        {autoAddress === 'vendor' && vendorData[vendor].address?.length > 0 && <div className='addr-sugg'>
                            {addrResults.length > 0 ? addrResults.map((item, index) => <div key={index} className='sugg' onClick={() => addAddress(item, 'vendor')}>{`${item.address_line1}, ${item.address_line2}`}</div>)
                                : <div className='sugg'>No address found</div>}
                        </div>}
                        <div className='w-100 d-flex align-items-center'>
                            {validate && <div className='newUi-err'>{validVendor.address}</div>}
                            {vendor !== 0 && <>
                                <input type='checkbox' className='mx-2' checked={sameAddr[vendor]} onChange={handleSameAddr} />
                                <span className='pf-label'>Add same address as first vendor</span></>}
                        </div>
                    </div>
                    <div className='pf-row mt-2'>
                        <label className='pf-label'>What is your citizenship status ? Please choose from below</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <select name='aus_res' value={vendorData[vendor].aus_res} className='pro-input w-100 mx-2' onChange={handleVendorChange} onFocus={() => setAutoAddress('')}>
                                <option disabled value=''>Choose any option</option>
                                <option value='Australian citizen'>Australian citizen</option>
                                <option value='Australian Permanent Resident'>Australian Permanent Resident</option>
                                <option value='New Zealand Citizen holding Special Catgeory Visa'>New Zealand Citizen holding Special Catgeory Visa</option>
                                <option value='Foreign Natural Person'>Foreign Natural Person</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validVendor.aus_res}</div>}
                    </div>
                    <div className='pf-row'>
                        <div className='pf-label'>Country of Citizenship *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faGlobe} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='citizen'
                                list='countries'
                                value={vendorData[vendor].citizen || ''}
                                onChange={handleVendorChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Country of citizenship' />
                            <datalist id='countries'>
                                {countries?.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </datalist>
                        </div>
                        {validate && <div className='newUi-err'>{validVendor.citizen}</div>}
                    </div>
                    {!validVendor.citizen && vendorData[vendor].citizen && vendorData[vendor].citizen !== 'Australia' && <>
                        <div className='d-md-flex w-100 gap-4 pf-row'>
                            <div className='prospect-field'>
                                <label className='pf-label'>First Name / Given Name as per Visa</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faUser} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='visa_name'
                                        value={vendorData[vendor].visa_name || ''}
                                        onChange={handleVendorChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Name as per the Visa' />

                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Date of Birth as per Visa</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='date'
                                        name='visa_dob'
                                        value={vendorData[vendor].visa_dob || ''}
                                        onFocus={() => setAutoAddress('')}
                                        onChange={handleVendorChange} />
                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Passport Number</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faPassport} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='passport_no'
                                        value={vendorData[vendor].passport_no || ''}
                                        onChange={handleVendorChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Passport number' />
                                </div>
                            </div>
                        </div></>}
                    <div className='d-flex w-100 gap-4 align-items-center'>
                        <span className='pf-label res-width'>Have you ever used a different name? </span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='diff_name_used_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${vendorData[vendor].diff_name_used === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='diff_name_used' id='diff_name_used_yes' onChange={handleVendorChange} />
                                {vendorData[vendor].diff_name_used === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='diff_name_used_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${vendorData[vendor].diff_name_used === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='diff_name_used' id='diff_name_used_no' onChange={handleVendorChange} />
                                {vendorData[vendor].diff_name_used === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                    </div>
                    {vendorData[vendor].diff_name_used === 'Yes' && <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>First Name / Given Name *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='given_name_diff'
                                    value={vendorData[vendor].given_name_diff || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='First name / Given Name' />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.given_name_diff}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Middle Name</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='middle_name_diff'
                                    value={vendorData[vendor].middle_name_diff || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Middle Name (if any)' />
                            </div>
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Surname *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='surname_diff'
                                    value={vendorData[vendor].surname_diff || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Surname' />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.surname_diff}</div>}
                        </div>
                    </div>}
                    <div className='d-flex w-100 gap-4 align-items-center mt-2'>
                        <span className='pf-label res-width'>Are you acting behalf of a trust? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='behalf_trust_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${vendorData[vendor].behalf_trust === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='behalf_trust' id='behalf_trust_yes' onChange={handleVendorChange} />
                                {vendorData[vendor].behalf_trust === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='behalf_trust_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${vendorData[vendor].behalf_trust === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='behalf_trust' id='behalf_trust_no' onChange={handleVendorChange} />
                                {vendorData[vendor].behalf_trust === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err'>{validVendor.behalf_trust}</div>}
                    </div>
                    {vendorData[vendor].behalf_trust === 'Yes' &&
                        <div className='pf-row'>
                            <label className='pf-label'>I am acting trustee for *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faAddressCard} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='trust_name'
                                    value={vendorData[vendor].trust_name || ''}
                                    onChange={handleVendorChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Trust Name' />
                            </div>
                            {validate && <div className='newUi-err'>{validVendor.trust_name}</div>}
                        </div>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        {vendorData.length !== 5 && <button className='au-btn res-width-3 my-2' onClick={addVendor}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <span className='ms-2'>Add Vendor</span>
                        </button>}
                        {vendorData.length !== 1 && <button className='au-btn res-width-3 my-2' onClick={removeVendor}>
                            <FontAwesomeIcon icon={faCircleMinus} />
                            <span className='ms-2'>Remove Vendor</span>
                        </button>}
                        <button className='au-btn my-2 res-width-3 ms-auto' onClick={validateVendor}>
                            Next Step
                        </button>
                    </div>
                    </>}
                {step === 1 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='d-flex w-100 align-items-center my-3'>
                        <span className='pf-label fw-bold'>Details of the Real estate Agency</span>
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Name of the Real Estate Agent *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agent_name'
                                value={stepTwoData.agent_name || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Name of the agent' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agent_name}</div>}
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Agent Mobile *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faMobileScreen} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agent_mobile'
                                value={stepTwoData.agent_mobile || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Mobile number' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agent_mobile}</div>}
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Direct email *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faAt} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agent_email'
                                value={stepTwoData.agent_email || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Email' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agent_email}</div>}
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Name of the real estate Agency *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agency_name'
                                value={stepTwoData.agency_name || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Name of the agency' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agency_name}</div>}
                    </div>
                    <div className='pf-row px-3 position-relative'>
                        <label className='pf-label'>Address of the Agency *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agency_address'
                                value={stepTwoData.agency_address || ''}
                                onChange={(e) => handleAddressChange(e, 'agency')}
                                onFocus={() => setAutoAddress('agency')}
                                autoComplete='off'
                                placeholder='Address' />
                        </div>
                        {autoAddress === 'agency' && stepTwoData.agency_address.length > 0 && <div className='addr-sugg ms-2'>
                            {addrResults.length > 0 ? addrResults.map((item, index) => <div key={index} className='sugg' onClick={() => addAddress(item, 'agency')}>{`${item.address_line1}, ${item.address_line2}`}</div>)
                                : <div className='sugg'>No address found</div>}
                        </div>}
                        {validate && <div className='newUi-err'>{validStepTwo.agency_address}</div>}
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Office Email *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faAt} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agency_email'
                                value={stepTwoData.agency_email || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Email' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agency_email}</div>}
                    </div>
                    <div className='pf-row px-3'>
                        <div className='pf-label'>Phone</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faPhone} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='agency_phone'
                                value={stepTwoData.agency_phone || ''}
                                onChange={handleStepTwoDataChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Phone number' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agency_phone}</div>}
                    </div>
                    <div className='pf-row position-relative'>
                        <label className='pf-label'>Address of property for sale *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='prop_address'
                                value={stepTwoData.prop_address || ''}
                                onChange={(e) => handleAddressChange(e, 'property')}
                                onFocus={() => setAutoAddress('property')}
                                autoComplete='off'
                                placeholder='Address' />
                        </div>
                        {autoAddress === 'property' && stepTwoData.agency_address.length > 0 && <div className='addr-sugg'>
                            {addrResults.length > 0 ? addrResults.map((item, index) => <div key={index} className='sugg' onClick={() => addAddress(item, 'property')}>{`${item.address_line1}, ${item.address_line2}`}</div>)
                                : <div className='sugg'>No address found</div>}
                        </div>}
                        {validate && <div className='newUi-err'>{validStepTwo.prop_address}</div>}
                    </div>
                    <div className='d-flex w-100 gap-4 align-items-center mt-2'>
                        <span className='pf-label'>Title reference of the property for sale (if known) *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='title_ref_known_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.title_ref_known === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='title_ref_known' id='title_ref_known_yes' onChange={handleStepTwoDataChange} />
                                {stepTwoData.title_ref_known === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='title_ref_known_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.title_ref_known === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='title_ref_known' id='title_ref_known_no' onChange={handleStepTwoDataChange} />
                                {stepTwoData.title_ref_known === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err'>{validVendor.behalf_trust}</div>}
                    </div>
                    {stepTwoData.title_ref_known === 'Yes' && <div className='d-md-flex gap-4 pf-row px-3'>
                            <div className='prospect-field'>
                                <label className='pf-label'>Volume</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faHashtag} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='volume'
                                        value={stepTwoData.volume || ''}
                                        onChange={handleStepTwoDataChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Volume' />

                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Folio</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faHashtag} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='folio'
                                        value={stepTwoData.folio || ''}
                                        onChange={handleStepTwoDataChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Folio' />
                                </div>
                            </div>
                        </div>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={goToStepOne}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepTwo}>Next Step</button>
                    </div>
                </>}
                {step === 2 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='pf-row'>
                        <label className='pf-label'>Is it a dwelling house/land/apartment/commercial? (Please choose one) *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faBuilding} />
                            <select name='property_type' value={stepThreeData.property_type} className='pro-input w-100 mx-2' onChange={handleStepThreeDataChange} >
                                <option disabled value=''>Choose any option</option>
                                <option value='Dwelling House'>Dwelling House</option>
                                <option value='Vacant Land'>Vacant Land</option>
                                <option value='Apartment'>Apartment</option>
                                <option value='Commercial'>Commercial</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.property_type}</div>}
                    </div>
                    {stepThreeData.property_type === 'Vacant Land' && <div className='d-flex w-100 align-items-center mt-2'>
                        <span className='px-3 pf-label res-width-2'>Do you require resale permission from the original vendor? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='resale_permission_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.resale_permission === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='resale_permission' id='resale_permission_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.resale_permission === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='resale_permission_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.resale_permission === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='resale_permission' id='resale_permission_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.resale_permission === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.resale_permission}</div>}
                    </div>}
                    <div className='d-flex w-100 align-items-center mt-2'>
                        <span className='pf-label res-width-2'>Does the sale price include GST? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='sale_price_include_gst_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.sale_price_include_gst === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='sale_price_include_gst' id='sale_price_include_gst_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.sale_price_include_gst === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='sale_price_include_gst_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.sale_price_include_gst === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='sale_price_include_gst' id='sale_price_include_gst_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.sale_price_include_gst === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.sale_price_include_gst}</div>}
                    </div>
                    <div className='pf-row'>
                        <div className='pf-label'>Number of Bedrooms *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faBed} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='number'
                                min={1}
                                name='no_bedrooms'
                                value={stepThreeData.no_bedrooms || ''}
                                onChange={handleStepThreeDataChange}
                                placeholder='Number of bedrooms' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.no_bedrooms}</div>}
                    </div>
                    <div className='pf-row'>
                        <label className='pf-label'>Material of the bulding *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faBuilding} />
                            <select name='material_of_building' value={stepThreeData.material_of_building} className='pro-input w-100 mx-2' onChange={handleStepThreeDataChange} >
                                <option disabled value=''>Choose any option</option>
                                <option value='Brick'>Brick</option>
                                <option value='Brick Veneer'>Brick Veneer</option>
                                <option value='Timber'>Timber</option>
                                <option value='Fibro Cement'>Fibro Cement</option>
                                <option value='Others'>Others</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.material_of_building}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center mt-2 mt-md-0'>
                        <span className='pf-label res-width-2'>Do you have a swimming pool? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='swimming_pool_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.swimming_pool === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='swimming_pool' id='swimming_pool_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.swimming_pool === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='swimming_pool_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.swimming_pool === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='swimming_pool' id='swimming_pool_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.swimming_pool === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.swimming_pool}</div>}
                    </div>
                    {stepThreeData.swimming_pool === 'Yes' && <div className='pf-row px-3'>
                        <div className='pf-label'>If yes, please provide the permit</div>
                        <div className='d-flex align-items-center newUi-field'>
                            <input className='d-none'
                                type='file'
                                name='swimming_pool_permit'
                                onChange={handleFiles}
                                id='swimming_pool_permit' />
                            <label htmlFor='swimming_pool_permit' className='w-100'>
                                <div className='w-100 file-box p-2'>
                                    {stepThreeData.swimming_pool_permit ? stepThreeData.swimming_pool_permit : 'No file is selected'}
                                </div>
                             </label>
                        </div>
                        <div className='d-flex align-items-center'>
                            {!stepThreeData.swimming_pool_permit && <div>
                                <input className='me-2' name='pool_permit_doc' type='checkbox' checked={stepThreeData.pool_permit_doc} onChange={handleStepThreeDataChange} />
                                <span className='pf-label'>I do not have the document</span>
                            </div>}
                            {validate && <div className='newUi-err ps-4'>{validStepThree.swimming_pool_permit}</div>}
                        </div>
                    </div>}
                    <div className='d-flex w-100 align-items-center mt-2'>
                        <span className='pf-label res-width-2'>Is the sale including all fixtures and any chattels? If so, please provide the list. *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='fixtures_chattels_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.fixtures_chattels === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='fixtures_chattels' id='fixtures_chattels_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.fixtures_chattels === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='fixtures_chattels_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.fixtures_chattels === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='fixtures_chattels' id='fixtures_chattels_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.fixtures_chattels === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.fixtures_chattels}</div>}
                    </div>
                    {stepThreeData.fixtures_chattels === 'Yes' && 
                    stepThreeData.fixtures_chattels_list.map((item, index) => (
                        <div key={index} className='pf-row px-3'>
                            <div className='pf-label'>Item {index + 1}</div>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faClipboard} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='fixtures_chattels_list'
                                    value={item.fix || ''}
                                    onChange={(e) => handleStepThreeDataChange(e, index)}
                                    placeholder={`Item ${index + 1}`} />
                                {stepThreeData.fixtures_chattels_list.length === Number(index) + 1 && <div className='d-flex gap-2'>
                                    <FontAwesomeIcon className='hand-symbol' icon={faCirclePlus} onClick={addList} />
                                    {index !== 0 && <FontAwesomeIcon className='hand-symbol' icon={faCircleMinus} onClick={() => removeFromList(index)} />}</div>}
                            </div>
                            {validate && <div className='newUi-err'>{validStepThree.fixtures_chattels_list[index]}</div>}
                        </div>))
                    }
                    <div className='d-flex w-100 align-items-center mt-2'>
                        <span className='pf-label res-width-2'>Is there any special condition in the contract? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='special_condition_mentioned_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.special_condition_mentioned === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='special_condition_mentioned' id='special_condition_mentioned_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.special_condition_mentioned === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='special_condition_mentioned_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.special_condition_mentioned === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='special_condition_mentioned' id='special_condition_mentioned_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.special_condition_mentioned === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.special_condition_mentioned}</div>}
                    </div>
                    {stepThreeData.special_condition_mentioned === 'Yes' && <div className='pf-row px-3'>
                        <span className='pf-label'>If yes, please mention *</span>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faStarOfLife} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='special_condition'
                                value={stepThreeData.special_condition || ''}
                                onChange={handleStepThreeDataChange}
                                placeholder='Special condition mentioned in the contract' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.special_condition}</div>}
                    </div>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={() => goToStep(1)}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepThree}>Next Step</button>
                    </div>
                </>}
                {step === 3 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='d-flex w-100 align-items-center mt-2'>
                        <span className='pf-label res-width-2'>Please provide the details of your Mortgagee Bank if applicable *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='mortgagee_bank_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.mortgagee_bank === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='mortgagee_bank' id='mortgagee_bank_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.mortgagee_bank === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='mortgagee_bank_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.mortgagee_bank === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='mortgagee_bank' id='mortgagee_bank_no' onChange={handleStepFourDataChange} />
                                {stepFourData.mortgagee_bank === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.mortgagee_bank}</div>}
                    </div>
                    {stepFourData.mortgagee_bank === 'Yes' && stepFourData.loan_account_no.map((item, index) => (
                        <div key={index} className='pf-row px-3'>
                            <div className='pf-label'>Loan Account Number {index + 1}</div>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='loan_account_no'
                                        value={item.acnt || ''}
                                        onChange={(e) => handleStepFourDataChange(e, index)}
                                        placeholder={`Loan Account No ${index + 1}`} />
                                {stepFourData.loan_account_no.length === index + 1 && <div className='d-flex gap-2'>
                                    <FontAwesomeIcon className='hand-symbol' icon={faCirclePlus} onClick={addAccntNumber}/>
                                    {index !== 0 && <FontAwesomeIcon className='hand-symbol' icon={faCircleMinus} onClick={() => removeAccntNumber(index)}/>}</div>}
                            </div>
                            {validate && <div className='error-message mt-1'>{validStepFour.loan_account_no[index]}</div>}
                        </div>))}
                    <div className='pf-label fw-bold my-2'>Loan Details: Please also provide the following Documents</div>
                    <div className='pf-row px-3'>
                        <span className='pf-label'>A copy of the recent Council Rates</span>
                        <div className='d-flex align-items-center newUi-field'>
                            <input className='d-none'
                                type='file' name='council_rates'
                                onChange={handleFiles} id='council_rates' />
                            <label htmlFor='council_rates' className='w-100'>
                                <div className='w-100 file-box p-2'>
                                    {stepFourData.council_rates ? stepFourData.council_rates : 'No file is selected'}
                                </div>
                            </label>
                        </div>
                        <div className='d-flex align-items-center'>
                            {!stepFourData.council_rates && <div>
                                <input className='me-2' name='council_rates_doc' type='checkbox' checked={stepFourData.council_rates_doc} onChange={handleStepFourDataChange} />
                                <span className='pf-label'>I do not have the document</span>
                            </div>}
                            {validate && <div className='newUi-err ps-4'>{validStepFour.council_rates}</div>}
                        </div>
                    </div>
                    <div className='pf-row mt-3 px-3'>
                        <span className='pf-label'>A copy of the recent Water bill of the property</span>
                        <div className='d-flex align-items-center newUi-field'>
                            <input className='d-none'
                                type='file' name='water_bill'
                                onChange={handleFiles} id='water_bill' />
                            <label htmlFor='water_bill' className='w-100'>
                                <div className='w-100 file-box p-2'>
                                    {stepFourData.water_bill ? stepFourData.water_bill : 'No file is selected'}
                                </div>
                            </label>
                        </div>
                        <div className='d-flex align-items-center'>
                            {!stepFourData.water_bill && <div>
                                <input className='me-2' name='water_bill_doc' type='checkbox' checked={stepFourData.water_bill_doc} onChange={handleStepFourDataChange} />
                                <span className='pf-label'>I do not have the document</span>
                            </div>}
                            {validate && <div className='newUi-err ps-4'>{validStepFour.water_bill}</div>}
                        </div>
                    </div>
                    <div className='d-flex align-items-center px-3 mt-3'>
                        <span className='pf-label res-width-2'>Is property more than seven years old? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='seven_years_old_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.seven_years_old === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='seven_years_old' id='seven_years_old_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.seven_years_old === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='seven_years_old_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.seven_years_old === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='seven_years_old' id='seven_years_old_no' onChange={handleStepFourDataChange} />
                                {stepFourData.seven_years_old === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.seven_years_old}</div>}
                    </div>
                    {stepFourData.seven_years_old === 'No' && <>
                        <div className='pf-row px-5'>
                            <span className='pf-label'>A copy of the building permit</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='building_permit'
                                    onChange={handleFiles} id='building_permit' />
                                <label htmlFor='building_permit' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.building_permit ? stepFourData.building_permit : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.building_permit && <div>
                                    <input className='me-2' name='building_permit_doc' type='checkbox' checked={stepFourData.building_permit_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.building_permit}</div>}
                            </div>
                        </div>
                        <div className='pf-row mt-3 px-5'>
                            <span className='pf-label'>A copy occupancy certificate/final inspection certificate</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='occupancy_certificate'
                                    onChange={handleFiles} id='occupancy_certificate' />
                                <label htmlFor='occupancy_certificate' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.occupancy_certificate ? stepFourData.occupancy_certificate : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.occupancy_certificate && <div>
                                    <input className='me-2' name='occupancy_certificate_doc' type='checkbox' checked={stepFourData.occupancy_certificate_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.occupancy_certificate}</div>}
                            </div>
                        </div>
                        <div className='pf-row mt-3 px-5'>
                            <span className='pf-label'>A copy of the building warranty Insurance</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='building_warranty_insurance'
                                    onChange={handleFiles} id='building_warranty_insurance' />
                                <label htmlFor='building_warranty_insurance' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.building_warranty_insurance ? stepFourData.building_warranty_insurance : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.building_warranty_insurance && <div>
                                    <input className='me-2' name='building_warranty_insurance_doc' type='checkbox' checked={stepFourData.building_warranty_insurance_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.building_warranty_insurance}</div>}
                            </div>
                        </div>
                    </>}
                    <div className='d-flex align-items-center mt-3 px-3'>
                        <span className='pf-label res-width-2'>Any additional constructions were made within last 7 years? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='additional_buildings_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.additional_buildings === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='additional_buildings' id='additional_buildings_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.additional_buildings === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='additional_buildings_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.additional_buildings === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='additional_buildings' id='additional_buildings_no' onChange={handleStepFourDataChange} />
                                {stepFourData.additional_buildings === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.additional_buildings}</div>}
                    </div>
                    {stepFourData.additional_buildings === 'Yes' && <div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>If yes, please provide building approvals</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='additional_buildings_approvals'
                                    onChange={handleFiles} id='additional_buildings_approvals' />
                                <label htmlFor='additional_buildings_approvals' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.additional_buildings_approvals ? stepFourData.additional_buildings_approvals : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.additional_buildings_approvals && <div>
                                    <input className='me-2' name='additional_buildings_approvals_doc' type='checkbox' checked={stepFourData.additional_buildings_approvals_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.additional_buildings_approvals}</div>}
                            </div>
                    </div>}
                    <div className='d-flex align-items-center mt-3 px-3'>
                        <span className='pf-label res-width-2'>Any building permits issued in the last 7 years? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='building_permit_issued_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_permit_issued === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='building_permit_issued' id='building_permit_issued_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.building_permit_issued === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='building_permit_issued_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_permit_issued === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='building_permit_issued' id='building_permit_issued_no' onChange={handleStepFourDataChange} />
                                {stepFourData.building_permit_issued === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.building_permit_issued}</div>}
                    </div>
                    {stepFourData.building_permit_issued === 'Yes' && <div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>If yes, please provide the file</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='building_permit_latest'
                                    onChange={handleFiles} id='building_permit_latest' />
                                <label htmlFor='building_permit_latest' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.building_permit_latest ? stepFourData.building_permit_latest : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.building_permit_latest && <div>
                                    <input className='me-2' name='building_permit_latest_doc' type='checkbox' checked={stepFourData.building_permit_latest_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.building_permit_latest}</div>}
                            </div>
                    </div>}
                    <div className='d-flex align-items-center mt-3 px-3'>
                        <span className='pf-label res-width-2'>Any construction completed by owner builder in the last 6 years? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='construction_by_owner_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.construction_by_owner === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='construction_by_owner' id='construction_by_owner_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.construction_by_owner === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='construction_by_owner_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.construction_by_owner === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='construction_by_owner' id='construction_by_owner_no' onChange={handleStepFourDataChange} />
                                {stepFourData.construction_by_owner === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.construction_by_owner}</div>}
                    </div>
                    {stepFourData.construction_by_owner === 'Yes' && <><div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>If yes, please provide owner builder inspection report under section 137B of the building act</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='owner_builder_inspection'
                                    onChange={handleFiles} id='owner_builder_inspection' />
                                <label htmlFor='owner_builder_inspection' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.owner_builder_inspection ? stepFourData.owner_builder_inspection : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.owner_builder_inspection && <div>
                                    <input className='me-2' name='owner_builder_inspection_doc' type='checkbox' checked={stepFourData.owner_builder_inspection_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.owner_builder_inspection}</div>}
                            </div>
                    </div>
                    <div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>Building warranty insurance if applicable</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='warranty_insurance_owner'
                                    onChange={handleFiles} id='warranty_insurance_owner' />
                                <label htmlFor='warranty_insurance_owner' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.warranty_insurance_owner ? stepFourData.warranty_insurance_owner : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.warranty_insurance_owner && <div>
                                    <input className='me-2' name='warranty_insurance_owner_doc' type='checkbox' checked={stepFourData.warranty_insurance_owner_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.warranty_insurance_owner}</div>}
                            </div>
                    </div></>}
                    <div className='d-flex align-items-center mt-3 px-3'>
                        <span className='pf-label res-width-2'>Property is tenanted? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='property_tenanted_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.property_tenanted === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='property_tenanted' id='property_tenanted_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.property_tenanted === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='property_tenanted_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.property_tenanted === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='property_tenanted' id='property_tenanted_no' onChange={handleStepFourDataChange} />
                                {stepFourData.property_tenanted === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.property_tenanted}</div>}
                    </div>
                    {stepFourData.property_tenanted === 'Yes' && <div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>If yes, please provide a copy of the lease agreement</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='lease_agreement'
                                    onChange={handleFiles} id='lease_agreement' />
                                <label htmlFor='lease_agreement' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.lease_agreement ? stepFourData.lease_agreement : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                {!stepFourData.lease_agreement && <div>
                                    <input className='me-2' name='lease_agreement_doc' type='checkbox' checked={stepFourData.lease_agreement_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.lease_agreement}</div>}
                            </div>
                    </div>}
                    <div className='d-flex align-items-center mt-3 px-3'>
                        <span className='pf-label res-width-2'>OCC is applicable to your property? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='occ_applicable_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.occ_applicable === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='occ_applicable' id='occ_applicable_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.occ_applicable === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='occ_applicable_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.occ_applicable === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='occ_applicable' id='occ_applicable_no' onChange={handleStepFourDataChange} />
                                {stepFourData.occ_applicable === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.occ_applicable}</div>}
                    </div>
                    {stepFourData.occ_applicable === 'Yes' && <div className='pf-row px-5 mt-3'>
                            <span className='pf-label'>If yes, please provide OC management details to apply for the certificate</span>
                            <div className='d-flex align-items-center newUi-field'>
                                <input className='d-none'
                                    type='file' name='oc_management_details'
                                    onChange={handleFiles} id='oc_management_details' />
                                <label htmlFor='oc_management_details' className='w-100'>
                                    <div className='w-100 file-box p-2'>
                                        {stepFourData.oc_management_details ? stepFourData.oc_management_details : 'No file is selected'}
                                    </div>
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <i className='pf-label'>Charges Applicable *</i>
                                {!stepFourData.oc_management_details && <div className='ps-3'>
                                    <input className='me-2' name='oc_management_details_doc' type='checkbox' checked={stepFourData.oc_management_details_doc} onChange={handleStepFourDataChange} />
                                    <span className='pf-label'>I do not have the document</span>
                                </div>}
                                {validate && <div className='newUi-err ps-4'>{validStepFour.oc_management_details}</div>}
                            </div>
                    </div>}
                    <div className='d-flex mt-3'>
                        <div className='d-flex w-50 align-items-center my-2'>
                            <span className='pf-label me-2' >Date : </span>
                            <div className='newUi-field p-2'>{todayDate}</div>
                        </div>
                        <div className='d-flex w-50 align-items-center my-2'>
                            <span className='pf-label text-nowrap ms-auto me-1' >Full Name : *</span>
                            <div className='d-flex align-items-center newUi-field px-2 w-auto ms-auto'>
                                <FontAwesomeIcon icon={faPenClip} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='signature'
                                    value={stepFourData.signature || ''}
                                    onChange={handleStepFourDataChange}
                                    placeholder='Full name' />
                            </div>
                        </div>
                    </div>
                    {validate && <div className='w-auto ms-auto newUi-err ps-2 mt-1'>{validStepFour.signature}</div>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={() => goToStep(2)}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepFour}>Review</button>
                    </div>
                </>}
                {step === 4 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    {vendorData.map((ven, index) => <div key={index}>
                        <div className='fw-bold d-flex'>
                            <span>{index === 0 ? 'First' : index === 1 ? 'Second' : index === 2 ? 'Third' : index === 3 ? 'Fourth' : 'Fifth'} Vendor Details</span>
                            <button className='ms-auto au-btn' onClick={() => editVendor(index)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Given Name :</span>
                            <b className='w-50'>{ven.given_name}</b>
                        </div>
                        {ven.middle_name && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Middle Name :</span>
                            <b className='w-50'>{ven.middle_name}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Surname :</span>
                            <b className='w-50'>{ven.surname}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Title :</span>
                            <b className='w-50'>{ven.title}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Different name used? </span>
                            <b className='w-50'>{ven.diff_name_used}</b>
                        </div>
                        {ven.diff_name_used === 'Yes' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Given Name :</span>
                            <b className='w-50'>{ven.given_name_diff}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{ven.surname_diff}</b>
                            </div>
                            {ven.middle_name_diff && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Middle Name :</span>
                                <b className='w-50'>{ven.middle_name_diff}</b>
                            </div>}
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Address :</span>
                            <b className='w-50'>{ven.address}</b>
                        </div>
                        {ven.phone && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Phone number :</span>
                            <b className='w-50'>{ven.phone}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Mobile number :</span>
                            <b className='w-50'>{ven.mobile}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Email :</span>
                            <b className='w-50'>{ven.email}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Date of birth :</span>
                            <b className='w-50'>{ven.dob}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Citizenship Details </span>
                            <b className='w-50'>{ven.aus_res}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Country of Citizenship :</span>
                            <b className='w-50'>{ven.citizen}</b>
                        </div>
                        {ven.citizen !== 'Australia' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Given Name as per Visa :</span>
                            <b className='w-50'>{ven.visa_name ? ven.visa_name : '-'}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Date of Birth as per Visa :</span>
                                <b className='w-50'>{ven.visa_dob ? ven.visa_dob : '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Passport Number : </span>
                                <b className='w-50'>{ven.passport_no ? ven.passport_no : '-'}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Are you acting behalf of a trust? :</span>
                            <b className='w-50'>{ven.behalf_trust}</b>
                        </div>
                        {ven.trust_name && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Trust Name :</span>
                            <b className='w-50'>{ven.trust_name}</b>
                        </div>}
                        <hr />
                    </div>)}
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Real Estate Listing Details</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(1)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Name of the Real Estate Agent :</span>
                            <b className='w-50'>{stepTwoData.agent_name}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Agent Mobile :</span>
                            <b className='w-50'>{stepTwoData.agent_mobile}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Direct email :</span>
                            <b className='w-50'>{stepTwoData.agent_email}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Name of the real estate Agency :</span>
                            <b className='w-50'>{stepTwoData.agency_name}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Address of the Agency :</span>
                            <b className='w-50'>{stepTwoData.agency_address}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Office Email :</span>
                            <b className='w-50'>{stepTwoData.agency_email}</b>
                        </div>
                        {stepTwoData.agency_phone && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Agency Phone :</span>
                            <b className='w-50'>{stepTwoData.agency_phone}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Address of property for sale :</span>
                            <b className='w-50'>{stepTwoData.prop_address}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Title reference of the property for sale is known? </span>
                            <b className='w-50'>{stepTwoData.title_ref_known}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Folio :</span>
                            <b className='w-50'>{stepTwoData.folio || '-'}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Volume :</span>
                            <b className='w-50'>{stepTwoData.volume || '-'}</b>
                        </div>
                        <hr />
                    </div>
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Property Details</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(2)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is it a dwelling house/land/apartment/commercial? </span>
                            <b className='w-50'>{stepThreeData.property_type}</b>
                        </div>
                        {stepThreeData.property_type === 'Vacant Land' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Do you require resale permission from the original vendor? </span>
                            <b className='w-50'>{stepThreeData.resale_permission}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Does the sale price include GST? </span>
                            <b className='w-50'>{stepThreeData.sale_price_include_gst}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Number of Bedrooms :</span>
                            <b className='w-50'>{stepThreeData.no_bedrooms}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Material of the bulding :</span>
                            <b className='w-50'>{stepThreeData.material_of_building}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Do you have a swimming pool? </span>
                            <b className='w-50'>{stepThreeData.swimming_pool}</b>
                        </div>
                        {stepThreeData.swimming_pool === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Swimming pool permit :</span>
                            <b className='w-50'>{stepThreeData.swimming_pool_permit || '-'}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is the sale including all fixtures and any chattels? </span>
                            <b className='w-50'>{stepThreeData.fixtures_chattels}</b>
                        </div>
                        {stepThreeData.fixtures_chattels_list.length > 0 && <div className='mb-3 d-flex w-100'>
                            <span className='w-50'>List of all fixtures and chattels :</span>
                            <span className='w-50'>{stepThreeData.fixtures_chattels_list.map((item, index) => (<div key={index} className='me-4 fw-bold'>{item.fix || '-'}</div>))}</span>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is there any special condition in the contract? </span>
                            <b className='w-50'>{stepThreeData.special_condition_mentioned}</b>
                        </div>
                        {stepThreeData.special_condition_mentioned === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Special condition </span>
                            <b className='w-50'>{stepThreeData.special_condition}</b>
                        </div>}
                        <hr />
                    </div>
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Others</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(3)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Mortgagee Bank is applicable? </span>
                            <b className='w-50'>{stepFourData.mortgagee_bank}</b>
                        </div>
                        {stepFourData.loan_account_no.length > 0 && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Loan Account Number/s :</span>
                            <span className='w-50'>
                                {stepFourData.loan_account_no.map((item, index) => (<div key={index} className='fw-bold'>{item.acnt || '-'}</div>))}
                            </span>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>A copy of the recent Council Rates :</span>
                            <b className='w-50'>{stepFourData.council_rates || '-'}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>A copy of the recent Water bill of the property :</span>
                            <b className='w-50'>{stepFourData.water_bill || '-'}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is property more than seven years old? </span>
                            <b className='w-50'>{stepFourData.seven_years_old}</b>
                        </div>
                        {stepFourData.seven_years_old === 'No' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>A copy of the building permit :</span>
                            <b className='w-50'>{stepFourData.building_permit || '-'}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the occupancy certificate :</span>
                                <b className='w-50'>{stepFourData.occupancy_certificate || '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the building warranty Insurance :</span>
                                <b className='w-50'>{stepFourData.building_warranty_insurance || '-'}</b>
                            </div></>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Any additional constructions were made within last 7 years? </span>
                            <b className='w-50'>{stepFourData.additional_buildings}</b>
                        </div>
                        {stepFourData.additional_buildings === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Copy of building approvals :</span>
                            <b className='w-50'>{stepFourData.additional_buildings_approvals || '-'}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Any building permits issued in the last 7 years? </span>
                            <b className='w-50'>{stepFourData.building_permit_issued}</b>
                        </div>
                        {stepFourData.building_permit_issued === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>A copy of buidling permit issued in the last 7 years :</span>
                            <b className='w-50'>{stepFourData.building_permit_latest || '-'}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Any construction completed by owner builder in the last 6 years? </span>
                            <b className='w-50'>{stepFourData.construction_by_owner}</b>
                        </div>
                        {stepFourData.construction_by_owner === 'Yes' && <>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Builder inspection report under section 137B of the building act :</span>
                                <b className='w-50'>{stepFourData.owner_builder_inspection || '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Building warranty insurance if applicable :</span>
                                <b className='w-50'>{stepFourData.warranty_insurance_owner || '-'}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Property is tenanted? </span>
                            <b className='w-50'>{stepFourData.property_tenanted}</b>
                        </div>
                        {stepFourData.property_tenanted === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>A copy of the lease agreement :</span>
                            <b className='w-50'>{stepFourData.lease_agreement || '-'}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>OCC is applicable to your property? </span>
                            <b className='w-50'>{stepFourData.occ_applicable}</b>
                        </div>
                        {stepFourData.occ_applicable === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>OC management details to apply for the certificate :</span>
                            <b className='w-50'>{stepFourData.oc_management_details || '-'}</b>
                        </div>}
                        <div className='d-flex w-100 my-4'>
                            <span>Date :<b className='ms-md-3'>{todayDate}</b></span>
                            <span className='ms-auto me-md-5'>Full Name :<b>{stepFourData.signature}</b></span>
                        </div>
                        <div className='d-flex justify-content-center error-message'>{errMsg}</div>
                    </div>
                </div>
                    <div className='d-flex my-2'>
                        <button className={`m-auto res-width-3 m-auto ${(validStepFour.error || validStepThree.error) ? 'au-btn-disabled' : 'au-btn'}`} disabled={validStepFour.error || validStepThree.error} onClick={submitData}>
                            <FontAwesomeIcon className='me-2' icon={faCheck} />
                            Submit</button>
                    </div>
                </>}
            </>)
    )
}

export default Vendor