import React, { useEffect, useState } from 'react'
import './clients.css'
import CaseAccordian from '../../components/CaseAccordian';
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import Backdrop from '../../components/Backdrop';
import AddNewCaseModal from '../../components/AddNewCaseModal';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { useDebounce } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCirclePlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const Clients = () => {

    const selected = 'Clients';
    const userType = 'entity';
    const api = axiosRequestInterceptor(true);
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [status, setStatus] = useState('Active');
    const [sort, setSort] = useState('latest_first');
    const [errMsg, setErrMsg] = useState(false);
    const [casesData, setCasesData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const casesPerPage = 7;
    const pagesToShow = 10;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const handleStatus = (e) => {
        setStatus(e.target.value);
    }

    const handleSort = (sortBy) => {
        if (sort === 'client_name_asc' && sortBy === 'client_name') setSort('client_name_desc')
        else if (sort === 'id_asc' && sortBy === 'id') setSort('id_desc')
        else if (sort === 'status_asc' && sortBy === 'status') setSort('status_desc')
        else if (sort === 'title_asc' && sortBy === 'title') setSort('title_desc')
        else if (sort === 'date_asc' && sortBy === 'date') setSort('date_desc')
        else if (sortBy === 'client_name') setSort('client_name_asc')
        else if (sortBy === 'id') setSort('id_asc')
        else if (sortBy === 'status') setSort('status_asc')
        else if (sortBy === 'title') setSort('title_asc')
        else if (sortBy === 'date') setSort('date_asc')
    }

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const fetchCasesData = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/clients/casesData/${status}/${casesPerPage}/${currentPage}/${sort}/${searchKeyword ? searchKeyword : null}`);
            const tempPages = Math.ceil(response.data.totalResults / casesPerPage);
            setTotalPages(tempPages);
            setCasesData(response.data.casesData);
            setLoading(false);
        } catch (err) {
            setErrMsg(true)
        }
    }

    const [debouncedInput] = useDebounce(searchKeyword, 500);

    const changeKeyword = (e) => {
        setSearchKeyword(e.target.value);
        setCurrentPage(1);
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        fetchCasesData();
    }, [status, currentPage, debouncedInput, sort]) //eslint-disable-line

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar userType={userType} collapsed={collapsed} />
                <div className='container px-4 py-2 scroll-window pb-4'>
                    <div className='clients-action-tab mt-2'>
                        <span >
                            <select className='form-select mb-3 py-1' value={status} onChange={handleStatus}>
                                <option value='Active'>Active</option>
                                <option value='Inactive'>Inactive</option>
                                <option value='Closed'>Closed</option>
                            </select>
                        </span>
                        <span className='search-holder px-1 ms-auto mb-3'>
                            <FontAwesomeIcon className='mx-1' color='gray' icon={faMagnifyingGlass} />
                            <input className='input-clients-search' onChange={changeKeyword} value={searchKeyword} type='text' placeholder='Search' />
                        </span>
                        <button onClick={openModal} className='add-clients-btn ms-md-5 mb-3'>
                        <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>New Case</button>
                    </div>
                    <div className='scrollable-hor card mt-2'>
                        {loading ? <div className='no-data-banner fw-bold pt-5'>Loading...</div> :
                            errMsg ? <div className='no-data-banner fw-bold pt-5'>Failed to fetch the clients data</div> :
                                casesData?.length !== 0 ?
                                    <>
                                        <div className='table-head sortBy pt-1'>
                                            <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('client_name')} >
                                                <span className='me-2'>Client Name</span>
                                                {sort === 'client_name_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'client_name_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
                                            </span>
                                            <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('id')}>
                                                <span className='me-2'>Id</span>
                                                {sort === 'id_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'id_desc' ? <FontAwesomeIcon icon={faArrowDown} />:null}
                                            </span>
                                            {window.innerWidth < 768 && <span className='client-head-col hand-symbol ps-3 client-status'
                                                onClick={() => handleSort('status')} ><span className='me-2'>Status</span>
                                                {sort === 'status_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'status_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
                                            </span>}
                                            {window.innerWidth < 768 && <span className='client-head-col ps-3'>Action</span>}
                                            <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('title')}>
                                                <span className='me-2'>Title</span>
                                                {sort === 'title_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'title_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
                                            </span>
                                            <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('date')}>
                                                <span className='me-2'>Date Requested</span>
                                                {sort === 'date_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'date_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
                                            </span>
                                            <span className='client-head-col ps-3'>Date Uploaded</span>
                                            {window.innerWidth > 768 && <span className='client-head-col hand-symbol ps-3'
                                                onClick={() => handleSort('status')}><span className='me-2'>Status</span>
                                                {sort === 'status_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                                                sort === 'status_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
                                            </span>}
                                            {window.innerWidth > 768 && <span className='client-head-col ps-3'>Action</span>}
                                        </div>
                                        {casesData?.map((caseData) => {
                                            return (
                                                <CaseAccordian key={caseData.id} caseData={caseData} fetchCasesData={fetchCasesData} />
                                            )
                                        })}</> : <div className='no-data-banner fw-bold pt-5'>
                                        There is no {status} Cases
                                    </div>}
                    </div>
                    {totalPages > 1 && <nav className='mt-2'>
                        <ul className='pagination'>
                            <li className='page-item'>
                                <button
                                    className={currentPage === 1 ? 'page-link disabled' : 'page-link'}
                                    onClick={() => handlePageClick(currentPage - 1)}
                                    disabled={currentPage === 1}>Previous</button></li>
                            {pageNumbers.map((pageNumber) => (
                                <li key={pageNumber} className='page-item'>
                                    <button
                                        className={`page-link ${pageNumber === currentPage ? 'active' : ''}`}
                                        onClick={() => handlePageClick(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                </li>
                            ))}
                            <li className='page-item'>
                                <button
                                    className={currentPage === totalPages ? 'page-link disabled' : 'page-link'}
                                    onClick={() => handlePageClick(currentPage + 1)}
                                    disabled={currentPage === totalPages}>Next</button></li>
                        </ul>
                    </nav>}
                </div>
            </div>
            <Backdrop isOpen={modalIsOpen} />
            {modalIsOpen && <AddNewCaseModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                fetchCasesData={fetchCasesData} />}
        </div>
    )
}

export default Clients