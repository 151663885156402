import React, { useState, useEffect } from 'react'
import './addRequest.css'
import FileViewer from '../FileViewer';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { useDebounce } from 'use-debounce';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faEye, faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const AddRequestModal = (props) => {

  const { isOpen, closeModal, clientId, caseId, fetchCaseRequests, fetchClientData } = props;
  const api = axiosRequestInterceptor(true);
  const draft = [{ doc_name: '', description: '', type_of_req: '', files: [] }];
  const userName = sessionStorage.getItem('SDX_user_name');
  const [viewFile, setViewFile] = useState({ file: null, view: false });
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [customRequests, setCustomRequests] = useState(false);
  const [collectionData, setCollectionData] = useState(null);
  const [newCollectionRequests, setNewCollectionRequests] = useState([]);
  const [newCustomRequests, setNewCustomRequests] = useState(draft);
  const [changed, setChanged] = useState(false);
  const [remarks, setRemarks] = useState({ collection: '', custom: '' });
  const [warning, setWarning] = useState('');
  const [valMsg, setValMsg] = useState({ error: true })
  const [submitting, setSubmitting] = useState(false);
  const [newReq, setNewReq] = useState(true);
  const [updateCollection, setUpdateCollection] = useState(false);
  const [confirmClearData, setConfirmClearData] = useState(false);
  const [docTypes, setDocTypes] = useState([]);
  const [notify, setNotify] = useState(false)
  const info = 'The collection is updated';

  const toggleCollection = () => {
    setCustomRequests(prevState => !prevState);
    setShowSuggestions(false);
    setSuggestions([]);
    setDocTypes([]);
  }

  const fetchCollectionData = async () => {
    if (!customRequests) {
      try {
        const response = await api.get(`/collections/getCollectionsData/${inputValue}`);
        setSuggestions(response.data)
      } catch (err) {
        console.error(err);
      }
    }
  }

  const fetchAllDocTypes = async (input, reqIndex) => {
    try {
      const response = await api.get(`/doc_types/data/${input}`)
      const tempData = [...docTypes];
      tempData[reqIndex] = response.data;
      setDocTypes(tempData);
    } catch (err) {
      console.error(err)
    }
  }

  const loadCollectionData = (collection) => {
    if (collection?.cases?.includes(caseId)) {
      setNewReq(false);
    }
    setShowSuggestions(false);
    setCollectionData(collection);
    setChanged(true);
  }

  const loadDocTypeData = (req, reqIndex) => {
    if (customRequests) {
      const tempData = [...newCustomRequests];
      tempData[reqIndex] = { doc_name: req.doc_name, description: req.description, type_of_req: req.type_of_req }
      setNewCustomRequests(tempData);
    } else {
      const tempData = [...newCollectionRequests];
      tempData[reqIndex] = { doc_name: req.doc_name, description: req.description, type_of_req: req.type_of_req }
      setNewCollectionRequests(tempData);
    }
    const tempDocType = [...docTypes];
    tempDocType[reqIndex] = [];
    setDocTypes(tempDocType);
  }

  const removeRequest = (reqIndex, type) => {
    if (customRequests) {
      const tempData = newCustomRequests.filter((req, index) => index !== reqIndex);
      setNewCustomRequests(tempData);
      const tempArr = docTypes.filter((req, index) => index !== reqIndex);
      setDocTypes(tempArr);
    } else if (type === 'existing') {
      const tempData = collectionData.requests.filter((req, index) => index !== reqIndex);
      setCollectionData({ name: collectionData.name, requests: tempData });
    } else if (type === 'new') {
      const tempData = newCollectionRequests.filter((req, index) => index !== reqIndex);
      setNewCollectionRequests(tempData);
      const tempArr = docTypes.filter((req, index) => index !== reqIndex);
      setDocTypes(tempArr);
    }
  }

  const addMoreReq = () => {
    if (customRequests) {
      setChanged(true);
      const tempReq = [...newCustomRequests, { doc_name: '', description: '', type_of_req: '' }]
      setNewCustomRequests(tempReq)
      const tempArr = [...docTypes, []];
      setDocTypes(tempArr);
    } else {
      setWarning(`Save these requests to the ${collectionData.name} collection`);
      const tempReq = [...newCollectionRequests, { doc_name: '', description: '', type_of_req: '' }]
      setNewCollectionRequests(tempReq)
      const tempArr = [...docTypes, []];
      setDocTypes(tempArr);
    }
  }

  const handleChange = (e, ind, type) => {
    if (type === 'custom') {
      setChanged(true);
      const updatedRequests = [...newCustomRequests];
      updatedRequests[ind][e.target.name] = e.target.value;
      setCustomRequests(updatedRequests);
    } else {
      const updatedRequests = [...newCollectionRequests];
      updatedRequests[ind][e.target.name] = e.target.value;
      setNewCollectionRequests(updatedRequests);
    }
  }

  const resetOtherSuggestions = (e, reqIndex) => {
    if (e.target.name === 'doc_name') {
      const tempDocType = docTypes.map((reqs, index) => {
        if (index === reqIndex) return reqs;
        else return [];
      })
      setDocTypes(tempDocType);
    } else {
      const tempDocType = docTypes.map(() => [])
      setDocTypes(tempDocType);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const setFilesWithRequest = (selectedFiles, ind, type) => {
    if (customRequests) {
      setNewCustomRequests((prevData) => {
        const newData = [...prevData];
        const existingFiles = newData[ind].files || [];
        const uniqueFiles = selectedFiles.filter((newFile) => {
          return !existingFiles.some((existingFile) => existingFile.name === newFile.name);
        });
        newData[ind].files = [...existingFiles, ...uniqueFiles];
        return newData;
      });
    } else if (type === 'existing') {
      setCollectionData((prevData) => {
        const newData = { ...prevData };
        const existingFiles = newData.requests[ind].files || [];
        const uniqueFiles = selectedFiles.filter((newFile) => {
          return !existingFiles.some((existingFile) => existingFile.name === newFile.name);
        });
        newData.requests[ind].files = [...existingFiles, ...uniqueFiles];
        return newData;
      });
    } else if (type === 'new') {
      setNewCollectionRequests((prevData) => {
        const newData = [...prevData];
        const existingFiles = newData[ind].files || [];
        const uniqueFiles = selectedFiles.filter((newFile) => {
          return !existingFiles.some((existingFile) => existingFile.name === newFile.name);
        });
        newData[ind].files = [...existingFiles, ...uniqueFiles];
        return newData;
      });
    }
  }

  const handleDrop = (e, ind, type) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setFilesWithRequest(droppedFiles, ind, type);
  };

  const handleFileInputChange = (e, ind, type) => {
    const clickedFiles = Array.from(e.target.files);
    setFilesWithRequest(clickedFiles, ind, type)
  };

  const viewSelectedFile = (file) => {
    setViewFile({ file, view: true });
  }

  const deleteFile = (reqIndex, fileName, type) => {
    if (customRequests) {
      setNewCustomRequests((prevData) => {
        const newData = [...prevData];
        const updatedFiles = newData[reqIndex].files.filter(file => file.name !== fileName);
        newData[reqIndex].files = updatedFiles;
        return newData;
      })
    } else if (type === 'existing') {
      setCollectionData((prevData) => {
        const newData = { ...prevData };
        const updatedFiles = newData.requests[reqIndex].files.filter(file => file.name !== fileName);
        newData.requests[reqIndex].files = updatedFiles;
        return newData;
      });
    } else if (type === 'new') {
      setNewCollectionRequests((prevData) => {
        const newData = [...prevData];
        const updatedFiles = newData[reqIndex].files.filter(file => file.name !== fileName);
        newData[reqIndex].files = updatedFiles;
        return newData;
      });
    }
  };

  const preReqIsVal = (requestArray, type, reqIndex) => {
    if (valMsg[type][reqIndex]?.doc_name) {
      return false
    } else if (valMsg[type][reqIndex]?.description) {
      return false
    } else if (valMsg[type][reqIndex]?.type_of_req) {
      return false
    } else if (requestArray[reqIndex]?.type_of_req === 'Upload') {
      return true
    } else if (valMsg[type][reqIndex]?.files) {
      return false
    }
    return true
  }

  const handleRemarks = (e) => {
    setChanged(true);
    const tempData = { ...remarks, [e.target.name]: e.target.value };
    setRemarks(tempData);
  }

  const clearData = () => {
    setConfirmClearData(false);
    closeModal();
    setInputValue('');
    setCustomRequests(false);
    setNewCustomRequests(draft);
    setCollectionData(null);
    setNewCollectionRequests([]);
    setShowSuggestions(false);
    setSuggestions([]);
    setDocTypes([]);
    setWarning('');
    setUpdateCollection(false);
    setChanged(false);
    setRemarks({ custom: '', collection: '' })
  }

  const submitData = async () => {
    try {
      setSubmitting(true);
      const formData = new FormData();
      let offset = ''
      formData.append('client_id', clientId);
      formData.append('case_id', caseId);
      formData.append('initiated_by', userName);
      if (customRequests) {
        formData.append('remark', remarks.custom);
        formData.append('type', 'custom_requests');
        newCustomRequests.forEach((req, reqIndex) => {
          formData.append(`requests[${reqIndex}][type_of_req]`, req.type_of_req);
          formData.append(`requests[${reqIndex}][doc_name]`, req.doc_name);
          formData.append(`requests[${reqIndex}][description]`, req.description);
          if (req.files && req.files.length > 0) {
            req.files.forEach((file) => {
              formData.append(`files_${reqIndex}`, file);
            });
          }
        });
      } else if (newReq) {
        formData.append('remark', remarks.collection);
        formData.append('type', 'collection_used');
        formData.append('name', collectionData.name);
        formData.append('update_collection', updateCollection);
        collectionData.requests.forEach((req, reqIndex) => {
          offset++;
          formData.append(`requests[${reqIndex}][type_of_req]`, req.type_of_req);
          formData.append(`requests[${reqIndex}][doc_name]`, req.doc_name);
          formData.append(`requests[${reqIndex}][description]`, req.description);
          if (req.files && req.files.length > 0) {
            req.files.forEach((file) => {
              formData.append(`files_${reqIndex}`, file);
            });
          }
        });
        newCollectionRequests.forEach((req, reqIndex) => {
          formData.append(`requests[${reqIndex + offset}][type_of_req]`, req.type_of_req);
          formData.append(`requests[${reqIndex + offset}][doc_name]`, req.doc_name);
          formData.append(`requests[${reqIndex + offset}][description]`, req.description);
          if (req.files && req.files.length > 0) {
            req.files.forEach((file) => {
              formData.append(`files_${reqIndex + offset}`, file);
            });
          }
        });
      } else {
        formData.append('remark', remarks.collection);
        formData.append('type', 'collection_addon');
        formData.append('update_collection', updateCollection);
        formData.append('name', collectionData.name)
        newCollectionRequests.forEach((req, reqIndex) => {
          formData.append(`requests[${reqIndex}][type_of_req]`, req.type_of_req);
          formData.append(`requests[${reqIndex}][doc_name]`, req.doc_name);
          formData.append(`requests[${reqIndex}][description]`, req.description);
          if (req.files && req.files.length > 0) {
            req.files.forEach((file) => {
              formData.append(`files_${reqIndex}`, file);
            });
          }
        });
      }
      await api.post(`/documents/newRequest`, formData);
      if(notify){
        await api.post(`/clients/notify`, { id: caseId, client_id: clientId });
      }
      setSubmitting(false);
      fetchClientData();
      fetchCaseRequests();
      clearData();
    } catch (err) {
      setSubmitting(false);
      console.error(err);
      if (err?.response?.data?.name) {
        setValMsg({ ...valMsg, error: true, name: err.response.data.name });
      } else if (customRequests) {
        setValMsg({ ...valMsg, error: true, rem_custom: err.response.data.error });
      } else if(err?.response?.data?.error) {
        setValMsg({ ...valMsg, error: true, rem_collection: err.response.data.error });
      } else {
        setValMsg({ error: true, rem_collection: 'Failed to connect with server'})
      }
    }
  }

  const cancelModal = () => {
    if (changed) {
      setConfirmClearData(true)
    } else {
      clearData();
    }
  }

  const [debouncedInput] = useDebounce(inputValue, 500);

  useEffect(() => {
    if (showSuggestions) {
      fetchCollectionData();
    }
  }, [showSuggestions, debouncedInput]) //eslint-disable-line

  useEffect(() => {
    let error = false;
    let custom_req = [];
    let new_req = [];
    let existing_req = [];
    let rem_custom = '';
    let rem_collection = '';
    if (customRequests) {

      if (!remarks.custom.length) {
        error = true;
        rem_custom = 'Remarks is required';
      } else if (remarks.custom.length < 2) {
        error = true;
        rem_custom = 'Remarks must contain at least 2 letters';
      }

      newCustomRequests?.forEach((req) => {
        let doc_name = '';
        let description = '';
        let type_of_req = '';
        let files = '';
        if (!req.doc_name) {
          error = true;
          doc_name = 'Document name is required'
        } else if (req.doc_name.length < 3) {
          error = true;
          doc_name = 'Document name must contain more than 2 letters'
        }

        if (!req.description) {
          error = true;
          description = 'Description is required'
        } else if (req.description.length < 3) {
          error = true;
          description = 'Description must contain more than 2 letters'
        }

        if (!req.type_of_req) {
          error = true;
          type_of_req = 'Type is required'
        }

        if ((req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && (!req.files || req.files?.length === 0)) {
          error = true;
          files = 'File is required for this type of request'
        }

        custom_req.push({ doc_name, description, type_of_req, files });
      })
    } else if (newReq) {
      collectionData?.requests?.forEach((req) => {
        let files = '';
        if ((req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && (!req.files || req.files?.length === 0) && newReq) {
          error = true;
          files = 'File is required for this type of request'
        }
        existing_req.push({ files });
      })
      newCollectionRequests?.forEach((req) => {
        let doc_name = '';
        let description = '';
        let type_of_req = '';
        let files = '';
        if (!req.doc_name) {
          error = true;
          doc_name = 'Document name is required'
        } else if (req.doc_name.length < 3) {
          error = true;
          doc_name = 'Document name must contain more than 2 letters'
        }

        if (!req.description) {
          error = true;
          description = 'Description is required'
        } else if (req.description.length < 3) {
          error = true;
          description = 'Description must contain more than 2 letters'
        }

        if (!req.type_of_req) {
          error = true;
          type_of_req = 'Type is required'
        }

        if ((req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && (!req.files || req.files?.length === 0)) {
          error = true;
          files = 'File is required for this type of request'
        }

        new_req.push({ doc_name, description, type_of_req, files });
      })

      if (!remarks.collection.length) {
        error = true;
        rem_collection = 'Remarks is required';
      } else if (remarks.collection.length < 2) {
        error = true;
        rem_collection = 'Remarks must contain at least 2 letters';
      }

    } else {
      newCollectionRequests?.forEach((req) => {
        let doc_name = '';
        let description = '';
        let type_of_req = '';
        let files = '';
        if (!req.doc_name) {
          error = true;
          doc_name = 'Document name is required'
        } else if (req.doc_name.length < 3) {
          error = true;
          doc_name = 'Document name must contain more than 2 letters'
        }

        if (!req.description) {
          error = true;
          description = 'Description is required'
        } else if (req.description.length < 3) {
          error = true;
          description = 'Description must contain more than 2 letters'
        }

        if (!req.type_of_req) {
          error = true;
          type_of_req = 'Type is required'
        }

        if ((req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && (!req.files || req.files?.length === 0)) {
          error = true;
          files = 'File is required for this type of request'
        }

        new_req.push({ doc_name, description, type_of_req, files });
      })

      if (!newCollectionRequests.length) {
        error = true;
        rem_collection = 'New request should be added to send the collection again';
      } else if (!remarks.collection.length) {
        error = true;
        rem_collection = 'Remarks is required';
      } else if (remarks.collection.length < 2) {
        error = true;
        rem_collection = 'Remarks must contain at least 2 letters';
      }
    }

    setValMsg({ error, custom_req, existing_req, new_req, rem_custom, rem_collection })
  }, [customRequests, collectionData, newCollectionRequests, newCustomRequests, remarks]) //eslint-disable-line

  return (
    <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          {viewFile.view ?
            <FileViewer
              file={viewFile.file}
              setViewFile={setViewFile} /> :
            <>
              <div className='modal-header'>
                <h1 className='modal-title fs-5'>
                  New Request
                </h1>
              </div>
              <div className='modal-body px-5'>
                {submitting ? <div className='no-data-banner fw-bold pt-5'>Submitting the request...</div> :
                  confirmClearData ? <div>
                    Added data will be lost if you cancel the request, are you sure to continue?
                  </div> : <>
                    <div className='active-filter fw-bold'>
                      <span onClick={toggleCollection} className={customRequests ? 'inactive-entities' : 'active-entities'}>
                        Collection</span>
                      <span onClick={toggleCollection} className={customRequests ? 'active-entities' : 'inactive-entities'}>
                        Custom Request</span>
                    </div>
                    {!customRequests ? <>
                      <div className='mt-2 d-flex align-items-center'>
                        <label>Select a collection: </label>
                        <span className='search-collection ms-2 pe-1'>
                          <FontAwesomeIcon className='mx-1' color='gray' icon={faMagnifyingGlass} />
                          <input className='input-clients-search'
                            type='text'
                            value={inputValue}
                            onFocus={(e) => {
                              setShowSuggestions(true)
                              resetOtherSuggestions(e)
                            }}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder='Search' />
                          {showSuggestions && suggestions.length > 0 && <div className='suggestion-list'>
                            {suggestions.map((suggestion) => (
                              <div className='px-2' key={suggestion.name}
                                onClick={() => loadCollectionData(suggestion)}
                              >
                                {suggestion.name}
                              </div>
                            ))}
                          </div>}
                        </span>
                      </div>
                      {collectionData && <div className='d-flex mt-3'>
                        <span className='fw-bold me-2'>Collection Name:</span> {collectionData?.name}
                        {collectionData.createdAt !== collectionData.updatedAt && <>
                        <FontAwesomeIcon
                            data-tooltip-id='collection-tooltip'
                            data-tooltip-content={info}
                            className='mb-2 ms-1 hand-symbol'
                            color='#2596be'
                            icon={faCircleInfo} />
                          <Tooltip id='collection-tooltip' place='top' effect='solid' /></>}
                      </div>}
                      {collectionData?.requests?.length && <div className='d-flex align-items-center mt-3 bg-light'>
                        <span className='col-md-3 fw-bold'>Document Name</span>
                        <span className='col-md-5 ms-2 fw-bold'>Description</span>
                        <span className='col-md-3 ms-2 fw-bold'>Request Type</span>
                        <button onClick={addMoreReq} className='add-more-req-btn'> + </button>
                      </div>}
                      {collectionData?.requests?.map((req, reqIndex) => {
                        let reqInfo = '';
                        if (req.type_of_req === 'Upload') {
                          reqInfo = 'Client have to upload the file which is requested by you';
                        } else if (req.type_of_req === 'Download') {
                          reqInfo = 'Client have to download the file which is uploaded by you';
                        } else {
                          reqInfo = 'Client have to download the file which is uploaded by you and upload it by making the necessary changes';
                        }
                        return (
                          <div key={reqIndex} className='border border-2 rounded p-1 mt-1'>
                            <div className='row mt-2'>
                              <div className='col-md-3'>{req.doc_name}</div>
                              <div className='col-md-5'>{req.description}</div>
                              <div className='col-md-3'>{req.type_of_req}
                              <FontAwesomeIcon
                            data-tooltip-id='collection-tooltip'
                            data-tooltip-content={reqInfo}
                            className='mb-2 ms-1 hand-symbol'
                            color='#2596be'
                            icon={faCircleInfo} />
                                <Tooltip id='type-tooltip' place='top' effect='solid' />
                              </div>
                              <div className='col-md-1'>
                                {collectionData.requests.length !== 1 &&
                                <FontAwesomeIcon onClick={() => removeRequest(reqIndex, 'existing')} className='hand-symbol' icon={faTrash} color='#208DA9' size='lg' />}
                              </div>
                            </div>
                            {req.files?.length > 0 && <div className='mt-2'>
                              <table className='users-table mt-2'>
                                <thead>
                                  <tr>
                                    <th className='table-head'>File name</th>
                                    <th className='table-head'>File type</th>
                                    <th className='table-head'>Date uploaded</th>
                                    <th className='table-head'>Action</th>
                                  </tr>
                                </thead>
                                <tbody className='table-body'>
                                  {req.files?.map((file, fileIndex) => {
                                    return (
                                      <tr key={fileIndex}>
                                        <td>{file.name}</td>
                                        <td>{file.type}</td>
                                        <td>{new Date().toLocaleString()}</td>
                                        <td>
                                          <FontAwesomeIcon onClick={() => viewSelectedFile(file)} icon={faEye} className='hand-symbol me-2' size='lg' />
                                          <FontAwesomeIcon onClick={() => deleteFile(reqIndex, file.name, 'existing')} className='hand-symbol' icon={faTrash} color='#208DA9' size='lg' /></td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>}
                            {req.type_of_req !== 'Upload' && newReq && <div>
                              <label htmlFor={`req-file-entity-${reqIndex}`} className='label-for-input'>
                                <div
                                  className='input-card mt-2'
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) => handleDrop(e, reqIndex, 'existing')}>
                                  <FontAwesomeIcon icon={faCloudArrowUp} size='4x' />
                                  <div>Drag and drop files here or click to select files</div>
                                </div>
                              </label>
                              <input
                                type="file"
                                id={`req-file-entity-${reqIndex}`}
                                style={{ display: 'none' }}
                                onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                onChange={(e) => handleFileInputChange(e, reqIndex, 'existing')}
                                multiple
                              />
                            </div>}
                            {<div className='error-message mt-1'>{valMsg?.existing_req[reqIndex]?.files}
                            </div>}
                          </div>
                        )
                      })}
                      {newCollectionRequests?.map((req, reqIndex) => {
                        return (
                          <div key={reqIndex}>
                            <div className='row'>
                              <div className='au-field col-md-3 mt-3'>
                                <input
                                  className='au-input ps-2'
                                  type='text'
                                  name='doc_name'
                                  value={req.doc_name}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={async (e) => {
                                    handleChange(e, reqIndex, 'collection')
                                    await fetchAllDocTypes(e.target.value, reqIndex)
                                  }}
                                  placeholder='Document name'
                                  autoComplete='off' />
                                {docTypes[reqIndex]?.length ? <div className='suggestion-list mt-2'>
                                  {docTypes[reqIndex].map((req) => (
                                    <div className='px-2' key={req.id}
                                      onClick={() => loadDocTypeData(req, reqIndex)}
                                    >
                                      {req.doc_name}
                                    </div>
                                  ))}</div> : null}
                                <label className='au-label'>Document name</label>
                                {(reqIndex === 0 || (reqIndex !== 0 && preReqIsVal(newCustomRequests, 'custom_req', reqIndex - 1))) &&
                                  <div className='error-message-no-wrap mt-1'>{valMsg?.new_req[reqIndex]?.doc_name}
                                  </div>}
                              </div>
                              <div className='au-field col-md-5 mt-3'>
                                <input
                                  className='au-input ps-2'
                                  type='text'
                                  name='description'
                                  value={req.description}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={(e) => handleChange(e, reqIndex, 'collection')}
                                  placeholder='Description' />
                                <label className='au-label'>Description</label>
                                {!valMsg?.new_req[reqIndex]?.doc_name && <div className='error-message mt-1'>
                                  {valMsg?.new_req[reqIndex]?.description}</div>}
                              </div>
                              <div className='col-md-3 mt-3'>
                                <select
                                  name='type_of_req'
                                  value={req.type_of_req}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={(e) => handleChange(e, reqIndex, 'collection')}
                                  className='ms-2 type-box'>
                                  <option value='' disabled>Select type</option>
                                  <option value='Upload' >Upload from client</option>
                                  <option value='Download & Upload'>Download & upload</option>
                                  <option value='Download'>Download for client</option>
                                </select>
                                {!valMsg?.new_req[reqIndex]?.description && <div className='error-message mt-1'>
                                  {valMsg?.new_req[reqIndex]?.type_of_req}</div>}
                              </div>
                              <div className='col-md-1 mt-3'>
                              <FontAwesomeIcon onClick={() => removeRequest(reqIndex, 'new')} className='hand-symbol mt-2' icon={faTrash} color='#208DA9' size='lg' />
                              </div>
                            </div>
                            {req.files?.length > 0 && <div className='mt-2'>
                              <table className='users-table mt-2'>
                                <thead>
                                  <tr>
                                    <th className='table-head'>File name</th>
                                    <th className='table-head'>File type</th>
                                    <th className='table-head'>Date uploaded</th>
                                    <th className='table-head'>Action</th>
                                  </tr>
                                </thead>
                                <tbody className='table-body'>
                                  {req.files?.map((file, fileIndex) => {
                                    return (
                                      <tr key={fileIndex}>
                                        <td>{file.name}</td>
                                        <td>{file.type}</td>
                                        <td>{new Date().toLocaleString()}</td>
                                        <td>
                                          <FontAwesomeIcon onClick={() => viewSelectedFile(file)} icon={faEye} className='hand-symbol me-2' size='lg' />
                                          <FontAwesomeIcon onClick={() =>  deleteFile(reqIndex, file.name, 'new')} className='hand-symbol' icon={faTrash} color='#208DA9' size='lg' />
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>}
                            {(req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && <><div>
                              <label htmlFor={`req-file-entity-${reqIndex}`} className='label-for-input'>
                                <div
                                  className='input-card mt-2'
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) => handleDrop(e, reqIndex, 'new')}>
                                  <FontAwesomeIcon icon={faCloudArrowUp} size='4x' />
                                  <div>Drag and drop files here or click to select files</div>
                                </div>
                              </label>
                              <input
                                type="file"
                                id={`req-file-entity-${reqIndex}`}
                                style={{ display: 'none' }}
                                onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                onChange={(e) => handleFileInputChange(e, reqIndex, 'new')}
                                multiple
                              />
                            </div>
                              {!valMsg?.new_req[reqIndex]?.description && <div className='error-message mt-1'>
                                {valMsg?.new_req[reqIndex]?.files}
                              </div>}</>}
                          </div>
                        )
                      })}
                      {collectionData && <div className='req-text-area-field'>
                        <textarea
                          className='req-text-area w-100 ps-2'
                          type='text'
                          name='collection'
                          value={remarks.collection}
                          onChange={handleRemarks}
                          placeholder='Remarks' />
                        <label className='req-text-area-label'>Remarks</label>
                        {((newCollectionRequests.length && preReqIsVal(newCollectionRequests, 'new_req', newCollectionRequests?.length - 1)) ||
                          !newCollectionRequests.length) &&
                          <div className='error-message mt-1'>{valMsg?.rem_collection}
                          </div>}
                      </div>}
                    </> : <>
                      <div className='d-flex align-items-center mt-3 bg-light'>
                        <span className='col-md-3 fw-bold'>Document Name</span>
                        <span className='col-md-5 ms-2 fw-bold'>Description</span>
                        <span className='col-md-3 ms-3 fw-bold'>Request Type</span>
                        <button onClick={addMoreReq} className='add-more-req-btn'> + </button>
                      </div>
                      {newCustomRequests?.map((req, reqIndex) => {
                        return (
                          <div key={reqIndex}>
                            <div className='row'>
                              <div className='au-field col-md-3 mt-3'>
                                <input
                                  className='au-input ps-2'
                                  type='text'
                                  name='doc_name'
                                  value={req.doc_name}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={async (e) => {
                                    handleChange(e, reqIndex, 'custom')
                                    await fetchAllDocTypes(e.target.value, reqIndex)
                                  }}
                                  placeholder='Document name'
                                  autoComplete='off' />
                                {docTypes[reqIndex]?.length ? <div className='suggestion-list mt-2'>
                                  {docTypes[reqIndex].map((req) => (
                                    <div className='px-2' key={req.id}
                                      onClick={() => loadDocTypeData(req, reqIndex)}
                                    >
                                      {req.doc_name}
                                    </div>
                                  ))}</div> : null}
                                <label className='au-label'>Document name</label>
                                {(reqIndex === 0 || (reqIndex !== 0 && preReqIsVal(newCustomRequests, 'custom_req', reqIndex - 1))) &&
                                  <div className='error-message mt-1 mb-2'>{valMsg?.custom_req[reqIndex]?.doc_name}
                                  </div>}
                              </div>
                              <div className='au-field col-md-5 mt-3'>
                                <input
                                  className='au-input ps-2'
                                  type='text'
                                  name='description'
                                  value={req.description}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={(e) => handleChange(e, reqIndex, 'custom')}
                                  placeholder='Description' />
                                <label className='au-label'>Description</label>
                                {!valMsg?.custom_req[reqIndex]?.doc_name && <div className='error-message mt-1'>
                                  {valMsg?.custom_req[reqIndex]?.description}</div>}
                              </div>
                              <div className='col-md-3 mt-3'>
                                <select
                                  name='type_of_req'
                                  value={req.type_of_req}
                                  onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                  onChange={(e) => handleChange(e, reqIndex, 'custom')}
                                  className='ms-2 type-box'>
                                  <option value='' disabled>Select type</option>
                                  <option value='Upload' >Upload from client</option>
                                  <option value='Download & Upload'>Download & upload</option>
                                  <option value='Download'>Download for client</option>
                                </select>
                                {!valMsg?.custom_req[reqIndex]?.description && <div className='error-message mt-1'>
                                  {valMsg?.custom_req[reqIndex]?.type_of_req}</div>}
                              </div>
                              <div className='col-md-1 mt-3'>
                                {newCustomRequests.length !== 1 && 
                                <FontAwesomeIcon onClick={() =>  removeRequest(reqIndex, 'new')} className='hand-symbol mt-2' icon={faTrash} color='#208DA9' size='lg' />}
                              </div>
                            </div>
                            {req.files?.length > 0 && <div className='mt-2'>
                              <table className='users-table mt-2'>
                                <thead>
                                  <tr>
                                    <th className='table-head'>File name</th>
                                    <th className='table-head'>File type</th>
                                    <th className='table-head'>Date uploaded</th>
                                    <th className='table-head'>Action</th>
                                  </tr>
                                </thead>
                                <tbody className='table-body'>
                                  {req.files?.map((file, fileIndex) => {
                                    return (
                                      <tr key={fileIndex}>
                                        <td>{file.name}</td>
                                        <td>{file.type}</td>
                                        <td>{new Date().toLocaleString()}</td>
                                        <td>
                                          <FontAwesomeIcon onClick={() => viewSelectedFile(file)} icon={faEye} className='hand-symbol me-2' size='lg' />
                                          <FontAwesomeIcon onClick={() =>  deleteFile(reqIndex, file.name)} className='hand-symbol' icon={faTrash} color='#208DA9' size='lg' />
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>}
                            {(req.type_of_req === 'Download' || req.type_of_req === 'Download & Upload') && <><div>
                              <label htmlFor={`req-file-entity-${reqIndex}`} className='label-for-input'>
                                <div
                                  className='input-card mt-2'
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) => handleDrop(e, reqIndex, 'custom')}>
                                  <FontAwesomeIcon icon={faCloudArrowUp} size='4x' />
                                  <div>Drag and drop files here or click to select files</div>
                                </div>
                              </label>
                              <input
                                type="file"
                                id={`req-file-entity-${reqIndex}`}
                                style={{ display: 'none' }}
                                onFocus={(e) => resetOtherSuggestions(e, reqIndex)}
                                onChange={(e) => handleFileInputChange(e, reqIndex, 'custom')}
                                multiple
                              />
                            </div>
                              {!valMsg?.custom_req[reqIndex]?.description && <div className='error-message mt-1'>
                                {valMsg?.custom_req[reqIndex]?.files}
                              </div>}</>}
                          </div>
                        )
                      })}
                      <div className='req-text-area-field'>
                        <textarea
                          className='req-text-area w-100 ps-2'
                          type='text'
                          name='custom'
                          value={remarks.custom}
                          onChange={handleRemarks}
                          placeholder='Remarks' />
                        <label className='req-text-area-label'>Remarks</label>
                        {preReqIsVal(newCustomRequests, 'custom_req', newCustomRequests?.length - 1) &&
                          <div className='error-message mt-1'>{valMsg?.rem_custom}
                          </div>}
                      </div>
                    </>}
                  </>}
              </div>
              <div className='modal-footer'>
                {confirmClearData ? <>
                  <button className='au-btn' onClick={() => setConfirmClearData(false)}>Go Back</button>
                  <button className='au-btn' onClick={clearData} >Confirm</button>
                </> : <>
                <span className='me-auto fw-bold'>
                  {warning && (newCollectionRequests.length !== 0) && !submitting && <div>
                    {collectionData?.entity_id === 'all' ?
                      'This collection cant be updated since it is not created by you' : <>
                        <input type='checkbox'
                          checked={updateCollection}
                          onFocus={(e) => resetOtherSuggestions(e)}
                          onChange={(e) => setUpdateCollection(prevState => !prevState)}
                          className='me-2 hand-symbol' />
                        {warning}</>}</div>}
                    {!valMsg.error && !submitting && <div>
                      <input type='checkbox'
                          checked={notify}
                          onFocus={(e) => resetOtherSuggestions(e)}
                          onChange={()=> setNotify(prevState => !prevState)}
                          className='me-2 hand-symbol'/>Notify client about these requests</div>}
                  </span>
                  <button disabled={submitting} className={submitting ? 'au-btn-disabled' : 'au-btn'} onClick={cancelModal}>Cancel</button>
                  <button disabled={valMsg.error || submitting} className={valMsg.error || submitting ? 'au-btn-disabled' : 'au-btn'} onClick={submitData}>Submit</button>
                </>}
              </div>
            </>}
        </div>
      </div>
    </div>
  )
}

export default AddRequestModal