import React, { useEffect, useState } from 'react'
import Backdrop from '../Backdrop';
import { Tooltip } from 'react-tooltip';
import AddRequestModal from '../AddRequestModal';
import ClientsDocTable from '../ClientsDocTable';
import ModifyCaseModal from '../ModifyCaseModal';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import NotifyClientModal from '../NotifyClientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CaseCard = (props) => {

    const { clientData, caseData, fetchClientData } = props;
    const { id, title, spoc_name, description, no_of_doc_up, no_of_doc_req, status, client_id } = caseData;
    const [modalIsOpen, setModalIsOpen] = useState({ addRequest: false, notify: false, editCase: false });
    const [docSection, setDocSection] = useState(false);
    const [reqDocData, setReqDocData] = useState([]);
    const [docError, setDocError] = useState('');

    const fetchCaseRequests = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/documents/requestsData/${caseData.id}`);
            setReqDocData(response.data);
        } catch (err) {
            if(err?.response?.data?.error) setDocError(err.response.data.error)
            else setDocError('Failed to connect with server')
        }
    }

    const openModal = (modalType) => {
        if (modalType === 'addRequest') {
            setModalIsOpen({ addRequest: true, notify: false, editCase: false });
        } else if (modalType === 'notify') {
            setModalIsOpen({ addRequest: false, notify: true, editCase: false });
        } else {
            setModalIsOpen({ addRequest: false, notify: false, editCase: true });
        }
    };

    const closeModal = () => {
        setModalIsOpen({ addRequest: false, notify: false, editCase: false });
    };

    const toggleDocSection = () => {
        setDocSection(prevState => !prevState);
    }

    let docColor = null;
    if (no_of_doc_req === no_of_doc_up) {
        docColor = {
            color: 'green',
        }
    } else if (no_of_doc_up === 0) {
        docColor = {
            color: 'red'
        }
    } else {
        docColor = {
            color: 'orange'
        }
    }

    let caseStatusColor = null;
    if (status === 'Active') {
        caseStatusColor = 'text-bg-success'
    } else if (status === 'Inactive') {
        caseStatusColor = 'text-bg-secondary'
    } else {
        caseStatusColor = 'text-bg-danger'
    }

    const createdAtDate = new Date(clientData?.createdAt);
    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }
    const formattedDateTime = createdAtDate.toLocaleString('en-AU', options);

    useEffect(() => {
        if (docSection) {
            fetchCaseRequests();
        }
    }, [docSection]) //eslint-disable-line

    const info = 'Notify Client';

    return (
        <div className='card mx-2 mb-5'>
            {clientData ? <>
                <div className='client-name mt-2'>
                    <span className='fw-bold'>{clientData?.name}</span>
                    <span className='ms-2 sf'>{clientData?.email}</span>
                </div>
                <div className='create-details mb-2'>
                    Account added on : {formattedDateTime} | Created by: {clientData?.created_by}
                </div>
            </> : null}
            <div className={`client-desc ${clientData ? '': 'mt-5'}`}>
                <div className='major-details mx-5'>
                    <span className=''><u>Title</u>: {title}</span>
                    <span className=''><u>Reference</u>: {id}</span>
                    <span className=''><u>Assignee</u>: {spoc_name}</span>
                    <span className=''><u>Case status</u>: <div className={`badge ${caseStatusColor}`}>{status}</div></span>
                    <span className=''><u>No of documents</u>:
                        <div className='fw-bold fs-4' style={docColor}>
                            {no_of_doc_up}/{no_of_doc_req}
                        </div></span>
                </div>
                <div className='client-desc px-5 mb-2'>
                    <u>Description</u>: {description}
                </div>
            </div>
            <span className='notify-client d-flex align-items-center mt-2'>
                <button onClick={() => openModal('addRequest')} className='add-req-btn me-3'>Add Request</button>
                {no_of_doc_up !== no_of_doc_req && <><img
                    data-tooltip-id='notify-tooltip'
                    data-tooltip-content={info}
                    onClick={() => openModal('notify')}
                    className='me-3' src='/assets/message.png'
                    alt='message'
                    width='30px' />
                    <Tooltip id='notify-tooltip' place='top' effect='solid' /></>}
                <img onClick={() => openModal('editCase')} className='me-3' src='/assets/edit-logo.png' alt='edit' width='25px' />
            </span>
            <div className='doc-title my-2 ms-4' onClick={toggleDocSection}>
                Documents
                <FontAwesomeIcon className='ms-2' icon={docSection ? faChevronUp : faChevronDown} />
            </div>
            {docSection ? (
                docError ? (
                    <div className='no-data-banner fw-bold pt-5'>{docError}</div>
                ) : reqDocData.length === 0 ? (
                    <div className='no-data-banner fw-bold pt-5'>There is no request added yet</div>
                ) : (
                    <ClientsDocTable
                        reqDocData={reqDocData}
                        caseTitle={title}
                        clientId={client_id}
                        fetchCaseRequests={fetchCaseRequests}
                        fetchCasesData={fetchClientData}
                    />
                )
            ) : null}
            <Backdrop isOpen={modalIsOpen.addRequest || modalIsOpen.notify || modalIsOpen.editCase} />
            {modalIsOpen.addRequest && <AddRequestModal
                isOpen={modalIsOpen.addRequest}
                closeModal={closeModal}
                clientId={client_id}
                caseId={caseData.id}
                fetchCaseRequests={fetchCaseRequests}
                fetchClientData={fetchClientData}
            />}
            {modalIsOpen.editCase && <ModifyCaseModal
                isOpen={modalIsOpen.editCase}
                caseData={props.caseData}
                openModal={openModal}
                closeModal={closeModal}
                fetchClientData={fetchClientData} />}
            {modalIsOpen.notify && <NotifyClientModal
                isOpen={modalIsOpen.notify}
                case_id={caseData.id}
                client_id={client_id}
                closeModal={closeModal} />}
        </div>
    )
}

export default CaseCard