import React, { useEffect, useState } from 'react'
import './entityDashboard.css'
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import CaseAccordian from '../../components/CaseAccordian';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const EntityDashboard = () => {

  const api = axiosRequestInterceptor(true);
  const selected = 'Dashboard';
  const userType = 'entity';
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
  const [dashboardData, setDashboardData] = useState(null);
  const [sort, setSort] = useState('latest_first');
  const [serverErr, setServerErr] = useState(false);

  const handleSort = (sortBy) => {
    if (sort === 'client_name_asc' && sortBy === 'client_name') setSort('client_name_desc')
    else if (sort === 'id_asc' && sortBy === 'id') setSort('id_desc')
    else if (sort === 'status_asc' && sortBy === 'status') setSort('status_desc')
    else if (sort === 'title_asc' && sortBy === 'title') setSort('title_desc')
    else if (sort === 'date_asc' && sortBy === 'date') setSort('date_desc')
    else if (sortBy === 'client_name') setSort('client_name_asc')
    else if (sortBy === 'id') setSort('id_asc')
    else if (sortBy === 'status') setSort('status_asc')
    else if (sortBy === 'title') setSort('title_asc')
    else if (sortBy === 'date') setSort('date_asc')
}

  const fetchLatestData = async () => {
    try {
      let noOfCases = 5;
      const response = await api.get(`/clients/dashboardData/${sort}/${noOfCases}`)
      setDashboardData(response.data)
    } catch (err) {
      setServerErr(true);
    }
  }

  useEffect(() => {
    fetchLatestData();
    setServerErr(false)
  }, [sort]) //eslint-disable-line

  const EntityDashboardDetails = () => {
    return (
      <div className='mx-0 d-flex gap-5'>
        <div className='px-md-5 db-entity-info'>
          <img src='/assets/users.png' alt='Clients' width='40px' />
          <span className='db-stats'>Total Clients</span>
          <span className='fw-bold me-2'>{dashboardData?.clientsCount}</span>
        </div>
        <div className='px-md-5 db-entity-info'>
          <img src='/assets/folder.png' alt='Clients' width='40px' />
          <span className='db-stats'>Total Documents</span>
          <span className='fw-bold me-2'>{dashboardData?.documentsCount}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='page-with-navbar'>
      <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
        <Profilebar userType={userType} collapsed={collapsed} />
        <div className='container px-4 py-2 scroll-window'>
          <EntityDashboardDetails />
          <div className='scrollable-hor card mt-2'>
            <div className='table-head sortBy pt-1'>
              <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('client_name')}>
                <span className='me-2'>Client Name</span>
                {sort === 'client_name_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'client_name_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>
              <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('id')}>
                <span className='me-2'>Id</span>
                {sort === 'id_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'id_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>
              {window.innerWidth < 768 && <span className='client-head-col hand-symbol ps-3 client-status'
                onClick={() => handleSort('status')} ><span className='me-2'>Status</span>
                {sort === 'status_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'status_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>}
              {window.innerWidth < 768 && <span className='client-head-col ps-3'>Action</span>}
              <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('title')}>
                <span className='me-2'>Title</span>
                {sort === 'title_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'title_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>
              <span className='client-head-col hand-symbol ps-3' onClick={() => handleSort('date')}>
                <span className='me-2'>Date Requested</span>
                {sort === 'date_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'date_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>
              <span className='client-head-col ps-3'>Date Uploaded</span>
              {window.innerWidth > 768 && <span className='client-head-col hand-symbol ps-3'
                onClick={() => handleSort('status')}><span className='me-2'>Status</span>
                {sort === 'status_asc' ? <FontAwesomeIcon icon={faArrowUp} />:
                sort === 'status_desc' ? <FontAwesomeIcon icon={faArrowDown} />: null}
              </span>}
              {window.innerWidth > 768 && <span className='client-head-col ps-3'>Action</span>}
            </div>
            {!dashboardData ? <div className='no-data-banner fw-bold pt-5'>Loading...</div> :
            serverErr ? <div className='no-data-banner fw-bold pt-5'>Failed to fetch dashboard data</div>:
              !dashboardData.casesData.length ? <div className='no-data-banner fw-bold pt-5'>No Cases have been created yet</div> :
                dashboardData.casesData.map((caseData) => {
                  return (
                    <CaseAccordian key={caseData.id} caseData={caseData} fetchCasesData={fetchLatestData} />
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EntityDashboard