import React, { useEffect, useState } from 'react'
import './caseAccordian.css'
import { useNavigate } from 'react-router-dom';
import ClientsDocTable from '../ClientsDocTable';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import Backdrop from '../Backdrop';
import NotifyClientModal from '../NotifyClientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const CaseAccordian = (props) => {

  const { caseData, fetchCasesData } = props;
  const navigate = useNavigate();
  const [docSection, setDocSection] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reqDocData, setReqDocData] = useState([]);
  const [docError, setDocError] = useState('');

  const fetchCaseRequests = async () => {
    try {
      const api = axiosRequestInterceptor(true);
      const response = await api.get(`/documents/requestsData/${caseData.id}`);
      setReqDocData(response.data);
    } catch (err) {
      if(err?.response?.data?.error) setDocError(err.response.data.error)
      else setDocError('Failed to connect with server')
    }
  }

  const openModal = (e) => {
    e.stopPropagation();
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const toggleDocSection = () => {
    setDocSection(prevState => !prevState);
  }

  const gotoClientDetails = (caseData) => {
    navigate(`/client_details/${caseData.client_id}`, { state: { caseData } })
  }

  let docColor = null;
  if (caseData.no_of_doc_req === caseData.no_of_doc_up) {
    docColor = {
      color: 'green',
    }
  } else if (caseData.no_of_doc_up === 0) {
    docColor = {
      color: 'red'
    }
  } else {
    docColor = {
      color: 'orange'
    }
  }

  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  }
  const date_req = new Date(caseData?.date_req);
  const date_up = new Date(caseData?.date_up);
  const formatted_date_req = date_req.toLocaleString('en-AU', options);
  const formatted_date_up = date_up.toLocaleString('en-AU', options);

  useEffect(() => {
    if (docSection) {
      fetchCaseRequests();
    }
  }, [docSection]) //eslint-disable-line

  return (
    <>
      <div onClick={toggleDocSection} className='client-acc-head pt-2'>
        <div>
          <span className='client-col ps-3'>
            {caseData.client_name}
          </span>
          <span className='client-col ps-3 client-id'>
            <u onClick={() => gotoClientDetails(caseData)}>{caseData.id}</u>
          </span>
          {window.innerWidth < 768 && <span className='client-col ps-3 fs-5 fw-bold client-status' style={docColor}>
            {`${caseData.no_of_doc_up}/${caseData.no_of_doc_req}`}
          </span>}
          {window.innerWidth < 768 && <span className='client-col ps-3'>
            <div className='d-flex'>
              <FontAwesomeIcon onClick={() => gotoClientDetails(caseData)} icon={faEye} />
              {caseData.no_of_doc_req !== caseData.no_of_doc_up && <img onClick={openModal} className='ms-2' src='/assets/message.png' alt='message' width='20px' />}
              <FontAwesomeIcon className='ms-auto' icon={docSection ? faChevronUp : faChevronDown} />
            </div>
          </span>}
          <span className='client-col ps-3'>
            {caseData.title}
          </span>
          <span className='client-col ps-3'>
            {caseData.date_req ? formatted_date_req : '-'}
          </span>
          <span className='client-col ps-3'>
            {caseData.date_up ? formatted_date_up : '-'}
          </span>
          {window.innerWidth > 768 && <span className='client-col ps-3 fs-5 fw-bold' style={docColor}>
            {`${caseData.no_of_doc_up}/${caseData.no_of_doc_req}`}
          </span>}
          {window.innerWidth > 768 && <span className='client-col ps-3'>
            <div className='d-flex'>
              <FontAwesomeIcon onClick={() => gotoClientDetails(caseData)} icon={faEye} className='align-self-center' size='lg'/>
              {caseData.no_of_doc_req !== caseData.no_of_doc_up &&
              <img onClick={openModal} className='ms-2' src='/assets/message.png' alt='message' width='25px' />}
              <FontAwesomeIcon className='ms-auto' icon={docSection ? faChevronUp : faChevronDown} />
            </div>
          </span>}
        </div>
      </div>
      {docSection ? (
        docError ? (
          <div className='no-data-banner fw-bold pt-5'>{docError}</div>
        ) : reqDocData.length === 0 ? (
          <div className='no-data-banner fw-bold pt-5'>There is no request added yet</div>
        ) : (
          <ClientsDocTable
            reqDocData={reqDocData}
            caseTitle={caseData.title}
            clientId={caseData.client_id}
            fetchCaseRequests={fetchCaseRequests}
            fetchCasesData={fetchCasesData} />
        )
      ) : null}
      <Backdrop isOpen={modalIsOpen} />
      {modalIsOpen && <NotifyClientModal
        isOpen={modalIsOpen}
        case_id={caseData.id}
        client_id={caseData.client_id}
        closeModal={closeModal} />}
    </>
  )
}

export default CaseAccordian