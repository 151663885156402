import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useEffect, useState } from 'react'

const ModifyDocTypeModal = (props) => {
    const initialState = {
        doc_name: '',
        description: '',
        type_of_req: ''
    }
    const api = axiosRequestInterceptor(true);
    const userName = sessionStorage.getItem('SDX_user_name');
    const { isOpen, closeModal, existingData, fetchCollectionData } = props;
    const [ modalData, setModalData ] = useState(initialState);
    const [validMsg, setValidMsg] = useState({ error: true });

    const handleChange = (e) => {
        const tempData = { ...modalData, [e.target.name]: e.target.value }
        setModalData(tempData);
    }

    const clearData = () => {
        closeModal();
        setModalData(initialState);
    }

    const newDocType = async () => {
        try {
            await api.post('/doc_types/create', {
                doc_name: modalData.doc_name,
                description: modalData.description,
                type_of_req: modalData.type_of_req,
                created_by: userName
            })
            fetchCollectionData();
            clearData();
        } catch (err) {
            console.error(err)
            if (err?.response?.data?.doc_name) {
                setValidMsg({ error: true, doc_name: err.response.data.doc_name });
            } else if(err?.response?.data?.error) {
                setValidMsg({ error: true, type_of_req: err.response.data.error });
            } else {
                setValidMsg({ error: true, type_of_req: 'Failed to connect with server' });
            }
        }
    }

    const editDocType = async () => {
        try {
            await api.put('/doc_types/edit', {
                id: existingData.id,
                doc_name: modalData.doc_name,
                description: modalData.description,
                type_of_req: modalData.type_of_req
            });
            fetchCollectionData();
            clearData();
        } catch (err) {
            if (err?.response?.data?.doc_name) {
                setValidMsg({ error: true, doc_name: err.response.data.doc_name });
            } else if(err?.response?.data?.error) {
                setValidMsg({ error: true, type_of_req: err.response.data.error });
            } else {
                setValidMsg({ error: true, type_of_req: 'Failed to connect with server' });
            }
        }
    }

    const deleteDocType = async () => {
        try {
            await api.delete(`/doc_types/delete/${existingData.id}`);
            fetchCollectionData();
            clearData();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, server: err.response.data.error });
            else setValidMsg({ error: true, server: 'Failed to connect with server'})
        }
    }

    const submitData = () => {
        if (existingData.action === 'New') {
            newDocType();
        }
        else if (existingData.action === 'Edit') {
            editDocType();
        } else {
            deleteDocType();
        }
    }

    useEffect(()=> {
        if(existingData?.action === 'Edit'){
            setModalData({
                doc_name: existingData.doc_name,
                description: existingData.description,
                type_of_req: existingData.type_of_req
            })
        }else{
            setModalData(initialState);
        }
    },[existingData, isOpen]) //eslint-disable-line

    useEffect(() => {
        let error = false;
        let doc_name = '';
        let description = '';
        let type_of_req = '';

        if (!modalData?.doc_name) {
            error = true;
            doc_name = 'Document name is required'
        } else if (modalData?.doc_name.length < 3) {
            error = true;
            doc_name = 'Document name should contain more than 2 characters'
        }
        
        if (!modalData?.description) {
            error = true;
            description = 'Description is required'
        } else if (modalData?.description < 3) {
            error = true;
            description = 'Description should contain more than 2 characters'
        }

        if (!modalData?.type_of_req) {
            error = true;
            type_of_req = 'Request type is required'
        }

        setValidMsg({ error, doc_name, description, type_of_req });

    }, [modalData])

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            {existingData?.action} Document Type
                        </h1>
                    </div>
                    <div className='modal-body px-5'>
                        {existingData?.action === 'Delete' ? <div>
                            Are you sure you want to delete the document type {existingData?.name}
                            <div className='error-message mt-1'>{validMsg?.server}</div>
                        </div> : <>
                            <div className='au-field w-50'>
                                <input
                                    className='au-input ps-2'
                                    type='text'
                                    name='doc_name'
                                    value={modalData?.doc_name || ''}
                                    onChange={handleChange}
                                    placeholder='Document name'
                                    autoComplete='off' />
                                <label className='au-label'>Document name</label>
                                <div className='error-message mt-1'>{validMsg?.doc_name}
                                </div>
                            </div>
                            <div className='au-field'>
                                <input
                                    className='au-input ps-2'
                                    type='text'
                                    name='description'
                                    value={modalData?.description || ''}
                                    onChange={handleChange}
                                    placeholder='Description'
                                    autoComplete='off' />
                                <label className='au-label'>Description</label>
                                {!validMsg?.doc_name && <div className='error-message mt-1'>{validMsg?.description}
                                </div>}
                            </div>
                            <div className='mt-4'>
                                <select
                                    name='type_of_req'
                                    value={modalData?.type_of_req}
                                    onChange={handleChange}
                                    className='type-box'>
                                    <option value='' disabled>Select type</option>
                                    <option value='Upload' >Upload from client</option>
                                    <option value='Download & Upload'>Download & upload</option>
                                    <option value='Download'>Download for client</option>
                                </select>
                                {!validMsg?.description && <div className='error-message mt-1'>
                                    {validMsg?.type_of_req}</div>}
                            </div>
                        </>}
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button
                            disabled={validMsg.error && existingData?.action !== 'Delete'}
                            className={validMsg.error && existingData?.action !== 'Delete' ? 'au-btn-disabled' : 'au-btn'}
                            onClick={submitData}>
                            {existingData?.action === 'New' ? 'Submit' :
                                existingData?.action === 'Edit' ? 'Update' :
                                    'Delete'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModifyDocTypeModal