import React from 'react'
import './prospectDashboard.css'
import Purchaser from '../../components/Purchaser'
import Vendor from '../../components/Vendor'

const ProspectDashboard = () => {

    const userName = sessionStorage.getItem('SDX_user_name');
    const entityLogo = sessionStorage.getItem('SDX_entity_logo');
    const entityName = sessionStorage.getItem('SDX_entity_name');
    const client_type = sessionStorage.getItem('SDX_role');
    const status = sessionStorage.getItem('SDX_status');

    return (
        <div className='user-page'>
            <div className='profile-bar'>
                <div>
                    <img src={entityLogo ? entityLogo : '/assets/def-logo.png'} alt='logo' className='user-header-logo' />
                    <span className='ms-2 fw-bold d-none d-md-inline'>{entityName}</span>
                </div>
                <div className='user-detail align-items-center'>
                    <span className='fw-bold'>{userName}</span>
                </div>
            </div>
            <div className='scroll-window'>
                {status === 'Submitted' && <div className='submit-page'>
                    <span className='fw-bold ps-5'>Thank you for providing this information. Feel free to contact us if you wish to discuss further.</span>
                </div>}
                {status === 'Pending' && <div className='prospect-body'>
                {client_type === 'purchaser' && <Purchaser />}
                {client_type === 'vendor' && <Vendor />}
                </div>}
                <div className='user-footer d-flex'>
                    <div className='m-auto'>Powered by Secure Document Exchange</div>
                </div>
            </div>
        </div>
    )
}

export default ProspectDashboard