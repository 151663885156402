import React, { useState, useEffect } from 'react'

const FileViewer = (props) => {
    const { file, setViewFile } = props;
    const [fileContent, setFileContent] = useState('');

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            setFileContent(content);
        };
        reader.readAsDataURL(file);
    }, [file]);
    return (
        <div className='m-auto my-2'>
            <div><iframe
                title='File Viewer'
                width='750px'
                height='500px'
                src={fileContent}
            /></div>
            <div className='my-2'>
                <button className='au-btn' onClick={() => setViewFile({ view: false, file: null })}>Close</button>
            </div>
        </div>
    )
}

export default FileViewer