import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Profilebar from '../../components/Profilebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faEnvelope, faEye, faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons';
import Backdrop from '../../components/Backdrop';
import ProspectActionModal from '../../components/ProspectActionModal';
import { useDebounce } from 'use-debounce';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { Link } from 'react-router-dom';

const Prospects = () => {

    const selected = 'Prospects';
    const userType = 'entity';
    const api = axiosRequestInterceptor(true);
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [prospects, setProspects] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [error, setError] = useState('');

    const fetchProspectsData = async() => {
        try {
            const response = await api.get(`/prospects/list/${searchKeyword}`);
            setProspects(response.data)
        } catch (err) {
            console.error(err);
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server');
        }
    }

    const changeKeyword = (e) => {
        setSearchKeyword(e.target.value);
    }

    const openModal = (action, prospect) => {
        setModalIsOpen(true);
        setModalData({ action, prospect });
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const [debouncedInput] = useDebounce(searchKeyword, 500);

    useEffect(()=> {
        fetchProspectsData();
    },[debouncedInput]) //eslint-disable-line

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar userType={userType} collapsed={collapsed} />
                <div className='container px-4 py-2 scroll-window pb-4'>
                    <div className='clients-action-tab mt-2'>
                        <span className='search-holder px-1 ms-auto mb-3'>
                            <FontAwesomeIcon className='mx-1' color='gray' icon={faMagnifyingGlass} />
                            <input className='input-clients-search' onChange={changeKeyword} value={searchKeyword} type='text' placeholder='Search' />
                        </span>
                        <button onClick={() => openModal('Add Prospect', prospects)} className='add-clients-btn ms-md-5 mb-3'>
                            <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg' />Add Prospect</button>
                    </div>
                    <div className='scrollable-hor card mt-2'>
                        {error ? <div className='m-auto p-5'>{error}</div>: prospects.length > 0 ? <table className='table mt-1'>
                            <thead>
                                <tr>
                                    <th>Prospect Name</th>
                                    <th>Email</th>
                                    <th>Prospect Type</th>
                                    <th>Case Name</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prospects?.map(pro => <tr key={pro.id}>
                                    <td>{pro.name}</td>
                                    <td>{pro.email}</td>
                                    <td>{pro.prospect_type}</td>
                                    <td>{pro.case_name ? pro.case_name : '-'}</td>
                                    <td><span className={`badge rounded-pill text-bg-${pro.status === 'Pending' ? 'danger' : 'success' }`}>
                                        {pro.status}</span></td>
                                    <td>
                                        <FontAwesomeIcon style={{ marginRight: '10px', cursor: 'pointer', color: '#0d6efd' }} onClick={() => openModal('Resend Mail', pro)} icon={faEnvelope} />
                                        <FontAwesomeIcon style={{ marginRight: '10px', cursor: 'pointer', color: '#0d6efd' }} onClick={() => openModal('Delete', pro)} icon={faTrash} />
                                        {Object.keys(pro.data).length !== 0 && <Link to={`/prospectData/${pro.id}`} state={pro}>
                                        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faEye}/>
                                        </Link>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>: <div className='m-auto p-5'>There is no Prospect added yet</div>}
                    </div>
                </div>
            </div>
            {modalIsOpen && <>
                <Backdrop isOpen={modalIsOpen} />
                <ProspectActionModal
                    isOpen={modalIsOpen}
                    prospectData={modalData}
                    fetchProspectsData={fetchProspectsData}
                    closeModal={closeModal} /></>}
        </div>
    )
}

export default Prospects