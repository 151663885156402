import React from 'react';
import './doughnutChart.css';
import {
    Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend);

const DoughnutChart = (props) => {

    const { consumedSize, alottedSize } = props;
    const consumedData = parseFloat(consumedSize);
    const alottedData = parseFloat(alottedSize);

    const data = {
        labels: ['Space Used', 'Space Available'],
        datasets: [
            {
                data: [consumedData, alottedData],
                backgroundColor: ['#208da9', '#e9e9e9'],
                borderWidth: 0,
            }
        ],
    };

    const options = {
        cutout: '70%',
        rotation: -90,
        circumference: 180,
        aspectRatio: 2,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const dataValue = context.raw;
                        if(dataValue >= 1){
                            return `${dataValue.toFixed(2)} GB`;
                        } else if(dataValue * 1024 >= 1){
                            return `${(dataValue * 1024).toFixed(2)} MB`;
                        } else {
                            return `${(dataValue * 1024 * 1024).toFixed(2)} KB`;
                        }
                            
                    }
                }
            }
        }
    }


    return (
        <div className='chart-container ps-4'>
            <Doughnut options={options} data={data}/>
            <div className='ms-3 ms-md-5'>
                {data.labels.map((label, index) => {
                    let storageSize = '';
                    const dataValue = data.datasets[0].data[index];
                    if(dataValue >= 1){
                        storageSize = `${dataValue.toFixed(2)} GB`
                    }else if(dataValue * 1024 >= 1){
                        storageSize = `${(dataValue * 1024).toFixed(2)} MB`
                    }else {
                        storageSize = `${(dataValue * 1024 * 1024).toFixed(2)} KB`
                    }
                return (
                    <div key={index} className='legend-item mt-3'>
                        <span className='color-box' style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}>&nbsp; &nbsp; &nbsp;</span>
                        <span className='ms-2'>{label}</span>
                        <div className='ms-4 fw-bold'>{storageSize}</div>
                    </div>
                )})}
            </div>
        </div>
    )

}
export default DoughnutChart;