import React, { useState } from 'react'
import './modifyUserModal.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const ModifyUserModal = (props) => {
  const { isOpen, closeModal, modalData, fetchUsers } = props;
  const [errMsg, setErrMsg] = useState('');
  const [confirmDel, setConfirmDel] = useState('');
  const api = axiosRequestInterceptor(true);

  const modifyUserRole = async () => {
    try {
      setErrMsg('');
      let role = '';
      if (modalData.user.role === 'system_user') {
        role = 'system_admin'
      } else if (modalData.user.role === 'system_admin') {
        role = 'system_user'
      } else if (modalData.user.role === 'entity_user') {
        role = 'entity_admin'
      } else if (modalData.user.role === 'entity_admin') {
        role = 'entity_user'
      }
      await api.put('/users/role_change', {
        id: modalData.user.id,
        role: role,
        entity_id: modalData.user.entity_id
      });
      closeModal();
      fetchUsers();
    } catch (err) {
      if(err?.response?.data?.error) setErrMsg(err.response.data.error);
      else setErrMsg('Failed to connect with server')
    }
  }

  const modifyUserStatus = async () => {
    try {
      setErrMsg('');
      let status = '';
      if (modalData.user.status === 'Deactivated') {
        status = 'Inactive'
      } else {
        status = 'Deactivated'
      }
      await api.put('/users/status_change', {
        id: modalData.user.id,
        status: status,
        entity_id: modalData.user.entity_id
      });
      closeModal();
      setConfirmDel('');
      fetchUsers();
    } catch (err) {
      if(err?.response?.data?.error) setErrMsg(err.response.data.error);
      else setErrMsg('Failed to connect with server');
    }
  }

  const sendLinkMail = async () => {
    try {
      await api.post(`/users/mail`, { email: modalData.user.email, expiresIn: '1d' });
      closeModal();
    } catch (err) {
      if(err?.response?.data?.error) setErrMsg(err.response.data.error);
      else setErrMsg('Failed to connect with server')
    }
  }

  const clearData = () => {
    setConfirmDel('');
    closeModal();
  }

  const submitData = () => {
    if (modalData.action === 'Modify User Roles') {
      modifyUserRole();
    } else if (modalData.action === 'Modify User Status') {
      modifyUserStatus();
    } else if (modalData.action === 'Mail Link') {
      sendLinkMail();
    }
  }

  return (
    <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5'>{modalData?.action}</h1>
          </div>
          <div className='modal-body px-5'>
            {modalData?.action === 'Modify User Roles' ?
              <div className='action-content'>Are you sure you want to change the user role of {modalData?.user?.name} to {modalData?.user?.role === 'system_user' || modalData?.user?.role === 'entity_user' ?
                'Admin' : 'User'} ?</div> :
              modalData?.action === 'Modify User Status' ?
                <>
                  <div className='action-content'>Are you sure you want to {modalData?.user?.status === 'Deactivated' ? 'activate' : 'deactivate' } the user {modalData?.user?.name} ?</div>
                  <div className='remove-warning mt-2' >
                    Please Type 'CONFIRM' in the below field to confirm {modalData?.user?.status === 'Deactivated' ? 'Activation' : 'Deactivation' } of user
                  </div>
                  <input className='ps-1 delete-input' type='text' value={confirmDel} onChange={(e) => setConfirmDel(e.target.value)} />
                </> :
                <div className='action-content'>Send link to reset the password of {modalData?.user?.name} ?</div>}
            <div className='mt-1 ms-0 error-message'>
              {errMsg}
            </div>
          </div>
          <div className='modal-footer'>
            <button className='au-btn' onClick={clearData}>Cancel</button>
            <button
              disabled={modalData?.action === 'Modify User Status' && confirmDel !== 'CONFIRM'}
              className={modalData?.action === 'Modify User Status' && confirmDel !== 'CONFIRM' ? 'au-btn-disabled' : 'au-btn'}
              onClick={submitData}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyUserModal