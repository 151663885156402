import React from 'react'
import './error.css'

const Error = () => {
  return (
    <div className="error-container">
        <h2>404 Not Found</h2>
        <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  )
}

export default Error