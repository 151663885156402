import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useEffect, useState } from 'react'

const ProspectActionModal = (props) => {

    const { isOpen, prospectData, fetchProspectsData, closeModal } = props;
    const initialState = { prospect_name: '', email: '', case_name: '', mobile: '', mail_body: '', prospect_type: '' }
    const api = axiosRequestInterceptor(true);
    const [modalData, setModalData] = useState(initialState);
    const [confirmDel, setConfirmDel] = useState('');
    const [touched, setTouched] = useState(false);
    const [validMsg, setValidMsg] = useState({ error: true });
    const [serverErr, setServerErr] = useState('');

    const inputIsTouched = () => {
        setTouched(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let tempData = null;
        if (name === 'mobile') {
            let tempMob = '';
            if (value[0] === '+') {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 5 || value.length === 9) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...modalData,
                [name]: tempMob
            }
        } else {
            tempData = {
                ...modalData,
                [name]: value
            }
        }
        setModalData(tempData);
        setServerErr('')
    }

    const clearData = () => {
        closeModal();
        setTouched(true);
        setModalData(initialState);
    }

    const submitData = async () => {
        if (prospectData.action === 'Add Prospect') {
            try {
                await api.post('/prospects/create', modalData);
                fetchProspectsData();
                clearData();
            } catch (err) {
                if (err?.response?.data?.error) {
                    setServerErr(err.response.data.error);
                } else {
                    setServerErr('Failed to connect with server');
                }
            }
        } else if (prospectData.action === 'Delete') {
            try {
                await api.delete(`/prospects/delete/${prospectData.prospect?.id}`);
                fetchProspectsData();
                clearData();
            } catch (err) {
                if (err?.response?.data?.error) {
                    setServerErr(err.response.data.error);
                } else {
                    setServerErr('Failed to connect with server');
                }
            }
        } else if (prospectData.action === 'Resend Mail') {
            try {
                await api.put('/prospects/update/', { id: prospectData.prospect?.id });
                fetchProspectsData();
                clearData();
            } catch (err) {
                if (err?.response?.data?.error) {
                    setServerErr(err.response.data.error);
                } else {
                    setServerErr('Failed to connect with server');
                }
            }
        } else {
            clearData();
        }
    }

    useEffect(() => {
        if (prospectData.action === 'Add Prospect') {
            setServerErr('');
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const mobilePatterns = [
                /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
                /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
                /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
                /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
            ];
            let error = false;
            let prospect_name = '';
            let email = '';
            let case_name = '';
            let mobile = '';
            let mail_body = '';
            let prospect_type = '';

            if (!modalData.prospect_name) {
                error = true;
                prospect_name = 'Prospect name is required'
            } else if (modalData.prospect_name.length < 3) {
                error = true;
                prospect_name = 'Prospect name should contain more than 2 Characters'
            }

            if (!modalData.email) {
                error = true;
                email = 'Email address is required'
            } else if (!emailPattern.test(modalData.email)) {
                error = true;
                email = 'Email address is invalid'
            }

            if (modalData.case_name && modalData.case_name.length < 3) {
                error = true;
                case_name = 'Case name should contain more than 2 Characters'
            }

            if(!modalData.mobile){
                error = true;
                mobile = 'Mobile number is required'
            }else if (!mobilePatterns.some(pattern => pattern.test(modalData.mobile))) {
                error = true;
                mobile = 'Mobile number is invalid'
            }

            if (!modalData.prospect_type) {
                error = true;
                prospect_type = 'Prospect type is required'
            }

            if (modalData.mail_body && modalData.mail_body.length < 3) {
                error = true;
                mail_body = 'Mail body should contain more than 2 Characters'
            }

            prospectData.prospect?.forEach(pro => {
                if (pro.email === modalData.email && pro.case_name === modalData.case_name && pro.prospect_type === modalData.prospect_type) {
                    error = true;
                    case_name = 'Same case name is used for this email once, please type another'
                }
            })

            setValidMsg({ error, prospect_name, email, case_name, mobile, mail_body, prospect_type });
        }
    }, [modalData]) //eslint-disable-line

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            {prospectData.action}
                        </h1>
                    </div>
                    <div className='modal-body m-auto comp-content'>
                        {prospectData.action === 'Add Prospect' && <><div className='au-field'>
                            <input
                                className='au-input w-100 ps-2'
                                type='text'
                                name='prospect_name'
                                value={modalData.prospect_name}
                                onFocus={inputIsTouched}
                                onChange={handleChange}
                                placeholder='Prospect name' />
                            <label className='au-label'>Prospect name</label>
                        </div>
                            {touched && <div className='error-message'>{validMsg?.prospect_name}</div>}
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='email'
                                    value={modalData.email}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Email' />
                                <label className='au-label'>Email</label>
                            </div>
                            {!validMsg?.prospect_name && <div className='error-message'>{validMsg?.email}</div>}
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='case_name'
                                    value={modalData.case_name}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Case name (Optional for new prospect)' />
                                <label className='au-label'>Case name</label>
                            </div>
                            {!validMsg?.email && <div className='error-message'>{validMsg?.case_name}</div>}
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='mobile'
                                    value={modalData.mobile}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Mobile number' />
                                <label className='au-label'>Mobile number</label>
                            </div>
                            {!validMsg?.email && <div className='error-message'>{validMsg?.mobile}</div>}
                            <div className='au-field'>
                                <textarea
                                    className='mail-area w-100 ps-2'
                                    type='text'
                                    name='mail_body'
                                    value={modalData.mail_body}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Body of the mail' />
                                <label className='au-label'>Body of the mail</label>
                            </div>
                            {!validMsg?.mobile && <div className='error-message mt-1'>{validMsg?.mail_body}</div>}
                            <div>
                                <select
                                    name='prospect_type'
                                    value={modalData.prospect_type}
                                    onChange={handleChange}
                                    onFocus={inputIsTouched}
                                    className='select-client mt-3'>
                                    <option disabled value=''>Select prospect type</option>
                                    <option value='vendor'>Vendor</option>
                                    <option value='purchaser'>Purchaser</option>
                                </select>
                            </div>
                            {!validMsg.mobile && <div className='error-message mt-2'>{validMsg.prospect_type}</div>}</>}
                        {prospectData.action === 'Resend Mail' && <>
                            <div className='mt-2'>Are you sure that you want to send the mail again to the prospect?</div>
                            <div><i className='text-secondary'>(Any data that is already filled by the prospect will be replaced by new data)</i></div>
                        </>}
                        {prospectData.action === 'Delete' && <>
                            <div className='mt-2'>Are you sure that you want to delete the prospect?</div>
                            <div><i className='text-secondary'>(Link which was sent in mail cannot be accessed by the prospect in future)</i></div>
                            <div className='text-danger'>Please type 'CONFIRM' in the below field to confirm deletion of prospect</div>
                            <input className='ps-1 delete-input' type='text' value={confirmDel} onChange={(e) => {
                                setConfirmDel(e.target.value);
                                setServerErr('');
                            }} />
                        </>}
                        <div className='error-message mt-2'>{serverErr}</div>
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={closeModal}>Cancel</button>
                        <button disabled={(prospectData.action === 'Add Prospect' && validMsg.error) || (prospectData.action === 'Delete' && confirmDel !== 'CONFIRM')}
                            className={(prospectData.action === 'Add Prospect' && validMsg.error) || (prospectData.action === 'Delete' && confirmDel !== 'CONFIRM') ? 'au-btn-disabled' : 'au-btn'} onClick={submitData}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProspectActionModal