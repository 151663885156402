import React, { useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import ChangePasswordModal from '../ChangePasswordModal';
import Backdrop from '../Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faChartPie, faFolderOpen, faIndent, faLock, faOutdent, faUserPlus, faUserShield, faUsers } from '@fortawesome/free-solid-svg-icons';

const Navbar = (props) => {

  const { userType, selected, collapsed, setCollapsed } = props;
  const userName = sessionStorage.getItem('SDX_user_name');
  const userRole = sessionStorage.getItem('SDX_user_role');
  const entity_logo = sessionStorage.getItem('SDX_entity_logo');
  const entity_name = sessionStorage.getItem('SDX_entity_name');
  const add_prospect = sessionStorage.getItem('SDX_prospect');
  const [ modalIsOpen, setModalIsOpen ] = useState(false);

  const collapseNav = () => {
    setCollapsed(prevState => !prevState);
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={collapsed ? 'collapsed-nav-bar' : window.innerWidth < 768 ? 'mob-nav-bar' : 'nav-bar'}>
      <div className='logo-bar'>
        {(window.innerWidth > 768 || !collapsed) &&
        <img src={userType=== 'entity' && entity_logo ? entity_logo : userType=== 'entity' ? '/assets/def-logo.png' : '/assets/sdx-logo.png'} alt='logo' className={userType=== 'entity' ? 'entity-logo-db m-1 ms-4' : 'logo'} />}
        {userType=== 'entity' && !collapsed && <span className='ms-2 fw-bold'>{entity_name}</span>}
      </div>
      <div className='nav-arrow'>
      {collapsed ?
        <FontAwesomeIcon className='expand-arrow' onClick={collapseNav} icon={faIndent} />:
        <FontAwesomeIcon className='collapse-arrow' onClick={collapseNav} icon={faOutdent} />
      }
      </div>
      <div className={window.innerWidth < 768 && collapsed ? 'd-none' : 'container px-4'}>
        <Link to={userType=== 'entity' ? `/entity_dashboard/${userName}` : `/dashboard/${userName}`} className={`navLink ps-3 ${selected === 'Dashboard' ? 'active-link' : 'inactive-link'}`}>
          <FontAwesomeIcon icon={faChartPie} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Dashboard</span>
        </Link>
        {add_prospect === 'Yes' && userType === 'entity' && <Link to={`/prospects`} className={`navLink ps-3 ${selected === 'Prospects' ? 'active-link' : 'inactive-link'}`}>
        <FontAwesomeIcon icon={faUserPlus} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Prospects</span>
        </Link>}
        {userType=== 'entity' ? <Link to={`/clients/${userName}`} className={`navLink ps-3 ${selected === 'Clients' ? 'active-link' : 'inactive-link'}`}>
          <FontAwesomeIcon icon={faUsers} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Clients</span>
        </Link>:
        <Link to={`/business_entities/${userName}`} className={`navLink ps-3 ${selected === 'Business Entities' ? 'active-link' : 'inactive-link'}`}>
          <FontAwesomeIcon icon={faBuildingColumns} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Business Entities</span>
        </Link>}
        <Link to={`/documents/${userType}`} className={`navLink ps-3 ${selected === 'Documents' ? 'active-link' : 'inactive-link'}`}>
          <FontAwesomeIcon icon={faFolderOpen} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Documents</span>
        </Link>
        {userRole !== 'system_user' && userRole !== 'entity_user' && <Link to={`/manage_users/${userType}`} className={`navLink ps-3 ${selected === 'Manage Users' ? 'active-link' : 'inactive-link'}`}>
          <FontAwesomeIcon icon={faUserShield} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Manage Users</span>
        </Link>}
        <div onClick={openModal} style={{cursor: 'pointer'}} className='navLink ps-3 inactive-link'>
          <FontAwesomeIcon icon={faLock} />
          <span className={collapsed ? 'd-none' : 'nav-options'}>Change Password</span>
        </div>
      </div>
      <Backdrop isOpen={modalIsOpen}/>
      {modalIsOpen && <ChangePasswordModal isOpen={modalIsOpen} closeModal={closeModal}/>}
    </div>
  )
}

export default Navbar