import React, { useEffect, useState } from 'react'
import './addUserModal.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const AddUserModal = (props) => {

    const { isOpen, closeModal, fetchUsers } = props;
    const entity_id = sessionStorage.getItem('SDX_entity_id');
    const initialState = {
        name: '',
        mobile: '',
        email: '',
        confirmEmail: '',
        user_role: 'User'
    }
    const [validMsg, setValidMsg] = useState({ error: true });
    const [userData, setUserData] = useState(initialState);
    const [mailVisible, setMailVisible] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let tempData = null
        if(name === 'mobile'){
            let tempMob = '';
            if(value[0] === '+'){
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 5 || value.length === 9 ) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...userData,
                [name]: tempMob
            }
        } else {
            tempData = {
                ...userData,
                [e.target.name]: e.target.value
            }
        }
        setUserData(tempData);
    }

    const registerNewUser = async () => {
        try {
            let role = '';
            if (entity_id && userData.user_role === 'User') {
                role = 'entity_user'
            } else if (entity_id && userData.user_role=== 'Admin') {
                role = 'entity_admin'
            } else if (!entity_id && userData.user_role === 'User') {
                role = 'system_user'
            } else if (!entity_id && userData.user_role === 'Admin') {
                role = 'system_admin'
            }
            const api = axiosRequestInterceptor(true);
            await api.post(`/users/register`, {
                name: userData.name,
                mobile: userData?.mobile,
                email: userData.email,
                role: role,
                entity_id: entity_id
            });
            fetchUsers();
            clearData();
        } catch (err) {
            console.error(err);
            if(err?.response?.data.name) setValidMsg({error: true, name: err.response.data.name});
            else if (err?.response?.data?.email) setValidMsg({error: true, email: err.response.data.email});
            else if (err?.response?.data?.error) setValidMsg({error: true, confirmEmail: err.response.data.error});
            else setValidMsg({ error: true, confirmEmail: 'Failed to connect with server'})
        }
    }

    const clearData = () => {
        setUserData(initialState);
        closeModal();
    }

    const submitData = () => {
        registerNewUser();
    }

    useEffect(() => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
          ];
        let error = false;
        let name = '';
        let mobile = '';
        let email = '';
        let confirmEmail = '';
        if (!userData.name) {
            error = true;
            name = 'Name is required';
        } else if (userData.name.length < 3) {
            error = true;
            name = 'Name should contain more than 2 letters'
        }

        if (userData.mobile) {
            if(!mobilePatterns.some(pattern => pattern.test(userData.mobile))){
                error = true;
                mobile = 'Invalid Mobile number';
            }
        }

        if (!userData.email) {
            error = true;
            email = 'Email is required';
        } else if (!emailPattern.test(userData.email)) {
            error = true;
            email = 'Email address is invalid'
        }

        if(userData.email !== userData.confirmEmail){
            error = true;
            confirmEmail = 'Email address is not matching'
        }

        setValidMsg({ error, name, mobile, email, confirmEmail })
    }, [userData])

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>Add User</h1>
                    </div>
                    <div className='modal-body px-5'>
                        <div className='au-field'>
                            <input
                                className='au-input'
                                type='text'
                                name='name'
                                value={userData.name}
                                onChange={handleChange}
                                placeholder='Enter the name' />
                            <label className='au-label'>Name</label>
                        </div>
                        <div className='error-message'>{validMsg?.name}</div>
                        <div className='au-field'>
                            <input
                                className='au-input'
                                type='text'
                                name='mobile'
                                value={userData.mobile}
                                onChange={handleChange}
                                placeholder='Enter the mobile number (optional)' />
                            <label className='au-label'>Mobile number</label>
                        </div>
                        {!validMsg?.name && <div className='error-message'>{validMsg?.mobile}</div>}
                        <div className='au-field'>
                            <input
                                className='au-input'
                                type={mailVisible ? 'text' : 'password'}
                                name='email'
                                value={userData.email}
                                onChange={handleChange}
                                onFocus={()=> setMailVisible(true)}
                                onBlur={()=> setMailVisible(false)}
                                placeholder='Enter the email' />
                            <label className='au-label'>Email</label>
                        </div>
                        {!validMsg?.mobile && <div className='error-message'>{validMsg?.email}</div>}
                        <div className='au-field'>
                            <input
                                className='au-input'
                                type='text'
                                name='confirmEmail'
                                value={userData.confirmEmail}
                                onChange={handleChange}
                                placeholder='Confirm the email' />
                            <label className='au-label'>Confirm email</label>
                        </div>
                        {!validMsg?.email && <div className='error-message'>{validMsg?.confirmEmail}</div>}
                        <div>
                            <div>Select user role</div>
                            <label className='radio-input-field'>
                                <input
                                    type='radio'
                                    name='user_role'
                                    value='Admin'
                                    checked={userData.user_role === 'Admin'}
                                    onChange={handleChange}/>
                                <span className='ms-2'>Admin</span>
                            </label>
                            <label className='radio-input-field'>
                                <input
                                    type='radio'
                                    name='user_role'
                                    value='User'
                                    checked={userData.user_role === 'User'}
                                    onChange={handleChange}/>
                                <span className='ms-2'>User</span>
                            </label>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button disabled={validMsg.error} className={validMsg.error ? 'au-btn-disabled' : 'au-btn'} onClick={submitData}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserModal