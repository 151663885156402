import React from 'react'
import './profilebar.css'
import { useNavigate } from 'react-router-dom'
// import { axiosRequestInterceptor } from '../../api/axiosInstance';

const Profilebar = (props) => {

  const { collapsed, userType } = props;
  const userName = sessionStorage.getItem('SDX_user_name');
  const entity_logo = sessionStorage.getItem('SDX_entity_logo');
  const navigate = useNavigate();
  const goToLoginPage = () => {
    sessionStorage.clear();
    navigate('/login')
  }

  return (
    <div className='profile-bar'>
      {collapsed && window.innerWidth < 768 ?
        <img src={userType === 'entity' && entity_logo ? entity_logo : userType === 'entity' ? '/assets/def-logo.png' : '/assets/sdx-logo.png'}
          alt='logo'
          className={userType === 'entity' ? 'logo-mob ms-4' : 'logo-mob'} /> :
        <>
          {userType === 'admin' && <div className='profile-title'>Secure Document Exchange</div>}
        </>}
        <span className='ms-auto me-2 admin-name'>
          {userName}
          <button onClick={goToLoginPage} className='logout'>Logout</button>
        </span>
    </div>
  )
}

export default Profilebar