import React, { useEffect, useState } from 'react'
import './businessEntities.css'
import BusinessEntitiesList from '../../components/BusinessEntitiesList';
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import Backdrop from '../../components/Backdrop';
import CreateEntityModal from '../../components/CreateEntityModal';
import { useDebounce } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const BusinessEntities = () => {

  const selected = 'Business Entities';
  const userType = 'admin'
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false)
  const [status, setStatus] = useState('Active');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [entityData, setEntityData] = useState([]);
  const [searchKeyword, setSearchKeyword ] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const entitiesPerPage = 3;
  const pagesToShow = 10;
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const fetchEntityData = async () => {
    try {
      setLoading(true);
      const api = axiosRequestInterceptor(true);
      const response = await api.get(`/entities/entitiesData/${status}/${entitiesPerPage}/${currentPage}/${searchKeyword ? searchKeyword : null}`);
      const tempPages = Math.ceil(response.data.totalResults / entitiesPerPage);
      setTotalPages(tempPages);
      setEntityData(response.data.entityData);
      setLoading(false);
    } catch (err) {
      if(err?.response?.data?.error) setErrMsg(err.response.data.error)
      else setErrMsg('Failed to connect with server')
    }
  }

  const [debouncedInput] = useDebounce(searchKeyword, 500);

  const changeKeyword = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1);
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    fetchEntityData()
  }, [status, currentPage, debouncedInput]) //eslint-disable-line

  return (
    <div className='page-with-navbar'>
      <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
        <Profilebar collapsed={collapsed} userType={userType} />
        <div className='active-filter fw-bold'>
          <span onClick={() => setStatus('Active')} className={status === 'Active' ? 'active-entities' : 'inactive-entities'}>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM9.0026 14L16.0737 6.92893L14.6595 5.51472L9.0026 11.1716L6.17421 8.3431L4.75999 9.7574L9.0026 14Z'
                fill={status === 'Active' ? '#208DA9' : '#98A2B3'} />
            </svg>
            &nbsp;Active</span>
          <span onClick={() => setStatus('Inactive')} className={status === 'Active' ? 'inactive-entities' : 'active-entities'}>
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 8.5858L12.8284 5.75736L14.2426 7.17157L11.4142 10L14.2426 12.8284L12.8284 14.2426L10 11.4142L7.17157 14.2426L5.75736 12.8284L8.5858 10L5.75736 7.17157L7.17157 5.75736L10 8.5858Z'
                fill={status === 'Active' ? '#98A2B3' : '#208DA9'} />
            </svg>
            &nbsp;Inactive</span>
        </div>
        <div className='container-fluid scroll-window-be pb-4'>
          <div className='be-modify-field'>
            <span className='title-be'>Business Entities</span>
            <span className='group-ip-be'>
              <span className='searchbar-be'>
                <FontAwesomeIcon className='mx-1' color='gray' icon={faMagnifyingGlass} />
                <input className='input-be-search' onChange={changeKeyword} value={searchKeyword} type='text' placeholder='Search' />
              </span>
              <button onClick={openModal} className='create-entity'>
              <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>
              Create New Entity</button>
            </span>
          </div>
          {loading ? <div className='no-data-banner fw-bold pt-5'>Loading...</div>:
          errMsg ? <div className='no-data-banner fw-bold pt-5'>{errMsg}</div> :
            entityData?.length !== 0 ? entityData?.map((entity) => {
              return <BusinessEntitiesList
                key={entity.id}
                name={entity.name}
                logo={entity.logo}
                account_added={entity.createdAt}
                email={entity.email}
                status={entity.status}
                reference={entity.display_id}
                number_of_clients={entity.clientsCount}
                total_usage={entity.driveUsage}
                spaceAlotted={entity.spaceAlotted}
                folder_name={entity.folder_name}
                number_of_documents={entity.documentsCount}
                abn={entity.abn}
                acn={entity.acn}
                spoc_mobile={entity.spoc_mobile}
                spoc_name={entity.spoc_name}
                spoc_designation={entity.spoc_designation}
                address={entity.address}
                pincode={entity.pincode}
                location={entity.location}
                number_of_users={entity.usersCount}
                website={entity.website} />
            }) : <div className='no-data-banner fw-bold pt-5'>
              There is no {status} Entities
            </div>}
          {totalPages > 1 && <nav className='mt-2'>
            <ul className='pagination'>
              <li className='page-item'>
                <button
                  className={currentPage === 1 ? 'page-link disabled' : 'page-link'}
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 1}>Previous</button></li>
              {pageNumbers.map((pageNumber) => (
                <li key={pageNumber} className='page-item'>
                  <button
                    className={`page-link ${pageNumber === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(pageNumber)}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}
              <li className='page-item'>
                <button
                  className={currentPage === totalPages ? 'page-link disabled' : 'page-link'}
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages}>Next</button></li>
            </ul>
          </nav>}
        </div>
      </div>
      <Backdrop isOpen={modalIsOpen} />
      {modalIsOpen && <CreateEntityModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        fetchEntityData={fetchEntityData} />}
    </div>
  )
}

export default BusinessEntities