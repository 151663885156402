import axios from 'axios';

export const axiosRequestInterceptor = (auth) => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_LINK,
    });

    api.interceptors.request.use(
        (config) => {
                 config.headers["x-api-key"] = "9GRBPQGeSd2qvDjTfpdNH3kq4W7cDTOA5NILacss";
            if (auth) {
                const token = sessionStorage.getItem('SDX_token');
                config.headers.Token = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return api;
};
