import React, { useState } from 'react'
import Profilebar from '../../components/Profilebar';
import Navbar from '../../components/Navbar';
import './editBusinessEntity.css'
import DoughnutChart from '../../components/DoughnutChart';
import { useLocation, useNavigate } from 'react-router-dom';
import UserTable from '../../components/UserTable';
import { useEffect } from 'react';
import Backdrop from '../../components/Backdrop';
import EditLogoModal from '../../components/EditLogoModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronUp, faDatabase, faFile, faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const EditBusinessEntity = () => {

    const selected = 'Business Entities';
    const userType = 'admin';
    const propsData = useLocation();
    const { name, logo, email, account_added, status, reference, number_of_clients, total_usage, spaceAlotted,
        folder_name, number_of_documents, abn, acn, spoc_mobile, spoc_name, spoc_designation, address, pincode, location, number_of_users, website } = propsData.state;
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
    const [newLogo, setNewLogo] = useState(null);
    const [entityDetails, setEntityDetails] = useState(true);
    const [userDetails, setUserDetails] = useState(false);
    const [spaceDetails, setSpaceDetails] = useState(false);
    const [entityData, setEntityData] = useState({
        status, folder_name, abn, acn, spoc_mobile, spoc_name, spoc_designation, address, pincode, website
    });
    const [validMsg, setValidMsg] = useState({ error: true });
    const [storage, setStorage] = useState(spaceAlotted);
    const [editWebsite, setEditWebsite] = useState(false);
    const [userData, setUserData] = useState([]);
    const [errUserData, setErrUserData] = useState('');

    const fetchEntityUsers = async() => {
        try{
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/users/entityUserData/${reference}`);
            setErrUserData('');
            setUserData(response.data)
        }catch(err){
            if(err?.response?.data?.error) setErrUserData(err.response.data.error);
            else setErrUserData('Failed to connect with server')
        }
    }

    const openModal = () => {
        setModalIsOpen(true);
    };
    
      const closeModal = () => {
        setModalIsOpen(false);
    };

    const showEditWebsite = () => {
        setEditWebsite(true);
    }

    const toggleEntityDetails = () => {
        setEntityDetails(prevState => !prevState);
    }

    const toggleUserDetails = () => {
        setUserDetails(prevState => !prevState);
    }

    const toggleSpaceDetails = () => {
        setSpaceDetails(prevState => !prevState);
    }

    const handleDataChange = (e) => {
        const { name, value } = e.target;
        let tempData = null
        if(name === 'spoc_mobile'){
            let tempMob = '';
            if(value[0] === '+'){
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 5 || value.length === 9 ) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...entityData,
                [name]: tempMob
            }
        } else {
            tempData = {
                ...entityData,
                [name]: value
            }
        }
        setEntityData(tempData);
    }

    const handleRangeChange = (e) => {
        const newStorage = e.target.value;
        setStorage(newStorage);
    };

    const cancelEdit = () => {
        navigate(-1);
    }

    const submitEdit = async() => {
        try{
            setValidMsg({error: false});
            const api = axiosRequestInterceptor(true);
            await api.put(`/entities/edit/${reference}`, {...entityData, spaceAlotted: storage, email })
            navigate(-1);
        }catch(err){
            if(err?.response?.data?.error) setValidMsg({error: true, server: err.response.data.error});
            else setValidMsg({error: true, server: 'Failed to connect with server'});
        }
    }

    let storageSize = '';
    if (total_usage >= 1) {
        storageSize = total_usage.toFixed(2) + ' GB';
    } else if (total_usage * 1024 >= 1) {
        storageSize = (total_usage * 1024).toFixed(2) + ' MB';
    } else {
        storageSize = (total_usage * 1024 * 1024).toFixed(2) + ' KB';
    }

    useEffect(()=> {
        fetchEntityUsers();
    },[]) //eslint-disable-line

    useEffect(() => {
        const websitePattern = /\w{3,}\.\w{2,}/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
          ];
        let error = false;
        let website= '';
        let folder_name = '';
        let abn = '';
        let acn = '';
        let spoc_name = '';
        let spoc_designation = '';
        let spoc_mobile = '';
        let address = '';
        let pincode = '';

        if (!entityData.website) {
            error = true;
            website = 'Link Is Required'
        } else if (!websitePattern.test(entityData.website)) {
            error = true;
            website = 'Invalid Website'
        }

        if (!entityData.folder_name) {
            error = true;
            folder_name = 'Folder Name Is Required'
        } else if (entityData.folder_name.length < 3) {
            error = true;
            folder_name = 'Folder Name Should Contain Atleast 3 Characters'
        }

        if (!entityData.abn) {
            error = true;
            abn = 'ABN Is Required'
        } else if (!/^[0-9]*$/.test(entityData.abn)) {
            error = true;
            abn = 'ABN Should Contain Only Numbers'
        } else if (entityData.abn.length !== 11) {
            error = true;
            abn = 'ABN Should Contain 11 Digits'
        }

        if (!entityData.acn) {
            error = true;
            acn = 'ACN Is Required'
        } else if (!/^[0-9]*$/.test(entityData.acn)) {
            error = true;
            acn = 'ACN Should Contain Only Numbers';
        } else if (entityData.acn.length !== 9) {
            error = true;
            acn = 'ACN Should Contain 9 Digits';
        }

        if (!entityData.spoc_name) {
            error = true;
            spoc_name = 'Primary Contact Is Required'
        } else if (entityData.spoc_name.length < 3) {
            error = true;
            spoc_name = 'Primary Contact Should Contain More Than 2 Letters';
        }

        if (!entityData.spoc_designation) {
            error = true;
            spoc_designation = 'Primary Contact Title Is Required'
        } else if (entityData.spoc_name.length < 3) {
            error = true;
            spoc_designation = 'Primary Contact Title Should Contain More Than 1 Letter';
        }

        if (!entityData.spoc_mobile) {
            error = true;
            spoc_mobile = 'Mobile is required'
        } else if (!mobilePatterns.some(pattern => pattern.test(entityData.spoc_mobile))) {
            error = true;
            spoc_mobile = 'Mobile is invalid';
        }

        if (!entityData.address) {
            error = true;
            address = 'Address Is Required'
        } else if (entityData.address.length < 3) {
            error = true;
            address = 'Address Should Contain More Than 2 Letters';
        }

        if (!entityData.pincode) {
            error = true;
            pincode = 'Post Code Is Required'
        } else if (!/^[0-9]*$/.test(entityData.pincode)) {
            error = true;
            pincode = 'Post Code Should Contain Only Numbers';
        } else if (entityData.pincode.length !== 4) {
            error = true;
            pincode = 'Post Code Should Contain 4 Digits';
        }

        setValidMsg({ error, website, folder_name, abn, acn, spoc_name, spoc_designation, spoc_mobile, address, pincode })

    }, [entityData])

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar collapsed={collapsed} userType={userType} />
                <div className='container-fluid scroll-window'>
                    <div className='title-edit-entity'>
                        <div onClick={cancelEdit} className='hand-symbol'>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        &nbsp;&nbsp;Edit Details
                    </div>
                    <div className='card mx-2 mb-5'>
                        <div className='main-section mt-2 mx-3 pb-2'>
                            <div className='logo-holder'>
                                <img className='edit-entity-logo' src={newLogo ? newLogo : logo ? logo : '/assets/def-logo.png'} alt='logo' />
                                <div className='edit-logo' onClick={openModal}>
                                    <img src='/assets/edit-logo.png' alt='edit' width='30px' />
                                </div>
                            </div>
                            <div className='ms-2 ms-md-4'>
                                <div className='d-flex fw-bold'>{name}</div>
                                <div className='accnt-added-date'>Account Added On : {account_added}</div>
                                {editWebsite ? <>
                                    <div className='input-field px-2 mt-2 mb-2'>
                                        <input
                                            className='input-edit-entity'
                                            type='text'
                                            name='website'
                                            value={entityData.website}
                                            onChange={handleDataChange} />
                                        <label className='edit-details-label'> Website</label>
                                    </div>
                                    <div className='error-message mt-1'>{validMsg?.website}</div>
                                    </> :
                                    <div className='accnt-added-date'>Website: {entityData.website}
                                        &nbsp;<img className='showEdit' onClick={showEditWebsite} src='/assets/edit-logo.png' alt='edit' /></div>}
                                <div className='entity-type-eb'>
                                    Email: {email}
                                </div>
                                <div className='entity-type-eb'>
                                    <select name='status' value={entityData.status} onChange={handleDataChange} className='hand-symbol'>
                                        <option value='Paid' >Paid</option>
                                        <option value='Trial Period'>Trial Period</option>
                                        <option value='Inactive'>Inactive</option>
                                    </select>
                                    &nbsp;|&nbsp; {reference}</div>
                            </div>
                            <div className='button-container ms-md-auto'>
                                <button onClick={cancelEdit} className='me-3 ps-3 pe-3 btn-cancel'>Cancel</button>
                                <button disabled={validMsg.error} onClick={submitEdit} className={validMsg.error ? 'ps-3 pe-3 btn-submit-disabled' : 'ps-3 pe-3 btn-submit'}>Submit</button>
                            </div>
                            <div className='error-message'>{validMsg.server}</div>
                        </div>
                        <div className='acc-section mx-2 mb-3'>
                            <div onClick={toggleEntityDetails} className='acc-header fw-bold ms-2'>
                                <span>Entity Details</span>
                                <FontAwesomeIcon className='ms-2' icon={entityDetails ? faChevronUp : faChevronDown}/>
                            </div>
                            {entityDetails && <div className='p-3'>
                                <div className='info-section'>
                                    <div className='row gap-md-4'>
                                        <span className='col-6 col-md-2'>
                                            <FontAwesomeIcon icon={faUser} size='lg' color='#208DA9'/>
                                            <span className='ms-2'>
                                                Number of Clients
                                                <p className='ms-4 fw-bold'>{number_of_clients}</p>
                                            </span>
                                        </span>
                                        <span className='col-6 col-md-2'>
                                            <FontAwesomeIcon icon={faDatabase} size='lg' color='#208DA9'/>
                                            <span className='ms-2'>
                                                Total Usage
                                                <p className='ms-4 fw-bold'>{storageSize}</p>
                                            </span>
                                        </span>
                                        <span className='col-6 col-md-2'>
                                            <FontAwesomeIcon icon={faFile} size='lg' color='#208DA9' />
                                            <span className='ms-2'>
                                                Number of Documents
                                                <p className='ms-4 fw-bold'>{number_of_documents}</p>
                                            </span>
                                        </span>
                                        <span className='col-6 col-md-2'>
                                            <FontAwesomeIcon icon={faLocationDot} size='lg' color='#208DA9'/>
                                            <span className='ms-2'>
                                                Location
                                                <p className='ms-4 fw-bold'>{location}</p>
                                            </span>
                                        </span>
                                        <span className='col-6 col-md-2'>
                                            <FontAwesomeIcon icon={faUser} size='lg' color='#208DA9'/>
                                            <span className='ms-2'>
                                                Number of Users
                                                <p className='ms-4 fw-bold'>{number_of_users}</p>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className='edit-section mt-3'>
                                    <div className='row px-2 gap-md-5'>
                                        <div className='col-md-3 mb-3'>
                                            <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='folder_name'
                                                value={entityData.folder_name}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Folder Name</label>
                                            </div>
                                            <div className='error-message mt-1'>{validMsg?.folder_name}</div>
                                        </div>
                                        <div className='col-md-3 ms-md-5 mb-3'>
                                            <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='abn'
                                                value={entityData.abn}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> ABN</label>
                                            </div>
                                        {!validMsg?.folder_name && <div className='error-message mt-1'>{validMsg?.abn}</div>}
                                        </div>
                                        <div className='col-md-3 ms-md-5 mb-3'>
                                            <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='acn'
                                                value={entityData.acn}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> ACN</label>
                                            </div>
                                            {!validMsg?.abn && <div className='error-message mt-1'>{validMsg?.acn}</div>}
                                        </div>
                                    </div>
                                    <div className='row px-2 gap-md-5'>
                                        <div className='col-md-3 mb-3'>
                                        <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='spoc_name'
                                                value={entityData.spoc_name}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Primary Contact</label>
                                            </div>
                                            {!validMsg?.acn && <div className='error-message mt-1'>{validMsg?.spoc_name}</div>}
                                        </div>
                                        <div className='col-md-3 ms-md-5 mb-3'>
                                        <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='spoc_designation'
                                                value={entityData.spoc_designation}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Primary Contact Title</label>
                                            </div>
                                            {!validMsg?.spoc_name && <div className='error-message mt-1'>{validMsg?.spoc_designation}</div>}
                                        </div>
                                        <div className='col-md-3 ms-md-5 mb-3'>
                                        <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='spoc_mobile'
                                                value={entityData.spoc_mobile}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Mobile</label>
                                            </div>
                                            {!validMsg?.spoc_designation && <div className='error-message mt-1'>{validMsg?.spoc_mobile}</div>}
                                        </div>
                                    </div>
                                    <div className='row px-2 gap-md-5'>
                                        <div className='col-md-3 mb-3'>
                                        <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='address'
                                                value={entityData.address}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Address</label>
                                            </div>
                                            {!validMsg?.spoc_mobile && <div className='error-message mt-1'>{validMsg?.address}</div>}
                                        </div>
                                        <div className='col-md-3 ms-md-5'>
                                        <div className='input-field'>
                                            <input
                                                className='input-edit-entity'
                                                type='text'
                                                name='pincode'
                                                value={entityData.pincode}
                                                onChange={handleDataChange} />
                                            <label className='edit-details-label'> Post Code</label>
                                            </div>
                                            {!validMsg?.address && <div className='error-message mt-1'>{validMsg?.pincode}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div onClick={toggleUserDetails} className='acc-header fw-bold ms-2'>
                                <span>Entity User Details</span>
                                <FontAwesomeIcon className='ms-2' icon={userDetails ? faChevronUp : faChevronDown}/>
                            </div>
                            {userDetails && <div className='p-3'>
                                <div className='table-holder'>
                                    {errUserData ? <div>errUserData</div> : <UserTable users={userData} editEntity={true} />}
                                </div>
                            </div>}
                            <div onClick={toggleSpaceDetails} className='acc-header fw-bold ms-2'>
                                <span>Space Management</span>
                                <FontAwesomeIcon className='ms-2' icon={spaceDetails ? faChevronUp : faChevronDown}/>
                            </div>
                            {spaceDetails && <div className='p-3'>
                                <div className='row'>
                                    <div className='col-12 col-md-5 my-2 py-4 chart-section'>
                                        <DoughnutChart
                                        consumedSize={total_usage}
                                        alottedSize={storage}/>
                                        <div className='tdu-title'>
                                            <div className='ms-3'>Total</div>
                                            <div className='ms-1'>Drive Size</div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 my-2 ms-md-5 storage-section'>
                                        <div className='mt-2 d-flex'>
                                            <span className='fw-bold'>Customize Limit</span>
                                            <span className='badge text-bg-primary ms-auto'>{storage}</span>
                                        </div>
                                        <p>Use the slider to customize the total size</p>
                                        <input
                                            type='range'
                                            value={storage}
                                            min={1.00}
                                            max={50.00}
                                            onChange={handleRangeChange}
                                            title={storage}
                                            className='data-slide-range mt-2'>
                                        </input>
                                        <div className='scale'>
                                            <p>0 GB</p>
                                            <p>10 GB</p>
                                            <p>20 GB</p>
                                            <p>30 GB</p>
                                            <p>40 GB</p>
                                            <p>50 GB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop isOpen={modalIsOpen} />
            {modalIsOpen && <EditLogoModal
            isOpen={modalIsOpen}
            closeModal={closeModal}
            entityId={reference}
            setNewLogo={setNewLogo}/>}
        </div>
    )
}

export default EditBusinessEntity