import { faCalendar, faCircle, faHandshake, faThumbsUp, faUser } from '@fortawesome/free-regular-svg-icons';
import { faAt, faBed, faBuildingColumns, faCheck, faCircleMinus, faDollarSign, faGlobe, faHashtag, faLocationDot, faMapLocationDot, faPassport, faPenClip, faPenToSquare, faPhone, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import axios from 'axios';

const Purchaser = () => {

    const storedSubmit = sessionStorage.getItem('submit');
    const [submitted, setSubmitted] = useState(storedSubmit ? storedSubmit : false);

    const storedStep = sessionStorage.getItem('step');
    const [step, setStep] = useState(storedStep ? Number(storedStep) : 0);

    const storedPurchaser = sessionStorage.getItem('purchaser');
    const [purchaser, setPurchaser] = useState(storedPurchaser ? Number(storedPurchaser) : 0);
    const [validate, setValidate] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const storedPurchaserState = JSON.parse(sessionStorage.getItem('purchaserData'));
    const storedSelAddr = JSON.parse(sessionStorage.getItem('selAddr'));
    const storedStepTwoData = JSON.parse(sessionStorage.getItem('stepTwoData'));
    const storedStepThreeData = JSON.parse(sessionStorage.getItem('stepThreeData'));
    const storedStepFourData = JSON.parse(sessionStorage.getItem('stepFourData'));
    const storedStepFiveData = JSON.parse(sessionStorage.getItem('stepFiveData'));

    const purchaserState = {
        surname: '', given_name: '', middle_name: '', diff_name_used: 'No', surname_diff: '', given_name_diff: '', middle_name_diff: '', gender: '',
        address: '', phone: '', mobile: '', email: '', dob: '', aus_res: '', citizen: '', visa_name: '', visa_dob: '', passport_no: '', not_listed_partner: '',
        surname_partner: '', given_name_partner: '', middle_name_partner: '', partner_dob: ''
    }
    const stepTwoState = { prop_address: '', agent_name: '', off_plan_purchase: '', contract_purchaser: '' }
    const stepThreeState = { nominated_purchaser: '', nominated_purchaser_relationship: '', joint_purchaser: '', joint_purchaser_relationship: '', right_in_the_title: '' }
    const stepFourState = {
        purpose: '', owner_corp_applicable: '', property_type: '', no_bedrooms: '', material_of_building: '', less_than_seven_years: '',
        building_warranty_insurance_applicable: '', builder_registered: '', building_permit: '', intend_to_build_house: '', signed_building_contract: '', claim_first_home_owner: ''
    }

    const [purchaserData, setPurchaserData] = useState(storedPurchaserState ? storedPurchaserState : [purchaserState]);
    const [validPurchaser, setValidPurchaser] = useState(purchaserState);
    const [addrResults, setAddrResults] = useState([]);
    const [autoAddress, setAutoAddress] = useState(false);
    const [selAddr, setSelAddr] = useState(storedSelAddr ? storedSelAddr : { purchaser1: null, purchaser2: null, purchaser3: null, purchaser4: null, purchaser5: null, property: null });
    const storedSameAddr = sessionStorage.getItem('sameAddr');
    const [sameAddr, setSameAddr] = useState(storedSameAddr ? storedSameAddr : Array(5).fill(false));
    const [countries, setCountries] = useState([]);

    const [stepTwoData, setStepTwoData] = useState(storedStepTwoData ? storedStepTwoData : stepTwoState);
    const [validStepTwo, setValidStepTwo] = useState(stepTwoState);

    const [stepThreeData, setStepThreeData] = useState(storedStepThreeData ? storedStepThreeData : stepThreeState);
    const [validStepThree, setValidStepThree] = useState(stepThreeState);

    const [stepFourData, setStepFourData] = useState(storedStepFourData ? storedStepFourData : stepFourState);
    const [validStepFour, setValidStepFour] = useState(stepFourState);

    const stepFiveState = {
        owner_house_built: '', initial_deposit_made: '', deposit_amount_paid: '', cash_purchaser: '', bank: '', approval_of_loan: '',
        apply_title_insurance: '', signature: '', first_home: '', eligible_for_health_card: '', applying_for_concession: '', occupy_as_ppr: '', date_of_occupation: '',
        present_fhog: '', prev_owned_prop: '', occupied_res_prop_cont: '', prev_fhog: '', properties_sold_over: '', tax_file_number: '', residency_status_changed: '', lodged_tax_return: '',
        holding_property_behalf: ''
    }

    const todayDate = moment().format('DD-MM-YYYY');

    const [stepFiveData, setStepFiveData] = useState(storedStepFiveData ? storedStepFiveData : stepFiveState)
    const [validStepFive, setValidStepFive] = useState(stepFiveState);

    const handlePurchaserChange = (e) => {
        const restData = purchaserData[purchaser];
        const { name, value } = e.target;
        let tempData = null;
        if (name === 'mobile') {
            let tempMob = '';
            if (value[0] === '+') {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if (value[value.length - 1] === ' ') {
                    tempMob = value.slice(0, -1);
                } else if (value.length === 5 || value.length === 9) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...restData,
                [name]: tempMob
            }
        } else if (name === 'aus_res' && value === 'Australian citizen') {
            tempData = {
                ...restData,
                [name]: value, citizen: 'Australia'
            }
        } else if (name === 'citizen') {
            tempData = {
                ...restData,
                [name]: value, visa_name: '', visa_dob: '', passport_no: ''
            }
        } else if (name === 'not_listed_partner') {
            tempData = {
                ...restData,
                [name]: value,
                surname_partner: '', given_name_partner: '', middle_name_partner: '', partner_dob: ''
            }
        } else {
            tempData = {
                ...restData,
                [name]: value
            }
        }
        const tempState = [...purchaserData];
        tempState[purchaser] = tempData;
        setPurchaserData(tempState);
    }

    const handleStepTwoDataChange = (e) => {
        const { name, value } = e.target;
        const tempData = {
            ...stepTwoData,
            [name]: value
        }
        setStepTwoData(tempData);
    }

    const handleStepThreeDataChange = (e) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'nominated_purchaser') {
            tempData = {
                ...stepThreeData,
                [name]: value,
                nominated_purchaser_relationship: ''
            }
        } else if (name === 'joint_purchaser') {
            tempData = {
                ...stepThreeData,
                [name]: value,
                joint_purchaser_relationship: ''
            }
        } else {
            tempData = {
                ...stepThreeData,
                [name]: value
            }
        }
        setStepThreeData(tempData);
    }

    const handleStepFourDataChange = (e) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'property_type') {
            tempData = {
                ...stepFourData,
                [name]: value,
                no_bedrooms: '', material_of_building: '', less_than_seven_years: '', building_warranty_insurance_applicable: '', builder_registered: '',
                building_permit: '', intend_to_build_house: '', signed_building_contract: '', claim_first_home_owner: ''
            }
        } else {
            tempData = {
                ...stepFourData,
                [name]: value
            }
        }
        setStepFourData(tempData);
    }

    const handleStepFiveDataChange = (e) => {
        const { name, value } = e.target;
        let tempData = '';
        if (name === 'initial_deposit_made') {
            tempData = {
                ...stepFiveData,
                [name]: value,
                deposit_amount_paid: ''
            }
        } else if (name === 'occupy_as_ppr') {
            tempData = {
                ...stepFiveData,
                [name]: value,
                date_of_occupation: ''
            }
        } else if (name === 'cash_purchaser') {
            tempData = {
                ...stepFiveData,
                [name]: value,
                bank: '', approval_of_loan: ''
            }
        } else if (name === 'properties_sold_over') {
            tempData = {
                ...stepFiveData,
                [name]: value,
                tax_file_number: '', residency_status_changed: '', lodged_tax_return: '', holding_property_behalf: ''
            }
        } else {
            tempData = {
                ...stepFiveData,
                [name]: value
            }
        }
        setStepFiveData(tempData);
    }

    const fetchAddress = async (word) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ADDRESS_AUTO}text=${word}&filter=countrycode:au&format=json&apiKey=${process.env.REACT_APP_ADDRESS_API_KEY}`)
            if (response.data?.results?.length > 0) {
                setAddrResults(response.data?.results);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleAddressChange = (e, type) => {
        const tempData = { ...selAddr }
        if (type === 'purchaser') {
            handlePurchaserChange(e);
            tempData[`purchaser${purchaser + 1}`] = null
        } else if (type === 'property') {
            handleStepTwoDataChange(e);
            tempData.property = null
        }
        setSelAddr(tempData)
        if (e.target.value.length > 2) {
            fetchAddress(e.target.value);
        } else {
            setAddrResults([]);
        }
    }

    const addAddress = (address, type) => {
        let tempAddr = null;
        if (type === 'purchaser') {
            const tempData = purchaserData[purchaser];
            tempData.address = `${address.address_line1}, ${address.address_line2}`
            const tempState = [...purchaserData];
            tempState[purchaser] = tempData;
            setPurchaserData(tempState);
            tempAddr = { ...selAddr };
            tempAddr[`purchaser${purchaser + 1}`] = address;
        } else if (type === 'property') {
            const tempData = stepTwoData;
            tempData.prop_address = `${address.address_line1}, ${address.address_line2}`
            setStepTwoData(tempData);
            tempAddr = { ...selAddr };
            tempAddr.property = address;
        }
        setSelAddr(tempAddr);
        setAutoAddress(false);
    }

    const handleSameAddr = () => {
        const tempData = [...purchaserData];
        const tempSelAddr = { ...selAddr };
        const tempSameAddr = { ...sameAddr }
        if (sameAddr[purchaser]) {
            tempData[purchaser].address = '';
            setPurchaserData(tempData);
            tempSelAddr[`purchaser${purchaser - 1}`] = null;
            tempSameAddr[purchaser] = false
        } else {
            tempData[purchaser].address = tempData[0].address;
            setPurchaserData(tempData);
            tempSelAddr[`purchaser${purchaser - 1}`] = tempSelAddr.purchaser1;
            tempSameAddr[purchaser] = true
        }
        sessionStorage.setItem('sameAddr', tempSameAddr);
        setSelAddr(tempSelAddr);
        setSameAddr(tempSameAddr);
    }

    const addPurchaser = () => {
        if (purchaserData.length !== 5) {
            if (validPurchaser.error) {
                setValidate(true);
            } else {
                const tempValue = purchaserData.length;
                const tempState = [...purchaserData, purchaserState];
                setPurchaserData(tempState);
                sessionStorage.setItem('purchaser', tempValue);
                setPurchaser(tempValue);
                setValidate(false);
            }
        }
    }

    const removePurchaser = () => {
        if (purchaserData.length > 1) {
            const tempValue = purchaserData.length;
            const tempState = purchaserData.filter((item, index) => index !== purchaser);
            setPurchaserData(tempState);
            sessionStorage.setItem('purchaser', tempValue - 2);
            setPurchaser(tempValue - 2);
            setValidate(false);
        }
    }

    const validatePurchaser = () => {
        if (validPurchaser.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(1);
            sessionStorage.setItem('step', 1);
        }
    }

    const goToStepOne = () => {
        setStep(0);
        setPurchaser(0);
        sessionStorage.setItem('step', 0);
        sessionStorage.setItem('purchaser', 0);
    }

    const validateStepTwo = () => {
        if (validStepTwo.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(2);
            sessionStorage.setItem('step', 2);
        }
    }

    const goToStep = (num) => {
        setStep(num);
        sessionStorage.setItem('step', num);
    }

    const validateStepThree = () => {
        if (validStepThree.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(3);
            sessionStorage.setItem('step', 3);
        }
    }

    const validateStepFour = () => {
        if (validStepFour.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(4);
            sessionStorage.setItem('step', 4);
        }
    }

    const submitData = async () => {
        try {
            setSubmitting(true);
            const convertedData = purchaserData.map((item, index) => {
                return {
                    ...item,
                    purchaserIndex: index + 1
                };
            });
            const payload = {
                purchaserDetails: convertedData,
                ...stepTwoData,
                ...stepThreeData,
                ...stepFourData,
                ...stepFiveData,
                filled_date: todayDate
            }
            const api = axiosRequestInterceptor(true);
            await api.post('/prospects/purchaserForm', payload);
            setValidate(false);
            setSubmitted(true);
            setSubmitting(false);
            sessionStorage.setItem('submit', true);
        } catch (err) {
            setSubmitting(false);
            console.error(err);
            if (err?.response?.data?.error) {
                setErrMsg(err.response.data.error);
            } else {
                setErrMsg('Failed to connect with server');
            }
        }
    }

    const validateStepFive = () => {
        if (validStepFive.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(5);
            sessionStorage.setItem('step', 5);
        }
    }

    const editPurchaser = (pur) => {
        if (validPurchaser.error) {
            setValidate(true);
        } else {
            setValidate(false);
            setStep(0);
            setPurchaser(pur);
            sessionStorage.setItem('purchaser', pur);
            sessionStorage.setItem('step', 0);
        }
    }

    useEffect(() => {
        sessionStorage.setItem('purchaserData', JSON.stringify(purchaserData));
        sessionStorage.setItem('selAddr', JSON.stringify(selAddr));
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
        ];
        const phonePattern = /^[+0-9][0-9]{7,10}$/;
        let error = false;
        let surname = '';
        let given_name = '';
        let surname_diff = '';
        let given_name_diff = '';
        let gender = '';
        let address = '';
        let phone = '';
        let mobile = '';
        let email = '';
        let dob = '';
        let aus_res = '';
        let citizen = '';
        let not_listed_partner = '';
        let surname_partner = '';
        let given_name_partner = '';
        let partner_dob = '';

        if (!purchaserData[purchaser].surname) {
            error = true;
            surname = 'Surname is required';
        } else if (purchaserData[purchaser].surname.length < 2) {
            error = true;
            surname = 'Surname should contain more than 1 Character';
        }

        if (!purchaserData[purchaser].given_name) {
            error = true;
            given_name = 'Given name is required';
        } else if (purchaserData[purchaser].given_name.length < 3) {
            error = true;
            given_name = 'Given name should contain more than 2 Characters';
        }

        if (purchaserData[purchaser].diff_name_used === 'Yes' && !purchaserData[purchaser].surname_diff) {
            error = true;
            surname_diff = 'Surname is required';
        } else if (purchaserData[purchaser].diff_name_used === 'Yes' && purchaserData[purchaser].surname_diff.length < 2) {
            error = true;
            surname_diff = 'Surname should contain more than 1 Character';
        }

        if (purchaserData[purchaser].diff_name_used === 'Yes' && !purchaserData[purchaser].given_name_diff) {
            error = true;
            given_name_diff = 'Given name is required';
        } else if (purchaserData[purchaser].diff_name_used === 'Yes' && purchaserData[purchaser].given_name_diff.length < 2) {
            error = true;
            given_name_diff = 'Given name should contain more than 1 Character';
        }

        if (!purchaserData[purchaser].gender) {
            error = true;
            gender = 'Gender is required';
        }

        if (!purchaserData[purchaser].address) {
            error = true;
            address = 'Address is required';
        } else if (purchaserData[purchaser].address.length < 3) {
            error = true;
            address = 'Invalid address';
        }

        if (purchaserData[purchaser].phone && !phonePattern.test(purchaserData[purchaser].phone)) {
            error = true;
            phone = 'Phone number is invalid'
        }

        if (!purchaserData[purchaser].mobile) {
            error = true;
            mobile = 'Mobile number is required'
        } else if (!mobilePatterns.some(pattern => pattern.test(purchaserData[purchaser].mobile))) {
            error = true;
            mobile = 'Mobile number is invalid'
        }

        if (!purchaserData[purchaser].email) {
            error = true;
            email = 'Email address is required'
        } else if (!emailPattern.test(purchaserData[purchaser].email)) {
            error = true;
            email = 'Email address is invalid'
        }

        if (!purchaserData[purchaser].dob) {
            error = true;
            dob = 'Date of birth is required';
        }

        if (!purchaserData[purchaser].aus_res) {
            error = true;
            aus_res = 'Option is required';
        }

        if (!purchaserData[purchaser].citizen) {
            error = true;
            citizen = 'Country of Citizenship is required';
        } else if (!countries.includes(purchaserData[purchaser].citizen)) {
            error = true;
            citizen = 'Invalid country name (select from the list)';
        }

        if (!purchaserData[purchaser].not_listed_partner) {
            error = true;
            not_listed_partner = 'Option is required';
        }

        if (purchaserData[purchaser].not_listed_partner === 'Yes' && !purchaserData[purchaser].surname_partner) {
            error = true;
            surname_partner = 'Surname is required';
        } else if (purchaserData[purchaser].not_listed_partner === 'Yes' && purchaserData[purchaser].surname_partner.length < 2) {
            error = true;
            surname_partner = 'Surname should contain more than 1 Character';
        }

        if (purchaserData[purchaser].not_listed_partner === 'Yes' && !purchaserData[purchaser].given_name_partner) {
            error = true;
            given_name_partner = 'Given name is required';
        } else if (purchaserData[purchaser].not_listed_partner === 'Yes' && purchaserData[purchaser].given_name_partner.length < 3) {
            error = true;
            given_name_partner = 'Given name should contain more than 2 Characters';
        }

        if (purchaserData[purchaser].not_listed_partner === 'Yes' && !purchaserData[purchaser].partner_dob) {
            error = true;
            partner_dob = 'Date of birth is required';
        }

        setValidPurchaser({
            error, surname, given_name, surname_diff, given_name_diff, gender, address, phone, mobile, email, dob, aus_res,
            citizen, not_listed_partner, surname_partner, given_name_partner, partner_dob
        })

    }, [purchaserData, selAddr, countries, purchaser]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepTwoData', JSON.stringify(stepTwoData));
        sessionStorage.setItem('selAddr', JSON.stringify(selAddr));
        let error = false;
        let prop_address = ''
        let agent_name = ''
        let off_plan_purchase = ''
        let contract_purchaser = ''

        if (!stepTwoData.prop_address) {
            error = true;
            prop_address = 'Address is required';
        } else if (stepTwoData.prop_address.length < 3) {
            error = true;
            prop_address = 'Invalid address';
        }

        if (stepTwoData.agent_name && stepTwoData.agent_name.length < 3) {
            error = true;
            agent_name = 'Agent name should contain more than 2 Characters';
        }

        if (!stepTwoData.off_plan_purchase) {
            error = true;
            off_plan_purchase = 'Option is required';
        }

        if (!stepTwoData.contract_purchaser) {
            error = true;
            contract_purchaser = 'Option is required';
        }

        setValidStepTwo({ error, prop_address, agent_name, off_plan_purchase, contract_purchaser })

    }, [stepTwoData, selAddr]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepThreeData', JSON.stringify(stepThreeData));
        let error = false;
        let nominated_purchaser = '';
        let nominated_purchaser_relationship = '';
        let joint_purchaser = '';
        let joint_purchaser_relationship = '';
        let right_in_the_title = '';

        if (!stepThreeData.nominated_purchaser) {
            error = true;
            nominated_purchaser = 'Option is required';
        }

        if (stepThreeData.nominated_purchaser === 'Yes' && !stepThreeData.nominated_purchaser_relationship) {
            error = true;
            nominated_purchaser_relationship = 'Relationship is required';
        } else if (stepThreeData.nominated_purchaser === 'Yes' && stepThreeData.nominated_purchaser_relationship.length < 3) {
            error = true;
            nominated_purchaser_relationship = 'Relationship should contain more than 2 Characters';
        }

        if (!stepThreeData.joint_purchaser) {
            error = true;
            joint_purchaser = 'Option is required';
        }


        if (stepThreeData.joint_purchaser === 'Yes' && !stepThreeData.joint_purchaser_relationship) {
            error = true;
            joint_purchaser_relationship = 'Relationship is required';
        } else if (stepThreeData.joint_purchaser === 'Yes' && stepThreeData.joint_purchaser_relationship.length < 3) {
            error = true;
            joint_purchaser_relationship = 'Relationship should contain more than 2 Characters';
        }


        if (!stepThreeData.right_in_the_title) {
            error = true;
            right_in_the_title = 'Right in the title is required';
        } else if (stepThreeData.right_in_the_title.length < 3) {
            error = true;
            right_in_the_title = 'Right in the title should contain more than 2 Characters';
        }

        setValidStepThree({ error, nominated_purchaser, nominated_purchaser_relationship, joint_purchaser, joint_purchaser_relationship, right_in_the_title })

    }, [stepThreeData]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepFourData', JSON.stringify(stepFourData));
        let error = false;
        let purpose = '';
        let owner_corp_applicable = '';
        let property_type = '';
        let no_bedrooms = '';
        let material_of_building = '';
        let less_than_seven_years = '';
        let building_warranty_insurance_applicable = '';
        let builder_registered = '';
        let building_permit = '';
        let intend_to_build_house = '';
        let signed_building_contract = '';
        let claim_first_home_owner = '';

        if (!stepFourData.purpose) {
            error = true;
            purpose = 'Purpose is required';
        }

        if (!stepFourData.owner_corp_applicable) {
            error = true;
            owner_corp_applicable = 'Option is required';
        }

        if (!stepFourData.property_type) {
            error = true;
            property_type = 'Property Type is required';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.no_bedrooms) {
            error = true;
            no_bedrooms = 'Number of bedrooms is required';
        } else if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && stepFourData.no_bedrooms.length < 0) {
            error = true;
            no_bedrooms = 'Number of bedrooms must be positive integer';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.material_of_building) {
            error = true;
            material_of_building = 'Material is required';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.less_than_seven_years) {
            error = true;
            less_than_seven_years = 'Option is required';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.building_warranty_insurance_applicable) {
            error = true;
            building_warranty_insurance_applicable = 'Option is required';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.builder_registered) {
            error = true;
            builder_registered = 'Option is required';
        }

        if ((stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && !stepFourData.building_permit) {
            error = true;
            building_permit = 'Option is required';
        }

        if (stepFourData.property_type === 'Vacant Land' && !stepFourData.intend_to_build_house) {
            error = true;
            intend_to_build_house = 'Option is required';
        }

        if (stepFourData.property_type === 'Vacant Land' && !stepFourData.signed_building_contract) {
            error = true;
            signed_building_contract = 'Option is required';
        }

        if (stepFourData.property_type === 'Vacant Land' && !stepFourData.claim_first_home_owner) {
            error = true;
            claim_first_home_owner = 'Option is required';
        }

        setValidStepFour({
            error, purpose, owner_corp_applicable, property_type, no_bedrooms, material_of_building, less_than_seven_years,
            building_warranty_insurance_applicable, builder_registered, building_permit, intend_to_build_house, signed_building_contract, claim_first_home_owner
        })

    }, [stepFourData]) //eslint-disable-line

    useEffect(() => {
        sessionStorage.setItem('stepFiveData', JSON.stringify(stepFiveData));
        let error = false;
        let owner_house_built = '';
        let initial_deposit_made = '';
        let deposit_amount_paid = '';
        let first_home = '';
        let eligible_for_health_card = '';
        let applying_for_concession = '';
        let occupy_as_ppr = '';
        let date_of_occupation = '';
        let present_fhog = '';
        let prev_owned_prop = '';
        let occupied_res_prop_cont = '';
        let prev_fhog = '';
        let cash_purchaser = '';
        let bank = '';
        let approval_of_loan = '';
        let apply_title_insurance = '';
        let properties_sold_over = '';
        let tax_file_number = '';
        let residency_status_changed = '';
        let lodged_tax_return = '';
        let holding_property_behalf = '';
        let signature = '';

        if (!stepFiveData.owner_house_built) {
            error = true;
            owner_house_built = 'Option is required';
        }

        if (!stepFiveData.initial_deposit_made) {
            error = true;
            initial_deposit_made = 'Option is required';
        }

        if (stepFiveData.initial_deposit_made === 'Yes' && !stepFiveData.deposit_amount_paid) {
            error = true;
            deposit_amount_paid = 'Amount is required';
        } else if (stepFiveData.initial_deposit_made === 'Yes' && stepFiveData.deposit_amount_paid < 0) {
            error = true;
            deposit_amount_paid = 'Amount must be positive number';
        }

        if (!stepFiveData.first_home) {
            error = true;
            first_home = 'Option is required';
        }

        if (!stepFiveData.eligible_for_health_card) {
            error = true;
            eligible_for_health_card = 'Option is required';
        }

        if (!stepFiveData.applying_for_concession) {
            error = true;
            applying_for_concession = 'Option is required';
        }

        if (!stepFiveData.occupy_as_ppr) {
            error = true;
            occupy_as_ppr = 'Option is required';
        }

        if (stepFiveData.occupy_as_ppr === 'Yes' && !stepFiveData.date_of_occupation) {
            error = true;
            date_of_occupation = 'Date of occupation is required';
        }

        if (!stepFiveData.present_fhog) {
            error = true;
            present_fhog = 'Option is required';
        }

        if (!stepFiveData.prev_owned_prop) {
            error = true;
            prev_owned_prop = 'Option is required';
        }

        if (!stepFiveData.occupied_res_prop_cont) {
            error = true;
            occupied_res_prop_cont = 'Option is required';
        }

        if (!stepFiveData.prev_fhog) {
            error = true;
            prev_fhog = 'Option is required';
        }

        if (!stepFiveData.cash_purchaser) {
            error = true;
            cash_purchaser = 'Option is required';
        }

        if (stepFiveData.cash_purchaser === 'No' && stepFiveData.bank && stepFiveData.bank.length < 3) {
            error = true;
            bank = 'Lender or Bank name must contain atleast 3 characters';
        }

        if (stepFiveData.cash_purchaser === 'No' && !stepFiveData.approval_of_loan) {
            error = true;
            approval_of_loan = 'Option is required';
        }

        if (!stepFiveData.apply_title_insurance) {
            error = true;
            apply_title_insurance = 'Option is required';
        }

        if (!stepFiveData.properties_sold_over) {
            error = true;
            properties_sold_over = 'Option is required';
        }

        if (stepFiveData.properties_sold_over === 'Yes' && !stepFiveData.tax_file_number) {
            error = true;
            tax_file_number = 'Tax file number is required';
        } else if (stepFiveData.properties_sold_over === 'Yes' && stepFiveData.tax_file_number.length < 3) {
            error = true;
            tax_file_number = 'Tax file number must contain atleast 3 characters';
        }

        if (stepFiveData.properties_sold_over === 'Yes' && !stepFiveData.residency_status_changed) {
            error = true;
            residency_status_changed = 'Option is required';
        }

        if (stepFiveData.properties_sold_over === 'Yes' && !stepFiveData.lodged_tax_return) {
            error = true;
            lodged_tax_return = 'Option is required';
        }

        if (stepFiveData.properties_sold_over === 'Yes' && !stepFiveData.holding_property_behalf) {
            error = true;
            holding_property_behalf = 'Option is required';
        }

        if (!stepFiveData.signature) {
            error = true;
            signature = 'Full Name is required';
        }

        setValidStepFive({
            error, owner_house_built, initial_deposit_made, deposit_amount_paid, first_home, eligible_for_health_card, applying_for_concession, occupy_as_ppr, date_of_occupation,
            present_fhog, prev_owned_prop, occupied_res_prop_cont, prev_fhog, cash_purchaser, bank, approval_of_loan, apply_title_insurance, properties_sold_over, tax_file_number,
            residency_status_changed, lodged_tax_return, holding_property_behalf, signature
        })

    }, [stepFiveData]) //eslint-disable-line

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_COUNTRIES}`);
                const results = response.data?.map(item => item.name.common);
                results.sort();
                setCountries(results);
            } catch (err) {
                console.error(err);
                setCountries(['Australia']);
            }
        }
        fetchCountries();
    }, []) //eslint-disable-line

    return (
        submitted ? (
            <div className='submit-page'>
                <span className='fw-bold'>Thank you for providing this information. Feel free to contact us if you wish to discuss further.</span>
            </div>) :
            submitting ? (
                <div className='submit-page'>
                    <span className='fw-bold'>Submitting the data, please wait.</span>
                </div>
            ) : (<>
                <div className='card mx-1 px-2 my-3 mx-md-5 px-md-5 py-4'>
                    <div className='fs-5 fw-bold text-center mb-3'>Purchaser Details</div>
                    <div className='d-flex justify-content-between gap-2'>
                        <div className='steps'>
                            <div className='step-box-active mb-1' />
                            Step 1: Personal Information
                        </div>
                        <div className='steps'>
                            <div className={`${step > 0 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 2: Purchaser property details
                        </div>
                        <div className='steps'>
                            <div className={`${step > 1 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 3: Ownership & Title rights
                        </div>
                        <div className='steps'>
                            <div className={`${step > 2 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 4: Property type and details
                        </div>
                        <div className='steps'>
                            <div className={`${step > 3 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 5: Others
                        </div>
                        <div className='steps'>
                            <div className={`${step > 4 ? 'step-box-active' : 'step-box'} mb-1`} />
                            Step 6: Review
                        </div>
                    </div>
                </div>
                {step === 0 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    {window.innerWidth < 768 && <div className='fw-bold'>Purchaser Details</div>}
                    <div className='mb-3'>
                        <span className={`fw-bold pb-1 me-5 ${purchaser === 0 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editPurchaser(0)}>
                            {window.innerWidth < 768 ? '1st' : 'First Purchaser Details'}
                        </span>
                        {purchaserData.length > 1 && <span className={`fw-bold pb-1 me-5 ${purchaser === 1 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editPurchaser(1)}>
                            {window.innerWidth < 768 ? '2nd' : 'Second Purchaser Details'}</span>}
                        {purchaserData.length > 2 && <span className={`fw-bold pb-1 me-5 ${purchaser === 2 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editPurchaser(2)}>
                            {window.innerWidth < 768 ? '3rd' : 'Third Purchaser Details'}</span>}
                        {purchaserData.length > 3 && <span className={`fw-bold pb-1 me-5 ${purchaser === 3 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editPurchaser(3)}>
                            {window.innerWidth < 768 ? '4th' : 'Fourth Purchaser Details'}</span>}
                        {purchaserData.length > 4 && <span className={`fw-bold pb-1 ${purchaser === 4 ? 'newUi-tabs-active' : 'newUi-tabs-inactive'}`} onClick={() => editPurchaser(4)}>
                            {window.innerWidth < 768 ? '5th' : 'Fifth Purchaser Details'}</span>}
                    </div>
                    <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>First Name / Given Name *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <select name='gender' value={purchaserData[purchaser].gender} className='pro-input mx-2' onChange={handlePurchaserChange} onFocus={() => setAutoAddress('')}>
                                    <option disabled value=''></option>
                                    <option value='Male'>Mr</option>
                                    <option value='Female'>Mrs</option>
                                    <option value='Female'>Ms</option>
                                </select>
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='given_name'
                                    value={purchaserData[purchaser].given_name || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='First Name / Given Name' />

                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.gender ? validPurchaser.gender : validPurchaser.given_name}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Middle Name</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='middle_name'
                                    value={purchaserData[purchaser].middle_name || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Middle Name (if any)' />
                            </div>
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Surname *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='surname'
                                    value={purchaserData[purchaser].surname || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Surname' />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.surname}</div>}
                        </div>
                    </div>
                    <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>Email *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faAt} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='email'
                                    value={purchaserData[purchaser].email || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Email' />

                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.email}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Mobile Number *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faPhone} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='mobile'
                                    value={purchaserData[purchaser].mobile || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Mobile Number' />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.mobile}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Date of Birth *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faCalendar} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='date'
                                    name='dob'
                                    value={purchaserData[purchaser].dob || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')} />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.dob}</div>}
                        </div>
                    </div>
                    <div className='pf-row'>
                        <div className='pf-label'>Phone Number</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faPhone} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='phone'
                                value={purchaserData[purchaser].phone || ''}
                                onChange={handlePurchaserChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Phone number' />
                        </div>
                        {validate && <div className='newUi-err'>{validPurchaser.phone}</div>}
                    </div>
                    <div className='pf-row w-100 position-relative'>
                        <label className='pf-label'>Address *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='address'
                                value={purchaserData[purchaser].address || ''}
                                onChange={(e) => handleAddressChange(e, 'purchaser')}
                                onFocus={() => setAutoAddress('purchaser')}
                                autoComplete='off'
                                placeholder='Address' />
                        </div>
                        {autoAddress === 'purchaser' && purchaserData[purchaser].address?.length > 0 && <div className='addr-sugg'>
                            {addrResults.length > 0 ? addrResults.map((item, index) => <div key={index} className='sugg' onClick={() => addAddress(item, 'purchaser')}>{`${item.address_line1}, ${item.address_line2}`}</div>)
                                : <div className='sugg'>No address found</div>}
                        </div>}
                        <div className='w-100 d-flex align-items-center'>
                            {validate && <div className='newUi-err'>{validPurchaser.address}</div>}
                            {purchaser !== 0 && <>
                                <input type='checkbox' className='mx-2' checked={sameAddr[purchaser]} onChange={handleSameAddr} />
                                <span className='pf-label'>Add same address as first purchaser</span></>}
                        </div>
                    </div>
                    <div className='pf-row mt-2'>
                        <label className='pf-label'>What is your citizenship status ? Please choose from below</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <select name='aus_res' value={purchaserData[purchaser].aus_res} className='pro-input w-100 mx-2' onChange={handlePurchaserChange} onFocus={() => setAutoAddress('')}>
                                <option disabled value=''>Choose any option</option>
                                <option value='Australian citizen'>Australian citizen</option>
                                <option value='Australian Permanent Resident'>Australian Permanent Resident</option>
                                <option value='New Zealand Citizen holding Special Catgeory Visa'>New Zealand Citizen holding Special Catgeory Visa</option>
                                <option value='Foreign Natural Person'>Foreign Natural Person</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validPurchaser.aus_res}</div>}
                    </div>
                    <div className='pf-row'>
                        <div className='pf-label'>Country of Citizenship *</div>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faGlobe} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='citizen'
                                list='countries'
                                value={purchaserData[purchaser].citizen || ''}
                                onChange={handlePurchaserChange}
                                onFocus={() => setAutoAddress('')}
                                placeholder='Country of citizenship' />
                            <datalist id='countries'>
                                {countries?.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </datalist>
                        </div>
                        {validate && <div className='newUi-err'>{validPurchaser.citizen}</div>}
                    </div>
                    {!validPurchaser.citizen && purchaserData[purchaser].citizen && purchaserData[purchaser].citizen !== 'Australia' && <>
                        <div className='d-md-flex w-100 gap-4 pf-row'>
                            <div className='prospect-field'>
                                <label className='pf-label'>First Name / Given Name as per Visa *</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faUser} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='visa_name'
                                        value={purchaserData[purchaser].visa_name || ''}
                                        onChange={handlePurchaserChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Name as per the Visa' />

                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Date of Birth as per Visa *</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='date'
                                        name='visa_dob'
                                        value={purchaserData[purchaser].visa_dob || ''}
                                        onFocus={() => setAutoAddress('')}
                                        onChange={handlePurchaserChange} />
                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Passport Number *</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faPassport} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='passport_no'
                                        value={purchaserData[purchaser].passport_no || ''}
                                        onChange={handlePurchaserChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Passport number' />
                                </div>
                            </div>
                        </div></>}
                    <div className='d-flex w-100 gap-4 align-items-center mt-2 mt-md-0'>
                        <span className='pf-label res-width-2'>Have you ever used a different name? </span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='diff_name_used_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${purchaserData[purchaser].diff_name_used === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='diff_name_used' id='diff_name_used_yes' onChange={handlePurchaserChange} />
                                {purchaserData[purchaser].diff_name_used === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='diff_name_used_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${purchaserData[purchaser].diff_name_used === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='diff_name_used' id='diff_name_used_no' onChange={handlePurchaserChange} />
                                {purchaserData[purchaser].diff_name_used === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                    </div>
                    {purchaserData[purchaser].diff_name_used === 'Yes' && <div className='d-md-flex w-100 gap-4 pf-row'>
                        <div className='prospect-field'>
                            <label className='pf-label'>First Name / Given Name *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='given_name_diff'
                                    value={purchaserData[purchaser].given_name_diff || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='First name / Given Name' />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.given_name_diff}</div>}
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Middle Name</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='middle_name_diff'
                                    value={purchaserData[purchaser].middle_name_diff || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Middle Name (if any)' />
                            </div>
                        </div>
                        <div className='prospect-field'>
                            <label className='pf-label'>Surname *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faUser} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='surname_diff'
                                    value={purchaserData[purchaser].surname_diff || ''}
                                    onChange={handlePurchaserChange}
                                    onFocus={() => setAutoAddress('')}
                                    placeholder='Surname' />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.surname_diff}</div>}
                        </div>
                    </div>}
                    <div className='d-flex w-100 gap-4 align-items-center my-2'>
                        <span className='pf-label res-width-2'>Do you have a spouse/partner that is not listed as a transferee in the contract to purchase this property? </span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='not_listed_partner_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${purchaserData[purchaser].not_listed_partner === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='not_listed_partner' id='not_listed_partner_yes' onChange={handlePurchaserChange} />
                                {purchaserData[purchaser].not_listed_partner === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='not_listed_partner_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${purchaserData[purchaser].not_listed_partner === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='not_listed_partner' id='not_listed_partner_no' onChange={handlePurchaserChange} />
                                {purchaserData[purchaser].not_listed_partner === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                    </div>
                    {purchaserData[purchaser].not_listed_partner === 'Yes' && <>
                        <div className='d-md-flex w-100 gap-4 pf-row'>
                            <div className='prospect-field'>
                                <label className='pf-label'>First Name / Given Name *</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faUser} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='given_name_partner'
                                        value={purchaserData[purchaser].given_name_partner || ''}
                                        onChange={handlePurchaserChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='First name / Given Name' />
                                </div>
                                {validate && <div className='newUi-err'>{validPurchaser.given_name_partner}</div>}
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Middle Name</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faUser} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='middle_name_partner'
                                        value={purchaserData[purchaser].middle_name_partner || ''}
                                        onChange={handlePurchaserChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Middle Name (if any)' />
                                </div>
                            </div>
                            <div className='prospect-field'>
                                <label className='pf-label'>Surname *</label>
                                <div className='d-flex align-items-center newUi-field px-2'>
                                    <FontAwesomeIcon icon={faUser} />
                                    <input
                                        className='pro-input ps-2 w-100'
                                        type='text'
                                        name='surname_partner'
                                        value={purchaserData[purchaser].surname_partner || ''}
                                        onChange={handlePurchaserChange}
                                        onFocus={() => setAutoAddress('')}
                                        placeholder='Surname' />
                                </div>
                                {validate && <div className='newUi-err'>{validPurchaser.surname_partner}</div>}
                            </div>
                        </div>
                        <div className='pf-row'>
                            <label className='pf-label'>Date of Birth as per Visa : *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <input
                                    className='pro-input ps-2'
                                    type='date'
                                    name='partner_dob'
                                    value={purchaserData[purchaser].partner_dob || ''}
                                    onFocus={() => setAutoAddress('')}
                                    onChange={handlePurchaserChange} />
                            </div>
                            {validate && <div className='newUi-err'>{validPurchaser.partner_dob}</div>}
                        </div></>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        {purchaserData.length !== 5 && <button className='au-btn res-width-3 my-2' onClick={addPurchaser}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            <span className='ms-2'>Add Purchaser</span>
                        </button>}
                        {purchaserData.length !== 1 && <button className='au-btn res-width-3 my-2' onClick={removePurchaser}>
                            <FontAwesomeIcon icon={faCircleMinus} />
                            <span className='ms-2'>Remove Purchaser</span>
                        </button>}
                        <button className='au-btn res-width-3 ms-auto my-2' onClick={validatePurchaser}>
                            Next Step
                        </button>
                    </div>
                </>}
                {step === 1 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='pf-row w-100 position-relative'>
                        <label className='pf-label'>Address of property being purchased *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faLocationDot} />
                            <input
                                className='pro-input ps-2 w-100'
                                type='text'
                                name='prop_address'
                                value={stepTwoData.prop_address || ''}
                                onChange={(e) => handleAddressChange(e, 'property')}
                                onFocus={() => setAutoAddress('property')}
                                autoComplete='off'
                                placeholder='Address' />
                        </div>
                        {autoAddress === 'property' && stepTwoData.prop_address?.length > 0 && <div className='addr-sugg'>
                            {addrResults.length > 0 ? addrResults.map((item, index) => <div key={index} className='sugg' onClick={() => addAddress(item, 'property')}>{`${item.address_line1}, ${item.address_line2}`}</div>)
                                : <div className='sugg'>No address found</div>}
                        </div>}
                        <div className='w-100 d-flex align-items-center'>
                            {validate && <div className='newUi-err'>{validStepTwo.prop_address}</div>}
                            {purchaser !== 0 && <>
                                <input type='checkbox' className='mx-2' checked={sameAddr[purchaser]} onChange={handleSameAddr} />
                                <span className='pf-label'>Add same address as first purchaser</span></>}
                        </div>
                    </div>
                    <div className='pf-row'>
                        <label className='pf-label'>Name of real-estate agent </label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='text'
                                name='agent_name'
                                value={stepTwoData.agent_name || ''}
                                onFocus={() => setAutoAddress('')}
                                onChange={handleStepTwoDataChange}
                                placeholder='Name of real-estate agent' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepTwo.agent_name}</div>}
                    </div>
                    <div className='d-flex w-100 gap-4 align-items-center my-2'>
                        <span className='pf-label res-width-2'>Is it an Off the Plan (Untitled Property) purchase? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='off_plan_purchase_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.off_plan_purchase === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='off_plan_purchase' id='off_plan_purchase_yes' onChange={handleStepTwoDataChange} />
                                {stepTwoData.off_plan_purchase === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='off_plan_purchase_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.off_plan_purchase === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='off_plan_purchase' id='off_plan_purchase_no' onChange={handleStepTwoDataChange} />
                                {stepTwoData.off_plan_purchase === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepTwo.off_plan_purchase}</div>}
                    </div>
                    <div className='d-flex w-100 gap-4 align-items-center my-2'>
                        <span className='pf-label res-width-2'>Are you Contract Purchaser? (Have you signed the contract) *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='contract_purchaser_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.contract_purchaser === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='contract_purchaser' id='contract_purchaser_yes' onChange={handleStepTwoDataChange} />
                                {stepTwoData.contract_purchaser === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='contract_purchaser_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepTwoData.contract_purchaser === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='contract_purchaser' id='contract_purchaser_no' onChange={handleStepTwoDataChange} />
                                {stepTwoData.contract_purchaser === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepTwo.contract_purchaser}</div>}
                    </div>
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={goToStepOne}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepTwo}>Next Step</button>
                    </div>
                </>}
                {step === 2 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Do you have a Nominated Purchaser? (Do you intend to add your spouse or partner in the contract prior to settlement) *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='nominated_purchaser_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.nominated_purchaser === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='nominated_purchaser' id='nominated_purchaser_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.nominated_purchaser === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='nominated_purchaser_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.nominated_purchaser === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='nominated_purchaser' id='nominated_purchaser_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.nominated_purchaser === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.nominated_purchaser}</div>}
                    </div>
                    {stepThreeData.nominated_purchaser === 'Yes' && <div className='pf-row px-3'>
                        <label className='pf-label'>If Nominated Purchaser What is your Relationship? *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='text'
                                name='nominated_purchaser_relationship'
                                value={stepThreeData.nominated_purchaser_relationship || ''}
                                onChange={handleStepThreeDataChange}
                                placeholder='Relationship with nominated purchasers' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.nominated_purchaser_relationship}</div>}
                    </div>}
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Do you have a Joint Purchaser? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='joint_purchaser_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.joint_purchaser === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='joint_purchaser' id='joint_purchaser_yes' onChange={handleStepThreeDataChange} />
                                {stepThreeData.joint_purchaser === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='joint_purchaser_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepThreeData.joint_purchaser === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='joint_purchaser' id='joint_purchaser_no' onChange={handleStepThreeDataChange} />
                                {stepThreeData.joint_purchaser === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepThree.joint_purchaser}</div>}
                    </div>
                    {stepThreeData.joint_purchaser === 'Yes' && <div className='pf-row px-3'>
                        <label className='pf-label'>If joint purchasers what is your Relationship? *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faUser} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='text'
                                name='joint_purchaser_relationship'
                                value={stepThreeData.joint_purchaser_relationship || ''}
                                onChange={handleStepThreeDataChange}
                                placeholder='Relationship with joint purchasers' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.joint_purchaser_relationship}</div>}
                    </div>}
                    <div className='pf-row'>
                        <label className='pf-label'>What should be your right in the title? *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faHandshake} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='text'
                                name='right_in_the_title'
                                value={stepThreeData.right_in_the_title || ''}
                                onChange={handleStepThreeDataChange}
                                placeholder='Right in the title' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepThree.right_in_the_title}</div>}
                    </div>
                    <i className='ps'>Eg: Sole proprietor/Joint Proprietors / Tenants in Common (Joint Proprietor means both purchasers have equal rights and there will be right of survivorship. If Tenants in Common, percentage of ownership can be different and there is no right of survivorship)</i>
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={() => goToStep(1)}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepThree}>Next Step</button>
                    </div>
                </>}
                {step === 3 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='pf-row'>
                        <label className='pf-label'>Is it for residential or commercial purpose? *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <select name='purpose' value={stepFourData.purpose} className='pro-input w-100 mx-2' onChange={handleStepFourDataChange} >
                                <option disabled value=''>Choose any option</option>
                                <option value='Residential'>Residential</option>
                                <option value='Commercial'>Commercial</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validStepFour.purpose}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Is Owner’s corporation applicable? (Any common area or common service to the property) *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='owner_corp_applicable_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.owner_corp_applicable === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='owner_corp_applicable' id='owner_corp_applicable_yes' onChange={handleStepFourDataChange} />
                                {stepFourData.owner_corp_applicable === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='owner_corp_applicable_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.owner_corp_applicable === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='owner_corp_applicable' id='owner_corp_applicable_no' onChange={handleStepFourDataChange} />
                                {stepFourData.owner_corp_applicable === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFour.owner_corp_applicable}</div>}
                    </div>
                    <div className='pf-row'>
                        <label className='pf-label'>Is it a house/vacant land/apartment? (Please choose one option) *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            <select name='property_type' value={stepFourData.property_type} className='pro-input w-100 mx-2' onChange={handleStepFourDataChange} >
                                <option disabled value=''>Choose any option</option>
                                <option value='House'>House</option>
                                <option value='Vacant Land'>Vacant Land</option>
                                <option value='Apartment'>Apartment</option>
                            </select>
                        </div>
                        {validate && <div className='newUi-err'>{validStepFour.property_type}</div>}
                    </div>
                    {(stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && <>
                        <div className='pf-row px-3'>
                            <label className='pf-label'>Number of Bedrooms *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faBed} />
                                <input
                                    className='pro-input w-100 ps-2'
                                    type='number'
                                    min={1}
                                    name='no_bedrooms'
                                    value={stepFourData.no_bedrooms || ''}
                                    onChange={handleStepFourDataChange}
                                    placeholder='Number of bedrooms' />
                            </div>
                            {validate && <div className='newUi-err'>{validStepFour.no_bedrooms}</div>}
                        </div>
                        <div className='pf-row px-3'>
                            <label className='pf-label'>Material of the bulding *</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faMapLocationDot} />
                                <select name='material_of_building' value={stepFourData.material_of_building} className='pro-input w-100 mx-2' onChange={handleStepFourDataChange} >
                                    <option disabled value=''>Choose any option</option>
                                    <option value='Brick'>Brick</option>
                                    <option value='Brick Veneer'>Brick Veneer</option>
                                    <option value='Timber'>Timber</option>
                                    <option value='Fibro Cement'>Fibro Cement</option>
                                    <option value='Others'>Others</option>
                                </select>
                            </div>
                            {validate && <div className='newUi-err'>{validStepFour.material_of_building}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Is the house less than seven years old? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='less_than_seven_years_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.less_than_seven_years === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='less_than_seven_years' id='less_than_seven_years_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.less_than_seven_years === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='less_than_seven_years_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.less_than_seven_years === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='less_than_seven_years' id='less_than_seven_years_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.less_than_seven_years === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.less_than_seven_years}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Is building warranty insurance applicable? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='building_warranty_insurance_applicable_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_warranty_insurance_applicable === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='building_warranty_insurance_applicable' id='building_warranty_insurance_applicable_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.building_warranty_insurance_applicable === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='building_warranty_insurance_applicable_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_warranty_insurance_applicable === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='building_warranty_insurance_applicable' id='building_warranty_insurance_applicable_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.building_warranty_insurance_applicable === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.building_warranty_insurance_applicable}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Was the house built by the owner or through a registered builder? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='builder_registered_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.builder_registered === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='builder_registered' id='builder_registered_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.builder_registered === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='builder_registered_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.builder_registered === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='builder_registered' id='builder_registered_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.builder_registered === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.builder_registered}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Any building permits? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='building_permit_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_permit === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='building_permit' id='building_permit_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.building_permit === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='building_permit_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.building_permit === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='building_permit' id='building_permit_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.building_permit === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.building_permit}</div>}
                        </div>
                    </>}
                    {stepFourData.property_type === 'Vacant Land' && <>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Do you intend to build a house and use it as your main residence within 1 year from the date of settlement (There may be a requirement in the contract) *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='intend_to_build_house_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.intend_to_build_house === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='intend_to_build_house' id='intend_to_build_house_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.intend_to_build_house === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='intend_to_build_house_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.intend_to_build_house === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='intend_to_build_house' id='intend_to_build_house_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.intend_to_build_house === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.intend_to_build_house}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Have you signed a building contract? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='signed_building_contract_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.signed_building_contract === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='signed_building_contract' id='signed_building_contract_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.signed_building_contract === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='signed_building_contract_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.signed_building_contract === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='signed_building_contract' id='signed_building_contract_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.signed_building_contract === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.signed_building_contract}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                            <span className='pf-label px-3 w-75'>Are you claiming First Home Owners’ Grant? (Applicable only for brand new home) *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='claim_first_home_owner_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.claim_first_home_owner === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='claim_first_home_owner' id='claim_first_home_owner_yes' onChange={handleStepFourDataChange} />
                                    {stepFourData.claim_first_home_owner === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='claim_first_home_owner_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFourData.claim_first_home_owner === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='claim_first_home_owner' id='claim_first_home_owner_no' onChange={handleStepFourDataChange} />
                                    {stepFourData.claim_first_home_owner === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFour.claim_first_home_owner}</div>}
                        </div>
                    </>}
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={() => goToStep(2)}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepFour}>Next Step</button>
                    </div>
                </>}
                {step === 4 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Was the house built by the owner builder? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='owner_house_built_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.owner_house_built === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='owner_house_built' id='owner_house_built_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.owner_house_built === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='owner_house_built_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.owner_house_built === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='owner_house_built' id='owner_house_built_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.owner_house_built === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.owner_house_built}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Have you made any deposit? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='initial_deposit_made_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.initial_deposit_made === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='initial_deposit_made' id='initial_deposit_made_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.initial_deposit_made === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='initial_deposit_made_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.initial_deposit_made === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='initial_deposit_made' id='initial_deposit_made_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.initial_deposit_made === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.initial_deposit_made}</div>}
                    </div>
                    {stepFiveData.initial_deposit_made === 'Yes' && <div className='pf-row px-3'>
                        <label className='pf-label'>If so, what was the amount paid as deposit (Australian Dollars) *</label>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faDollarSign} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='text'
                                name='deposit_amount_paid'
                                value={stepFiveData.deposit_amount_paid || ''}
                                onChange={handleStepFiveDataChange}
                                placeholder='Amount paid as deposit' />
                        </div>
                        {validate && <div className='newUi-err'>{validStepFive.deposit_amount_paid}</div>}
                    </div>}
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Is this your First Home? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='first_home_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.first_home === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='first_home' id='first_home_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.first_home === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='first_home_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.first_home === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='first_home' id='first_home_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.first_home === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.first_home}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Are you eligible for health care card/ pension card Stamp duty concession? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='eligible_for_health_card_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.eligible_for_health_card === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='eligible_for_health_card' id='eligible_for_health_card_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.eligible_for_health_card === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='eligible_for_health_card_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.eligible_for_health_card === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='eligible_for_health_card' id='eligible_for_health_card_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.eligible_for_health_card === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.eligible_for_health_card}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Are you applying for a concession of exemption of stamp duty in relation to this transaction? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='applying_for_concession_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.applying_for_concession === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='applying_for_concession' id='applying_for_concession_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.applying_for_concession === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='applying_for_concession_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.applying_for_concession === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='applying_for_concession' id='applying_for_concession_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.applying_for_concession === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.applying_for_concession}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Do you intend to occupy the property as your principle place of residence (PPR)? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='occupy_as_ppr_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.occupy_as_ppr === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='occupy_as_ppr' id='occupy_as_ppr_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.occupy_as_ppr === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='occupy_as_ppr_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.occupy_as_ppr === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='occupy_as_ppr' id='occupy_as_ppr_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.occupy_as_ppr === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.occupy_as_ppr}</div>}
                    </div>
                    {stepFiveData.occupy_as_ppr === 'Yes' && <div className='pf-row px-3'>
                        <span className='pf-label'>If yes, what is the intended date of occupation? *</span>
                        <div className='d-flex align-items-center newUi-field px-2'>
                            <FontAwesomeIcon icon={faCalendar} />
                            <input
                                className='pro-input w-100 ps-2'
                                type='date'
                                name='date_of_occupation'
                                value={stepFiveData.date_of_occupation || ''}
                                onChange={handleStepFiveDataChange} />
                        </div>
                        {validate && <div className='newUi-err'>{validStepFive.date_of_occupation}</div>}
                    </div>}
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Have you received the First Home Owner Grant (FHOG) for this property? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='present_fhog_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.present_fhog === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='present_fhog' id='present_fhog_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.present_fhog === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='present_fhog_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.present_fhog === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='present_fhog' id='present_fhog_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.present_fhog === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.present_fhog}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Have you previously owned residential property, either jointly or separately or with some other person before 1 July 2000 in any State or territory of Australia? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='prev_owned_prop_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.prev_owned_prop === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='prev_owned_prop' id='prev_owned_prop_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.prev_owned_prop === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='prev_owned_prop_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.prev_owned_prop === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='prev_owned_prop' id='prev_owned_prop_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.prev_owned_prop === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.prev_owned_prop}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Have you occupied a residential property for a continuous period for at least six months in which you acquired a relevant interest on or after 1 July 2000? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='occupied_res_prop_cont_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.occupied_res_prop_cont === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='occupied_res_prop_cont' id='occupied_res_prop_cont_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.occupied_res_prop_cont === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='occupied_res_prop_cont_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.occupied_res_prop_cont === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='occupied_res_prop_cont' id='occupied_res_prop_cont_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.occupied_res_prop_cont === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.occupied_res_prop_cont}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Have you previously received the FHOG in any state or territory of Australia? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='prev_fhog_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.prev_fhog === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='prev_fhog' id='prev_fhog_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.prev_fhog === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='prev_fhog_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.prev_fhog === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='prev_fhog' id='prev_fhog_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.prev_fhog === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.prev_fhog}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Are you a cash Purchaser? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='cash_purchaser_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.cash_purchaser === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='cash_purchaser' id='cash_purchaser_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.cash_purchaser === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='cash_purchaser_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.cash_purchaser === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='cash_purchaser' id='cash_purchaser_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.cash_purchaser === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.cash_purchaser}</div>}
                    </div>
                    {stepFiveData.cash_purchaser === 'No' && <>
                        <div className='pf-row px-3'>
                            <span className='pf-label'>If not, Who is your Lender/ Bank? </span>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faBuildingColumns} />
                                <input
                                    className='pro-input w-100 ps-2'
                                    type='text'
                                    name='bank'
                                    value={stepFiveData.bank || ''}
                                    onChange={handleStepFiveDataChange}
                                    placeholder='Lender or Bank' />
                            </div>
                            {validate && <div className='error-message mt-1'>{validStepFive.bank}</div>}
                        </div>
                        <div className='pf-row px-3'>
                            <label className='pf-label'>Did you get final approval of your loan?</label>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <select name='approval_of_loan' value={stepFiveData.approval_of_loan} className='pro-input w-100 mx-2' onChange={handleStepFiveDataChange} >
                                    <option disabled value=''>Choose any option</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                    <option value='Awaiting'>Awaiting</option>
                                </select>
                            </div>
                            {validate && <div className='newUi-err'>{validStepFive.approval_of_loan}</div>}
                        </div>
                    </>}
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Would you like to apply for title insurance? (For details, please contact office) *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='apply_title_insurance_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.apply_title_insurance === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='apply_title_insurance' id='apply_title_insurance_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.apply_title_insurance === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='apply_title_insurance_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.apply_title_insurance === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='apply_title_insurance' id='apply_title_insurance_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.apply_title_insurance === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.apply_title_insurance}</div>}
                    </div>
                    <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label w-75'>Is properties sold over $750,000.00 ? *</span>
                        <div className='d-flex align-items-center gap-2'>
                            <label htmlFor='properties_sold_over_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.properties_sold_over === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='Yes' type='radio' name='properties_sold_over' id='properties_sold_over_yes' onChange={handleStepFiveDataChange} />
                                {stepFiveData.properties_sold_over === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>Yes</span>
                            </label>
                            <label htmlFor='properties_sold_over_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.properties_sold_over === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                <input className='d-none' value='No' type='radio' name='properties_sold_over' id='properties_sold_over_no' onChange={handleStepFiveDataChange} />
                                {stepFiveData.properties_sold_over === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                <span className='ms-1'>No</span>
                            </label>
                        </div>
                        {validate && <div className='newUi-err ps-2'>{validStepFive.properties_sold_over}</div>}
                    </div>
                    {stepFiveData.properties_sold_over === 'Yes' && <>
                        <div className='pf-row px-3'>
                            <span className='pf-label'>Tax File Number </span>
                            <div className='d-flex align-items-center newUi-field px-2'>
                                <FontAwesomeIcon icon={faHashtag} />
                                <input
                                    className='pro-input w-100 ps-2'
                                    type='text'
                                    name='tax_file_number'
                                    value={stepFiveData.tax_file_number || ''}
                                    onChange={handleStepFiveDataChange}
                                    placeholder='Tax File Number' />
                            </div>
                            {validate && <div className='error-message mt-1'>{validStepFive.tax_file_number}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label ps-3 w-75'>Has your residency status changed since your last Tax Return or will it change before you sell the property ? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='residency_status_changed_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.residency_status_changed === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='residency_status_changed' id='residency_status_changed_yes' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.residency_status_changed === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='residency_status_changed_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.residency_status_changed === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='residency_status_changed' id='residency_status_changed_no' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.residency_status_changed === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFive.residency_status_changed}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label ps-3 w-75'>Have you lodged a tax return in the last two years ? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='lodged_tax_return_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.lodged_tax_return === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='lodged_tax_return' id='lodged_tax_return_yes' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.lodged_tax_return === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='lodged_tax_return_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.lodged_tax_return === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='lodged_tax_return' id='lodged_tax_return_no' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.lodged_tax_return === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFive.lodged_tax_return}</div>}
                        </div>
                        <div className='d-flex w-100 align-items-center my-2'>
                        <span className='pf-label ps-3 w-75'>Are you holding property on behalf of a foreign resident or on behalf of other entities that include a foreign resident ? *</span>
                            <div className='d-flex align-items-center gap-2'>
                                <label htmlFor='holding_property_behalf_yes' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.holding_property_behalf === 'Yes' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='Yes' type='radio' name='holding_property_behalf' id='holding_property_behalf_yes' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.holding_property_behalf === 'Yes' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>Yes</span>
                                </label>
                                <label htmlFor='holding_property_behalf_no' className={`d-flex align-items-center gap-1 px-2 py-1 rounded ${stepFiveData.holding_property_behalf === 'No' ? 'sel-option' : 'unsel-option'}`}>
                                    <input className='d-none' value='No' type='radio' name='holding_property_behalf' id='holding_property_behalf_no' onChange={handleStepFiveDataChange} />
                                    {stepFiveData.holding_property_behalf === 'No' ? <FontAwesomeIcon icon={faCheck} size='sm' /> : <FontAwesomeIcon icon={faCircle} size='sm' />}
                                    <span className='ms-1'>No</span>
                                </label>
                            </div>
                            {validate && <div className='newUi-err ps-2'>{validStepFive.holding_property_behalf}</div>}
                        </div>
                        </>}
                    <div className='d-flex mt-3'>
                        <div className='d-flex w-50 align-items-center my-2'>
                            <span className='pf-label me-2' >Date : </span>
                            <div className='newUi-field p-2'>{todayDate}</div>
                        </div>
                        <div className='d-flex w-50 align-items-center my-2'>
                            <span className='pf-label text-nowrap ms-auto me-1' >Full Name : *</span>
                            <div className='d-flex align-items-center newUi-field px-2 w-auto ms-auto'>
                                <FontAwesomeIcon icon={faPenClip} />
                                <input
                                    className='pro-input ps-2 w-100'
                                    type='text'
                                    name='signature'
                                    value={stepFiveData.signature || ''}
                                    onChange={handleStepFiveDataChange}
                                    placeholder='Full name' />
                            </div>
                        </div>
                        {validate && <div className='w-auto ms-auto newUi-err ps-2 mt-1'>{validStepFive.signature}</div>}
                    </div>
                </div>
                    <div className='d-md-flex mx-1 mx-md-5 gap-4'>
                        <button className='au-btn res-width-3 my-2' onClick={() => goToStep(3)}>Prev Step</button>
                        <button className='au-btn res-width-3 my-2 ms-auto' onClick={validateStepFive}>Review</button>
                    </div>
                </>}
                {step === 5 && <><div className='card mx-1 px-2 mx-md-5 px-md-5 py-4 mb-4'>
                    {purchaserData.map((pur, index) => <div key={index}>
                        <div className='fw-bold d-flex'>
                            <span>{index === 0 ? 'First' : index === 1 ? 'Second' : index === 2 ? 'Third' : index === 3 ? 'Fourth' : 'Fifth'} Purchaser Details</span>
                            <button className='ms-auto au-btn' onClick={() => editPurchaser(index)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>First Name / Given Name :</span>
                            <b className='w-50'>{pur.given_name}</b>
                        </div>
                        {pur.middle_name && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Middle Name :</span>
                            <b className='w-50'>{pur.middle_name}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Surname :</span>
                            <b className='w-50'>{pur.surname}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Gender :</span>
                            <b className='w-50'>{pur.gender}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Different name used? </span>
                            <b className='w-50'>{pur.diff_name_used}</b>
                        </div>
                        {pur.diff_name_used === 'Yes' && <>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>First Name / Given Name :</span>
                                <b className='w-50'>{pur.given_name_diff}</b>
                            </div>
                            {pur.middle_name_diff && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Middle Name: </span>
                                <b className='w-50'>{pur.middle_name_diff}</b>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{pur.surname_diff}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Address :</span>
                            <b className='w-50'>{pur.address}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Phone number :</span>
                            <b className='w-50'>{pur.phone}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Mobile number :</span>
                            <b className='w-50'>{pur.mobile}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Email : </span>
                            <b className='w-50'>{pur.email}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Date of birth :</span>
                            <b className='w-50'>{pur.dob}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Citizenship Details :</span>
                            <b className='w-50'>{pur.aus_res}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Country of Citizenship :</span>
                            <b className='w-50'>{pur.citizen}</b>
                        </div>
                        {pur.citizen !== 'Australia' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Given Name as per Visa :</span>
                            <b className='w-50'>{pur.visa_name ? pur.visa_name : '-'}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Date of Birth as per Visa :</span>
                                <b className='w-50'>{pur.visa_dob ? pur.visa_dob : '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Passport Number :</span>
                                <b className='w-50'>{pur.passport_no ? pur.passport_no : '-'}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50 pe-4'>Do you have a spouse/partner that is not listed as a transferee in the contract to purchase this property? </span>
                            <b className='w-50'>{pur.not_listed_partner}</b>
                        </div>
                        {pur.not_listed_partner === 'Yes' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Surname :</span>
                            <b className='w-50'>{pur.surname_partner}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>First Name / Given Name :</span>
                                <b className='w-50'>{pur.given_name_partner}</b>
                            </div>
                            {pur.middle_name_partner && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Middle Name: </span>
                                <b className='w-50'>{pur.middle_name_partner}</b>
                            </div>}
                        </>}
                        {pur.not_listed_partner === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Date of birth :</span>
                            <b className='w-50'>{pur.partner_dob}</b>
                        </div>}
                        <hr />
                    </div>)}
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Purchaser Property Details</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(1)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Address of property being purchased :</span>
                            <b className='w-50'>{stepTwoData.prop_address}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Name of real-estate agent :</span>
                            <b className='w-50'>{stepTwoData.agent_name || '-'}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is it an Off the Plan (Untitled Property) purchase? </span>
                            <b className='w-50'>{stepTwoData.off_plan_purchase}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Are you Contract Purchaser? (Have you signed the contract) </span>
                            <b className='w-50'>{stepTwoData.contract_purchaser}</b>
                        </div>
                        <hr />
                    </div>
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Ownership & Title rights</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(2)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Do you have a Nominated Purchaser? </span>
                            <b className='w-50'>{stepThreeData.nominated_purchaser}</b>
                        </div>
                        {stepThreeData.nominated_purchaser === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Relationship with the Nominated Purchaser :</span>
                            <b className='w-50'>{stepThreeData.nominated_purchaser_relationship}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Do you have a Joint Purchaser? </span>
                            <b className='w-50'>{stepThreeData.joint_purchaser}</b>
                        </div>
                        {stepThreeData.joint_purchaser === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Relationship with the Joint Purchaser :</span>
                            <b className='w-50'>{stepThreeData.joint_purchaser_relationship}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>What should be your right in the title? </span>
                            <b className='w-50'>{stepThreeData.right_in_the_title}</b>
                        </div>
                        <hr />
                    </div>
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Property Type and Details</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(3)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is it for residential or commercial purpose? </span>
                            <b className='w-50'>{stepFourData.purpose}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is Owner’s corporation applicable? </span>
                            <b className='w-50'>{stepFourData.owner_corp_applicable}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Is it a house/vacant land/apartment? </span>
                            <b className='w-50'>{stepFourData.property_type}</b>
                        </div>
                        {(stepFourData.property_type === 'House' || stepFourData.property_type === 'Apartment') && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50'>Number of Bedrooms :</span>
                            <b className='w-50'>{stepFourData.no_bedrooms}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Any building permits? </span>
                                <b className='w-50'>{stepFourData.building_permit}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Material of the bulding :</span>
                                <b className='w-50'>{stepFourData.material_of_building}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is the house less than seven years old? </span>
                                <b className='w-50'>{stepFourData.less_than_seven_years}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is building warranty insurance applicable? </span>
                                <b className='w-50'>{stepFourData.building_warranty_insurance_applicable}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Was the house built by the owner or through a registered builder? </span>
                                <b className='w-50'>{stepFourData.builder_registered}</b>
                            </div></>}
                        {stepFourData.property_type === 'Vacant Land' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-50 pe-4'>Do you intend to build a house and use it as your main residence within 1 year from the date of settlement? </span>
                            <b className='w-50'>{stepFourData.intend_to_build_house}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Have you signed a building contract? </span>
                                <b className='w-50'>{stepFourData.signed_building_contract}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Are you claiming First Home Owners’ Grant? </span>
                                <b className='w-50'>{stepFourData.claim_first_home_owner}</b>
                            </div>
                        </>}
                        <hr />
                    </div>
                    <div>
                        <div className='fw-bold d-flex'>
                            <span>Others</span>
                            <button className='ms-auto au-btn' onClick={() => goToStep(4)}>
                                <FontAwesomeIcon className='me-2' icon={faPenToSquare} />
                                Edit</button>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Was the house built by the owner builder? </span>
                            <b className='w-25'>{stepFiveData.owner_house_built}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Have you made any deposit? </span>
                            <b className='w-25'>{stepFiveData.initial_deposit_made}</b>
                        </div>
                        {stepFiveData.initial_deposit_made === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Amount paid as deposit (Australian Dollars) :</span>
                            <b className='w-25'>{stepFiveData.deposit_amount_paid}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Are you eligible for health care card/ pension card Stamp duty concession? </span>
                            <b className='w-25'>{stepFiveData.eligible_for_health_card}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Is this your First Home? </span>
                            <b className='w-25'>{stepFiveData.first_home}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Are you applying for a concession of exemption of stamp duty in relation to this transaction? </span>
                            <b className='w-25'>{stepFiveData.applying_for_concession}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Do you intend to occupy the property as your principle place of residence (PPR)? </span>
                            <b className='w-25'>{stepFiveData.occupy_as_ppr}</b>
                        </div>
                        {stepFiveData.occupy_as_ppr === 'Yes' && <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Intended date of occupation? </span>
                            <b className='w-25'>{stepFiveData.date_of_occupation}</b>
                        </div>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Have you received the First Home Owner Grant (FHOG) for this property? </span>
                            <b className='w-25'>{stepFiveData.present_fhog}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75 pe-4'>Have you previously owned residential property, either jointly or separately or with some other person before 1 July 2000 in any State or territory of Australia? </span>
                            <b className='w-25'>{stepFiveData.prev_owned_prop}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75 pe-4'>Have you occupied a residential property for a continuous period for at least six months in which you acquired a relevant interest on or after 1 July 2000? </span>
                            <b className='w-25'>{stepFiveData.occupied_res_prop_cont}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Have you previously received the FHOG in any state or territory of Australia? </span>
                            <b className='w-25'>{stepFiveData.prev_fhog}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Are you a cash Purchaser? </span>
                            <b className='w-25'>{stepFiveData.cash_purchaser}</b>
                        </div>
                        {stepFiveData.cash_purchaser === 'No' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Who is your Lender/ Bank ? </span>
                            <b className='w-25'>{stepFiveData.bank || '-'}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-75'>Did you get final approval of your loan? </span>
                                <b className='w-25'>{stepFiveData.approval_of_loan}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Would you like to apply for title insurance? </span>
                            <b className='w-25'>{stepFiveData.apply_title_insurance}</b>
                        </div>
                        <div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Is properties sold over $750,000.00? </span>
                            <b className='w-25'>{stepFiveData.properties_sold_over}</b>
                        </div>
                        {stepFiveData.properties_sold_over === 'Yes' && <><div className='d-flex w-100 mb-3'>
                            <span className='w-75'>Tax File Number : </span>
                            <b className='w-25'>{stepFiveData.tax_file_number || '-'}</b>
                        </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-75'>Has your residency status changed since your last Tax Return or will it change before you sell the property? </span>
                                <b className='w-25'>{stepFiveData.residency_status_changed}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-75'>Have you lodged a tax return in the last two years? </span>
                                <b className='w-25'>{stepFiveData.lodged_tax_return || '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-75'>Are you holding property on behalf of a foreign resident or on behalf of other entities that include a foreign resident? </span>
                                <b className='w-25'>{stepFiveData.holding_property_behalf}</b>
                            </div>
                        </>}
                        <div className='d-flex w-100 my-4'>
                            <span>Date : <b className='ms-md-3'>{todayDate}</b></span>
                            <span className='ms-auto me-md-5'>Full Name: <b>{stepFiveData.signature}</b></span>
                        </div>
                        <div className='d-flex justify-content-center error-message'>{errMsg}</div>
                    </div>
                </div>
                    <div className='d-flex my-2'>
                        <button className='au-btn res-width-3 m-auto' onClick={submitData}>
                            <FontAwesomeIcon className='me-2' icon={faCheck} />
                            Submit</button>
                    </div>
                </>}
            </>)
    )
}

export default Purchaser