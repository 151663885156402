import React, { useEffect, useState } from 'react'
import './addNewCaseModal.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const AddNewCaseModal = (props) => {

    const initialState = { client_name: '', email: '', mobile: '', case_name: '', client_id: '' };
    const api = axiosRequestInterceptor(true);
    const entity_name = sessionStorage.getItem('SDX_entity_name');
    const entity_id = sessionStorage.getItem('SDX_entity_id');
    const userName = sessionStorage.getItem('SDX_user_name');
    const { isOpen, closeModal, fetchCasesData } = props;
    const [modalData, setModalData] = useState(initialState);
    const [clientType, setClientType] = useState('new');
    const [validMsg, setValidMsg] = useState({ error: true });
    const [clients, setClients] = useState([]);
    const [errFetch, setErrFetch] = useState('');
    const [touched, setTouched] = useState(false);

    const handleClientType = (e) => {
        setClientType(e.target.value);
        setModalData(initialState);
        setTouched(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let tempData= null;
        if(name === 'mobile'){
            let tempMob = '';
            if(value[0] === '+'){
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 5 || value.length === 9 ) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...modalData,
                [name]: tempMob
            }
        } else {
            tempData = {
                ...modalData,
                [name]: value
            }
        }
        setModalData(tempData);
    }

    const inputIsTouched = () => {
        setTouched(true);
    }

    const clearData = () => {
        closeModal();
        setTouched(true);
        setModalData(initialState);
    }

    const fetchClients = async() => {
        try{
            const response = await api.get(`/clients/getAllNames/${entity_id}`);
            setClients(response.data);
        }catch(err){
            if(err?.response?.data?.error){
                setErrFetch(err.response.data.error);
            }else{
                setErrFetch('Failed to connect with server');
            }
        }
    }

    const submitData = async() => {
        try{
            const short_name = entity_name.substring(0, 3).toUpperCase();
            await api.post('/clients/createCase', {
                name: modalData.client_name,
                email: modalData.email,
                mobile: modalData.mobile,
                client_id: modalData.client_id,
                case_name: modalData.case_name,
                entity_name: short_name,
                created_by: userName
            })
            fetchCasesData();
            closeModal();
            setTouched(true);
            setModalData(initialState);
        }catch(err){
            if (err?.response?.data?.email) {
                const email = err.response.data.email;
                setValidMsg({ error: true, email });
            } else if(err?.response?.data?.mobile) {
                const mobile = err.response.data.mobile;
                setValidMsg({ error: true, mobile });
            } else if(err?.response?.data?.error) {
                const case_name = err.response.data.error;
                setValidMsg({error: true, case_name })
            } else {
                setValidMsg({error: true, case_name: 'Failed to connect with server' })
            }
        }
    }

    useEffect(()=> {
            fetchClients();
    },[]) //eslint-disable-line

    useEffect(() => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
          ];
        let error = false;
        let client_name = '';
        let email = '';
        let mobile = '';
        let case_name = '';
        let client_id = '';

        if (clientType === 'new') {
            if (!modalData.client_name) {
                error = true;
                client_name = 'Client name is required'
            } else if (modalData.client_name.length < 3) {
                error = true;
                client_name = 'Client name should contain more than 2 Characters'
            }

            if (!modalData.email) {
                error = true;
                email = 'Email address is required'
            } else if (!emailPattern.test(modalData.email)) {
                error = true;
                email = 'Email address is invalid'
            }

            if (!modalData.mobile) {
                error = true;
                mobile = 'Mobile number is required'
            } else if (!mobilePatterns.some(pattern => pattern.test(modalData.mobile))) {
                error = true;
                mobile = 'Mobile number is invalid'
            }

        } else {
            if(!modalData.client_id) {
                error = true;
                client_id = 'Required to select a existing client'
            }
        }
        if (!modalData.case_name) {
            error = true;
            case_name = 'Case name is required'
        } else if (modalData.case_name < 3) {
            error = true;
            case_name = 'Case name should contain more than 2 characters'
        }

        setValidMsg({ error, client_name, email, mobile, case_name, client_id });

    }, [modalData, clientType, clients])

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            New Case
                        </h1>
                    </div>
                    <div className='modal-body m-auto comp-content'>
                        <span>Client :</span>
                        <select value={clientType} onChange={handleClientType} className='ms-2 select-client'>
                            <option value='new' >Add new client</option>
                            {clients.length && <option value='existing'>Existing client</option>}
                        </select>
                        {errFetch && <div className='error-message'>{errFetch}</div>}
                        {clientType === 'new' ? <>
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='client_name'
                                    value={modalData.client_name}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Client name' />
                                <label className='au-label'>Client name</label>
                            </div>
                            {touched && <div className='error-message'>{validMsg?.client_name}</div>}
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='email'
                                    value={modalData.email}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Email' />
                                <label className='au-label'>Email</label>
                            </div>
                            {!validMsg?.client_name && <div className='error-message'>{validMsg?.email}</div>}
                            <div className='au-field'>
                                <input
                                    className='au-input w-100 ps-2'
                                    type='text'
                                    name='mobile'
                                    value={modalData.mobile}
                                    onFocus={inputIsTouched}
                                    onChange={handleChange}
                                    placeholder='Mobile number' />
                                <label className='au-label'>Mobile number</label>
                            </div>
                            {!validMsg?.email && <div className='error-message'>{validMsg?.mobile}</div>}
                        </> : <>
                        <div>
                            <select
                                name='client_id'
                                value={modalData.client_id}
                                onChange={handleChange}
                                onFocus={inputIsTouched}
                                className='select-client mt-3'>
                                <option value=''>Select client</option>
                                {clients.map((client)=> <option key={client.id} value={client.id}>{client.name}</option>)}
                            </select>
                        </div>
                        {touched && <div className='error-message mt-2'>{validMsg?.client_id}</div>}
                        </>}
                        <div className='au-field'>
                            <input
                                className='au-input w-100 ps-2'
                                type='text'
                                name='case_name'
                                value={modalData.case_name}
                                onFocus={inputIsTouched}
                                onChange={handleChange}
                                placeholder='Case name' />
                            <label className='au-label'>Case name</label>
                        </div>
                        {!validMsg?.client_id && !validMsg.mobile && <div className='error-message'>{validMsg?.case_name}</div>}
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button disabled={validMsg.error} className={validMsg.error ? 'au-btn-disabled' : 'au-btn'} onClick={submitData}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewCaseModal