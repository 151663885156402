import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver';

const DownloadModal = (props) => {

    const { isOpen, closeModal, modalData, fetchRequestsData } = props;
    const [ status, setStatus ] = useState('Downloading...');

    const fetchFiles = async() => {
        try{
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/documents/download/${modalData.id}`);
            const files = response.data;
            files.forEach(file => {
                const { file_name, file_type } = file;
                const bufferData = file.file.content.data;
                const contentType = file.file.contentType;
                const uint8Array = new Uint8Array(bufferData);
                const blob = new Blob([uint8Array], { type: contentType});
                saveAs(blob, `${file_name}${file_type}`);
            });
            closeModal();
            fetchRequestsData();
        }catch(err){
            console.error('Error:', err);
            setStatus('Failed to download the document')
        }
    }

    useEffect(()=> {
        if(isOpen){
            fetchFiles();
        }
    },[isOpen]) //eslint-disable-line

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='mx-auto my-2 fw-bold'>{status}</div>
                    <div className='mx-auto my-2'><button className='au-btn' onClick={closeModal}>Close</button></div>
                </div>
            </div>
        </div>
    )
}

export default DownloadModal