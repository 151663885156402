import React, {useState} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './editLogoModal.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const EditLogoModal = (props) => {

    const { isOpen, closeModal, entityId, setNewLogo } = props;
    const [entityLogo, setEntityLogo] = useState(null);
    const [image, setImage] = useState(null);
    const [touched, setTouched] = useState(false);
    const [logoErrMsg, setLogoErrMsg] = useState(false);
    const [crop, setCrop] = useState({});
    const cropIsValid = crop.width > 0 && crop.height > 0;

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    if (img.width <= 500 && img.height <= 500) {
                        setImage(e.target.result);
                        setLogoErrMsg('');
                    } else {
                        setLogoErrMsg('Image dimensions are too large. Please select a smaller image (500 X 500)');
                    }
                };
            };
            reader.readAsDataURL(file);
        } else {
            setLogoErrMsg('Only JPEG Or PNG images are allowed');
        }
    }

    const getCroppedImage = (image, crop) => {
        const img = new Image();
        img.src = image;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio || 1;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.drawImage(
            img,
            crop.x * pixelRatio,
            crop.y * pixelRatio,
            crop.width * pixelRatio,
            crop.height * pixelRatio,
            0,
            0,
            crop.width * pixelRatio,
            crop.height * pixelRatio
        );
        return canvas.toDataURL('image/png');
    }

    const handleCropClick = () => {
        if (image) {
            const croppedDataURL = getCroppedImage(image, crop);
            setEntityLogo(croppedDataURL);
            setImage(null);
            setCrop({})
        }
    };

    const clearData = () => {
        closeModal();
        setEntityLogo(null);
        setImage(null);
        setTouched(false);
        setLogoErrMsg('');
    }

    const submitData = async () => {
        try{
            const api = axiosRequestInterceptor(true);
            await api.put(`/entities/editLogo/${entityId}`, { entityLogo });
            setNewLogo(entityLogo);
            closeModal();
            setEntityLogo(null);
            setImage(null);
            setTouched(false);
            setLogoErrMsg('');
        }catch(err){
            if(err?.response?.data?.error) setLogoErrMsg(err.response.data.error);
            else setLogoErrMsg('Failed to connect with server')
        }
    }

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            Edit Logo
                        </h1>
                    </div>
                    <div className='modal-body px-5'>
                        {!entityLogo ? <div>
                            <span>{`Upload logo of entity (Image dimension limit is 500px X 500px) `}</span>
                            {touched && (
                                <div className='error-message mt-2'>
                                    {logoErrMsg}
                                </div>
                            )}
                            <input
                                className='form-control'
                                type='file'
                                accept='image/*'
                                //value={entityData.logo}
                                onChange={handleChange}
                                onClick={()=> setTouched(true)}/>
                            {image && (
                                <div className='mt-2'>
                                    {!cropIsValid && <div className='error-message mt-2'>Select area of the image to crop</div>}
                                    <ReactCrop crop={crop} onImageLoaded={setEntityLogo} onChange={setCrop} aspect={1 / 1}>
                                        <img className='border' src={image} alt='logo' />
                                    </ReactCrop>
                                    <button disabled={!cropIsValid} className='ms-5 crop-btn p-2' onClick={handleCropClick}>Crop</button>
                                </div>
                            )}
                        </div> :
                            <div>
                                <div>Logo to be uploaded :</div>
                                <img className='border p-auto' src={entityLogo} alt='logo' />
                                <button className='ms-5 crop-btn p-2' onClick={()=> setEntityLogo(null)} >Delete</button>
                            </div>}
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button disabled={logoErrMsg || !entityLogo} className={logoErrMsg || !entityLogo ? 'ce-btn-disabled' : 'ce-btn'} onClick={submitData}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditLogoModal