import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const CollectionAccordian = (props) => {

    const { collectionData, openModal, userType } = props;
    const entityId = sessionStorage.getItem('SDX_entity_id');
    const [showRequests, setShowRequests] = useState(false);

    const toggleDocuments = () => {
        setShowRequests(prevState => !prevState)
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        openModal('Delete', collectionData);
    }

    const handleEditClick = (e) => {
        e.stopPropagation();
        openModal('Edit', collectionData);
    }

    const info = 'The collection is updated';

    return (
        <>
            <div onClick={toggleDocuments} className='d-flex py-2 hand-symbol border-bottom'>
                <span className='mx-5 col-4'>Collection name: <b>{collectionData.name}</b>
                    {collectionData.createdAt !== collectionData.updatedAt && <>
                        <FontAwesomeIcon
                            data-tooltip-id='collection-tooltip'
                            data-tooltip-content={info}
                            className='mb-2 ms-1 hand-symbol'
                            color='#2596be'
                            icon={faCircleInfo} />
                        <Tooltip id='collection-tooltip' place='top' effect='solid' /></>}
                </span>
                <span className='mx-5 col-4'>Number of documents: <b>{collectionData.requests.length}</b></span>
                <div className='w-25'>
                {(userType === 'admin' ? collectionData.entity_id === 'all' : entityId === collectionData.entity_id) && <>
                <FontAwesomeIcon onClick={handleEditClick} className='hand-symbol' icon={faPenToSquare} color='#208DA9' />
                <FontAwesomeIcon onClick={handleDeleteClick} className='ms-3 hand-symbol' icon={faTrash} color='#208DA9' />
                </>}</div>
                <FontAwesomeIcon className='me-2' icon={showRequests ? faChevronUp : faChevronDown} />
            </div>
            {showRequests && <div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Document name</th>
                            <th>Description</th>
                            <th>Type of request</th>
                        </tr>
                    </thead>
                    <tbody>
                        {collectionData?.requests?.map((req, index) => {
                            return (
                                <tr key={index}>
                                    <td>{req.doc_name}</td>
                                    <td>{req.description}</td>
                                    <td>{req.type_of_req}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}
        </>
    )
}

export default CollectionAccordian