import { faChevronLeft, faDownload, faEnvelope, faPaperclip, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { saveAs } from 'file-saver';
import React, { useState, useRef } from 'react';
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Backdrop from '../../components/Backdrop';
import SendMailModal from '../../components/SendMailModal';

const ViewProspectData = () => {

    const componentRef = useRef();
    const navigate = useNavigate();
    const propsData = useLocation();
    const prospectData = propsData.state;
    const selected = 'Prospects';
    const userType = 'entity';
    const api = axiosRequestInterceptor(true);
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [serverMsg, setServerMsg] = useState('');

    const goToProspectPage = () => {
        navigate('/prospects');
    }

    const downloadFile = async (file, file_name, fileType) => {
        try {
            const id = prospectData.id;
            const docId = prospectData.documents[file];
            const response = await api.get(`/prospects/download/${id}/${docId}/${fileType}`);
            const bufferData = response.data.file.content.data;
            const contentType = response.data.file.contentType;
            const uint8Array = new Uint8Array(bufferData);
            const blob = new Blob([uint8Array], { type: contentType });
            saveAs(blob, `${file_name}`);
        } catch (err) {
            console.error(err);
        }
    }

    const downloadWordFile = () => {
        const fileName = prospectData.prospect_type === 'purchaser' ? 'Purchaser_Details' : 'Vendor_Details';
        const fileType = 'docx';
        downloadFile(fileName, fileName, fileType);
    }

    const downloadOtherFile = (file) => {
        const [fileName, fileType] = prospectData?.data[file].split(/\.(?=[^.]+$)/);
        downloadFile(file, fileName, fileType);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const sendMail = async () => {
        try {
            setServerMsg('Sending the mail, please wait...');
            setModalIsOpen(true);
            const id = prospectData.id;
            await api.post('/prospects/mail', { id });
            setServerMsg('Mail has been sent to the admin');
        } catch (err) {
            setServerMsg('Failed to send mail, please try after some time');
            console.error(err);
        }
    }

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar userType={userType} collapsed={collapsed} />
                <div id='print-section' className='container-fluid scroll-window'>
                    <div className='m-3 d-flex align-items-center'>
                        <FontAwesomeIcon onClick={goToProspectPage} className='hand-symbol' icon={faChevronLeft} />
                        <span className='ms-3'>View Prospect Data</span>
                        <span className='ms-auto'>
                            <button onClick={sendMail} className='au-btn'><FontAwesomeIcon icon={faEnvelope} /> Email</button>
                            <button onClick={handlePrint} className='au-btn ms-3'><FontAwesomeIcon icon={faPrint} /> Print</button>
                            <button onClick={downloadWordFile} className='au-btn ms-3'><FontAwesomeIcon icon={faDownload} /> Download</button>
                            <button className='au-btn ms-3'><FontAwesomeIcon icon={faPaperclip} /> Link to Leap</button>
                        </span>
                    </div>
                    <div ref={componentRef} className='card px-5 pt-3 m-3'>
                        {prospectData.prospect_type === 'purchaser' && <div>
                          {prospectData?.data?.purchaserDetails?.map(pur => <div>
                            <div className='fw-bold mb-3'>{pur.purchaserIndex === 1 ? 'First' : pur.purchaserIndex === 2 ? 'Second' : pur.purchaserIndex === 3 ?
                            'Third' : pur.purchaserIndex === 4 ? 'Fourth' : 'Fifth'} Purchaser Details</div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Given Name :</span>
                                <b className='w-50'>{pur.given_name}</b>
                            </div>
                            {pur.middle_name && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Middle Name :</span>
                                <b className='w-50'>{pur.middle_name}</b>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{pur.surname}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Gender :</span>
                                <b className='w-50'>{pur.gender}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Different name used? </span>
                                <b className='w-50'>{pur.diff_name_used}</b>
                            </div>
                            {pur.diff_name_used_1 === 'Yes' && <><div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{pur.surname_diff}</b>
                            </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Given Name :</span>
                                    <b className='w-50'>{pur.given_name_diff}</b>
                                </div>
                                {pur.middle_name_diff_1 && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Middle Name: </span>
                                    <b className='w-50'>{pur.middle_name_diff}</b>
                                </div>}
                            </>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Address :</span>
                                <b className='w-50'>{pur.address}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Phone number :</span>
                                <b className='w-50'>{pur.phone}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Mobile number :</span>
                                <b className='w-50'>{pur.mobile}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Email : </span>
                                <b className='w-50'>{pur.email}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Date of birth :</span>
                                <b className='w-50'>{pur.dob}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Citizenship Details :</span>
                                <b className='w-50'>{pur.aus_res}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Country of Citizenship :</span>
                                <b className='w-50'>{pur.citizen}</b>
                            </div>
                            {pur.citizen_1 !== 'Australia' && <><div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Given Name as per Visa :</span>
                                <b className='w-50'>{pur.visa_name_1 ? pur.visa_name_1 : '-'}</b>
                            </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Date of Birth as per Visa :</span>
                                    <b className='w-50'>{pur.visa_dob_1 ? pur.visa_dob_1 : '-'}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Passport Number :</span>
                                    <b className='w-50'>{pur.passport_no_1 ? pur.passport_no_1 : '-'}</b>
                                </div>
                            </>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50 pe-4'>Do you have a spouse/partner that is not listed as a transferee in the contract to purchase this property? </span>
                                <b className='w-50'>{pur.not_listed_partner}</b>
                            </div>
                            {pur.not_listed_partner_1 === 'Yes' && <><div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{pur.surname_partner}</b>
                            </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Given Name :</span>
                                    <b className='w-50'>{pur.given_name_partner}</b>
                                </div>
                                {pur.middle_name_partner_1 && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Middle Name: </span>
                                    <b className='w-50'>{pur.middle_name_partner}</b>
                                </div>}
                            </>}
                            {pur.not_listed_partner_1 === 'Yes' && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Date of birth :</span>
                                <b className='w-50'>{pur.partner_dob}</b>
                            </div>}
                            <hr />
                          </div>)}
                          <div className='fw-bold mb-3'>Purchaser Property Details</div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Address of property being purchased :</span>
                                    <b className='w-50'>{prospectData?.data?.prop_address}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Name of real-estate agent :</span>
                                    <b className='w-50'>{prospectData?.data?.agent_name || '-'}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Is it an Off the Plan (Untitled Property) purchase? </span>
                                    <b className='w-50'>{prospectData?.data?.off_plan_purchase}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Are you Contract Purchaser? (Have you signed the contract) </span>
                                    <b className='w-50'>{prospectData?.data?.contract_purchaser}</b>
                                </div>
                                <hr />
                                <div className='fw-bold mb-3'>Ownership & Title rights</div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Do you have a Nominated Purchaser? </span>
                                    <b className='w-50'>{prospectData?.data?.nominated_purchaser}</b>
                                </div>
                                {prospectData?.data?.nominated_purchaser === 'Yes' && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Relationship with the Nominated Purchaser :</span>
                                    <b className='w-50'>{prospectData?.data?.nominated_purchaser_relationship}</b>
                                </div>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Do you have a Joint Purchaser? </span>
                                    <b className='w-50'>{prospectData?.data?.joint_purchaser}</b>
                                </div>
                                {prospectData?.data?.joint_purchaser === 'Yes' && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Relationship with the Joint Purchaser :</span>
                                    <b className='w-50'>{prospectData?.data?.joint_purchaser_relationship}</b>
                                </div>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>What should be your right in the title? </span>
                                    <b className='w-50'>{prospectData?.data?.right_in_the_title}</b>
                                </div>
                                <hr />
                                <div className='fw-bold mb-3'>Property Type and Details</div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Is it for residential or commercial purpose? </span>
                                    <b className='w-50'>{prospectData?.data?.purpose}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Is Owner’s corporation applicable? </span>
                                    <b className='w-50'>{prospectData?.data?.owner_corp_applicable}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Is it a house/vacant land/apartment? </span>
                                    <b className='w-50'>{prospectData?.data?.property_type}</b>
                                </div>
                                {(prospectData?.data?.property_type === 'House' || prospectData?.data?.property_type === 'Apartment') && <><div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Number of Bedrooms :</span>
                                    <b className='w-50'>{prospectData?.data?.no_bedrooms}</b>
                                </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Any building permits? </span>
                                        <b className='w-50'>{prospectData?.data?.building_permit}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Material of the bulding :</span>
                                        <b className='w-50'>{prospectData?.data?.material_of_building}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Is the house less than seven years old? </span>
                                        <b className='w-50'>{prospectData?.data?.less_than_seven_years}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Is building warranty insurance applicable? </span>
                                        <b className='w-50'>{prospectData?.data?.building_warranty_insurance_applicable}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Was the house built by the owner or through a registered builder? </span>
                                        <b className='w-50'>{prospectData?.data?.builder_registered}</b>
                                    </div></>}
                                {prospectData?.data?.property_type === 'Vacant Land' && <><div className='d-flex w-100 mb-3'>
                                    <span className='w-50 pe-4'>Do you intend to build a house and use it as your main residence within 1 year from the date of settlement? </span>
                                    <b className='w-50'>{prospectData?.data?.intend_to_build_house}</b>
                                </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Have you signed a building contract? </span>
                                        <b className='w-50'>{prospectData?.data?.signed_building_contract}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-50'>Are you claiming First Home Owners’ Grant? </span>
                                        <b className='w-50'>{prospectData?.data?.claim_first_home_owner}</b>
                                    </div>
                                </>}
                                <hr />
                                <div className='fw-bold mb-3'>Others</div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Was the house built by the owner builder? </span>
                                    <b className='w-25'>{prospectData?.data?.owner_house_built}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you made any deposit? </span>
                                    <b className='w-25'>{prospectData?.data?.initial_deposit_made}</b>
                                </div>
                                {prospectData?.data?.initial_deposit_made === 'Yes' && <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Amount paid as deposit (Australian Dollars) :</span>
                                    <b className='w-25'>{prospectData?.data?.deposit_amount_paid}</b>
                                </div>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Are you eligible for health care card/ pension card Stamp duty concession? </span>
                                    <b className='w-25'>{prospectData?.data?.eligible_for_health_card}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Is this your First Home? </span>
                                    <b className='w-25'>{prospectData?.data?.first_home}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Are you applying for a concession of exemption of stamp duty in relation to this transaction? </span>
                                    <b className='w-25'>{prospectData?.data?.applying_for_concession}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Do you intend to occupy the property as your principle place of residence (PPR)? </span>
                                    <b className='w-25'>{prospectData?.data?.occupy_as_ppr}</b>
                                </div>
                                {prospectData?.data?.occupy_as_ppr === 'Yes' && <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Intended date of occupation? </span>
                                    <b className='w-25'>{prospectData?.data?.date_of_occupation}</b>
                                </div>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you received the First Home Owner Grant (FHOG) for this property? </span>
                                    <b className='w-25'>{prospectData?.data?.present_fhog}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you previously owned residential property, either jointly or separately or with some other person before 1 July 2000 in any State or territory of Australia? </span>
                                    <b className='w-25'>{prospectData?.data?.prev_owned_prop}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you occupied a residential property for a continuous period for at least six months in which you acquired a relevant interest on or after 1 July 2000? </span>
                                    <b className='w-25'>{prospectData?.data?.occupied_res_prop_cont}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you previously received the FHOG in any state or territory of Australia? </span>
                                    <b className='w-25'>{prospectData?.data?.prev_fhog}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Are you a cash Purchaser? </span>
                                    <b className='w-25'>{prospectData?.data?.cash_purchaser}</b>
                                </div>
                                {prospectData?.data?.cash_purchaser === 'No' && <><div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Who is your Lender/ Bank ? </span>
                                    <b className='w-25'>{prospectData?.data?.bank}</b>
                                </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-75 pe-4'>Did you get final approval of your loan? </span>
                                        <b className='w-25'>{prospectData?.data?.approval_of_loan}</b>
                                    </div>
                                </>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Would you like to apply for title insurance? </span>
                                    <b className='w-25'>{prospectData?.data?.apply_title_insurance}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Is properties sold over $750,000.00? </span>
                                    <b className='w-25'>{prospectData?.data?.properties_sold_over}</b>
                                </div>
                                {prospectData?.data?.properties_sold_over === 'Yes' && <><div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Tax File Number :</span>
                                    <b className='w-25'>{prospectData?.data?.tax_file_number || '-'}</b>
                                </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-75 pe-4'>Has your residency status changed since your last Tax Return or will it change before you sell the property? </span>
                                        <b className='w-25'>{prospectData?.data?.residency_status_changed}</b>
                                    </div>
                                    <div className='d-flex w-100 mb-3'>
                                    <span className='w-75 pe-4'>Have you lodged a tax return in the last two years? </span>
                                    <b className='w-25'>{prospectData?.data?.lodged_tax_return}</b>
                                </div>
                                    <div className='d-flex w-100 mb-3'>
                                        <span className='w-75 pe-4'>Are you holding property on behalf of a foreign resident or on behalf of other entities that include a foreign resident? </span>
                                        <b className='w-25'>{prospectData?.data?.holding_property_behalf}</b>
                                    </div>
                                </>}
                                <div className='d-flex w-100 my-4'>
                                    <span>Date : <b className='ms-3'>{prospectData?.data?.filled_date}</b></span>
                                    <span className='ms-auto me-5'>Full Name :<b className='ms-2'>{prospectData?.data?.signature}</b></span>
                                </div>
                        </div>}
                        {prospectData.prospect_type === 'vendor' && <div>
                            {prospectData?.data?.vendorDetails?.map(ven => <div>
                                <div className='fw-bold mb-3'>{ven.vendorIndex === 1 ? 'First' : ven.vendorIndex === 2 ? 'Second' : ven.vendorIndex === 3 ?
                                    'Third' : ven.vendorIndex === 4 ? 'Fourth' : 'Fifth'} Vendor Details</div>
                                                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Given Name :</span>
                                <b className='w-50'>{ven.given_name}</b>
                            </div>
                            {ven.middle_name && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Middle Name :</span>
                                <b className='w-50'>{ven.middle_name}</b>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Surname :</span>
                                <b className='w-50'>{ven.surname}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Title :</span>
                                <b className='w-50'>{ven.title}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Different name used? </span>
                                <b className='w-50'>{ven.diff_name_used}</b>
                            </div>
                            {ven.diff_name_used === 'Yes' && <>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Given Name :</span>
                                    <b className='w-50'>{ven.given_name_diff}</b>
                                </div>
                                {ven.middle_name_diff_1 && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Middle Name :</span>
                                    <b className='w-50'>{ven.middle_name_diff}</b>
                                </div>}
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Surname :</span>
                                    <b className='w-50'>{ven.surname_diff}</b>
                                </div>
                            </>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Address :</span>
                                <b className='w-50'>{ven.address}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Phone number :</span>
                                <b className='w-50'>{ven.phone}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Mobile number :</span>
                                <b className='w-50'>{ven.mobile}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Email :</span>
                                <b className='w-50'>{ven.email}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Date of birth :</span>
                                <b className='w-50'>{ven.dob}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Citizenship Details :</span>
                                <b className='w-50'>{ven.aus_res}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Country of Citizenship :</span>
                                <b className='w-50'>{ven.citizen}</b>
                            </div>
                            {ven.citizen !== 'Australia' && <><div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Given Name as per Visa :</span>
                                <b className='w-50'>{ven.visa_name ? ven.visa_name : '-'}</b>
                            </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Date of Birth as per Visa :</span>
                                    <b className='w-50'>{ven.visa_dob ? ven.visa_dob : '-'}</b>
                                </div>
                                <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>Passport Number : </span>
                                    <b className='w-50'>{ven.passport_no ? ven.passport_no : '-'}</b>
                                </div>
                            </>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Are you acting behalf of a trust? :</span>
                                <b className='w-50'>{ven.behalf_trust}</b>
                            </div>
                            {ven.trust_name && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Trust Name :</span>
                                <b className='w-50'>{ven.trust_name}</b>
                            </div>}
                            <hr />
                            </div>)}
                            <div className='fw-bold mb-3'>Real Estate Listing Details</div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Name of real-estate agent :</span>
                                <b className='w-50'>{prospectData?.data?.agent_name}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Agent Mobile :</span>
                                <b className='w-50'>{prospectData?.data?.agent_mobile}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Direct email :</span>
                                <b className='w-50'>{prospectData?.data?.agent_email}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Name of the real estate Agency :</span>
                                <b className='w-50'>{prospectData?.data?.agency_name}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Address of the Agency :</span>
                                <b className='w-50'>{prospectData?.data?.agency_address}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Office Email :</span>
                                <b className='w-50'>{prospectData?.data?.agency_email}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Agency Phone :</span>
                                <b className='w-50'>{prospectData?.data?.agency_phone || '-'}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Address of property being purchased :</span>
                                <b className='w-50'>{prospectData?.data?.prop_address}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Title reference of the property for sale is known? </span>
                                <b className='w-50'>{prospectData?.data?.title_ref_known}</b>
                            </div>
                            {prospectData?.data?.volume && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Volume :</span>
                                <b className='w-50'>{prospectData?.data?.volume}</b>
                            </div>}
                            {prospectData?.data?.folio &&<div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Folio :</span>
                                <b className='w-50'>{prospectData?.data?.folio}</b>
                            </div>}
                            <hr />
                            <div className='fw-bold mb-3'>Property Details</div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is it a dwelling house/land/apartment/commercial? </span>
                                <b className='w-50'>{prospectData?.data?.property_type}</b>
                            </div>
                            {prospectData?.data?.property_type === 'Vacant Land' && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Do you require resale permission from the original vendor? </span>
                                <b className='w-50'>{prospectData?.data?.resale_permission}</b>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Does the sale price include GST? </span>
                                <b className='w-50'>{prospectData?.data?.sale_price_include_gst}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Number of Bedrooms :</span>
                                <b className='w-50'>{prospectData?.data?.no_bedrooms}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Material of the bulding :</span>
                                <b className='w-50'>{prospectData?.data?.material_of_building}</b>
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Do you have a swimming pool? </span>
                                <b className='w-50'>{prospectData?.data?.swimming_pool}</b>
                            </div>
                            {prospectData?.data?.swimming_pool_permit && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Swimming pool permit :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.swimming_pool_permit}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('swimming_pool_permit')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is the sale including all fixtures and any chattels? </span>
                                <b className='w-50'>{prospectData?.data?.fixtures_chattels}</b>
                            </div>
                            {prospectData?.data?.fixtures_chattels_list.length > 0 && <div className='mb-3 d-flex w-100'>
                                <span className='w-50'>List of all fixtures and chattels :</span>
                                <span className='w-50'>{prospectData?.data?.fixtures_chattels_list.map((item, index) => (<div key={index} className='me-4 fw-bold'>{item.fix}</div>))}</span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is there any special condition in the contract? </span>
                                <b className='w-50'>{prospectData?.data?.special_condition_mentioned}</b>
                            </div>
                            {prospectData?.data?.special_condition_mentioned === 'Yes' && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Special condition :</span>
                                <b className='w-50'>{prospectData?.data?.special_condition}</b>
                            </div>}
                            <hr />
                            <div className='fw-bold mb-3'>Others
                            </div>
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Mortgagee Bank is applicable? </span>
                                <b className='w-50'>{prospectData?.data?.mortgagee_bank}</b>
                            </div>
                            {prospectData?.data?.loan_account_no.length > 0 && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Loan Account Number/s :</span>
                                <span className='w-50'>
                                    {prospectData?.data?.loan_account_no.map((item, index) => (<div key={index} className='fw-bold'>{item.acnt}</div>))}
                                </span>
                            </div>}
                            {prospectData?.data?.council_rates && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the recent Council Rates :</span>
                                <span className='d-flex w-50'>
                                    <b className='me-2'>{prospectData?.data?.council_rates}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('council_rates')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            {prospectData?.data?.water_bill && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the recent Water bill of the property :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.water_bill}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('water_bill')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Is property more than seven years old? </span>
                                <b className='w-50'>{prospectData?.data?.seven_years_old}</b>
                            </div>
                            {prospectData?.data?.building_permit && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the building permit :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.building_permit}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('building_permit')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                                {prospectData?.data?.occupancy_certificate && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>A copy of the occupancy certificate :</span>
                                    <span className='w-50 d-flex'>
                                        <b className='me-2'>{prospectData?.data?.occupancy_certificate}</b>
                                        <FontAwesomeIcon onClick={() => downloadOtherFile('occupancy_certificate')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                    </span>
                                </div>}
                                {prospectData?.data?.building_warranty_insurance && <div className='d-flex w-100 mb-3'>
                                    <span className='w-50'>A copy of the building warranty Insurance :</span>
                                    <span className='w-50 d-flex'>
                                        <b className='me-2'>{prospectData?.data?.building_warranty_insurance}</b>
                                        <FontAwesomeIcon onClick={() => downloadOtherFile('building_warranty_insurance')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                    </span>
                                </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Any additional constructions were made within last 7 years? </span>
                                <b className='w-50'>{prospectData?.data?.additional_buildings}</b>
                            </div>
                            {prospectData?.data?.additional_buildings_approvals && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Copy of building approvals :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.additional_buildings_approvals}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('additional_buildings_approvals')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Any building permits issued in the last 7 years? </span>
                                <b className='w-50'>{prospectData?.data?.building_permit_issued}</b>
                            </div>
                            {prospectData?.data?.building_permit_latest && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of buidling permit issued in the last 7 years :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.building_permit_latest}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('building_permit_latest')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Any construction completed by owner builder in the last 6 years? </span>
                                <b className='w-50'>{prospectData?.data?.construction_by_owner}</b>
                            </div>
                            {prospectData?.data?.owner_builder_inspection && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Builder inspection report under section 137B of the building act :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.owner_builder_inspection}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('owner_builder_inspection')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            {prospectData?.data?.warranty_insurance_owner && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Building warranty insurance if applicable :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.warranty_insurance_owner}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('warranty_insurance_owner')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>Property is tenanted? </span>
                                <b className='w-50'>{prospectData?.data?.property_tenanted}</b>
                            </div>
                            {prospectData?.data?.lease_agreement && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>A copy of the lease agreement :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.lease_agreement}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('lease_agreement')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>OCC is applicable to your property? </span>
                                <b className='w-50'>{prospectData?.data?.occ_applicable}</b>
                            </div>
                            {prospectData?.data?.oc_management_details && <div className='d-flex w-100 mb-3'>
                                <span className='w-50'>OC management details to apply for the certificate :</span>
                                <span className='w-50 d-flex'>
                                    <b className='me-2'>{prospectData?.data?.oc_management_details}</b>
                                    <FontAwesomeIcon onClick={() => downloadOtherFile('oc_management_details')} className='ms-auto hand-symbol hide-on-print' icon={faDownload} />
                                </span>
                            </div>}
                            <div className='d-flex w-100 my-4'>
                                <span>Date :<b className='ms-3'>{prospectData?.data?.filled_date}</b></span>
                                <span className='ms-auto me-5'>Full Name :<b>{prospectData?.data?.signature}</b></span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {modalIsOpen && <>
                <Backdrop isOpen={modalIsOpen} />
                <SendMailModal
                    isOpen={modalIsOpen}
                    serverMsg={serverMsg}
                    closeModal={closeModal}
                    />
            </>}
        </div>
    )
}

export default ViewProspectData