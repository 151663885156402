import React, { useState } from 'react'
import './userTable.css';
import moment from 'moment';
import Backdrop from '../Backdrop';
import ModifyUserModal from '../ModifyUserModal';

const UserTable = (props) => {

    const { users, fetchUsers, editEntity } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const userId = sessionStorage.getItem('SDX_user_id');

    const openModal = (user, action) => {
        setModalIsOpen(true);
        setModalData({ user, action });
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            <table className='users-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>User role</th>
                        <th>Status</th>
                        <th>Last logged in</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='users-count'>
                        <td colSpan={7}>
                            {`Showing ${users.length} of ${users.length} total Users`}
                        </td>
                    </tr>
                    {users?.map((user) => {
                        let relativeTime = '';
                        if (!user.last_logged_in) {
                            relativeTime = 'not logged in yet'
                        } else {
                            const lastLoggedInTimestamp = user.last_logged_in;
                            relativeTime = moment(lastLoggedInTimestamp).fromNow();
                        }
                        return (
                            <tr key={user.id} style={user.status === 'Deactivated' ? { color: 'gray' } : null}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.role === 'system_super_admin' || user.role === 'entity_super_admin' ?
                                    <span className='badge rounded-pill text-bg-danger'>Admin</span> :
                                    user.role === 'system_admin' || user.role === 'entity_admin' ?
                                        <span className={`badge rounded-pill ${user.status === 'Deactivated' ? 'text-bg-secondary' : 'text-bg-primary'}`}>Admin</span> :
                                        <span className={`badge rounded-pill ${user.status === 'Deactivated' ? 'text-bg-secondary' : 'text-bg-success'}`}>User</span>
                                }</td>
                                <td>{user.status}</td>
                                <td>{relativeTime}</td>
                                <td>
                                    {userId !== user.id && user.role !== 'system_super_admin' && !editEntity && user.role !== 'entity_super_admin' && <div className='dropdown'>
                                        <button className='btn dropdown-toggle' type='button' data-bs-toggle='dropdown'>
                                            More options
                                        </button>
                                        <ul className='dropdown-menu'>
                                            <li className='dropdown-item more-options' onClick={() => openModal(user, 'Modify User Status')}>{user.status === 'Deactivated' ? 'Activate User' : 'Deactivate User'}</li>
                                            {user.status !== 'Deactivated' &&<>
                                                <li className='dropdown-item more-options' onClick={() => openModal(user, 'Modify User Roles')}>Modify role</li>
                                                <li className='dropdown-item more-options' onClick={() => openModal(user, 'Mail Link')}>Mail password reset link</li>
                                            </>}
                                        </ul>
                                    </div>}
                                    {user.role === 'entity_super_admin' && editEntity && 
                                    <button className='btn' onClick={() => openModal(user, 'Mail Link')}>Mail password reset link</button>}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Backdrop isOpen={modalIsOpen} />
            {modalIsOpen && <ModifyUserModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                modalData={modalData}
                fetchUsers={fetchUsers} />}
        </>
    )
}

export default UserTable