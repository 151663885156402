import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const DocTypeRow = (props) => {

  const { userType, documentData, openModal } = props;
  const entityId = sessionStorage.getItem('SDX_entity_id');

  const handleDelete = () => {
    openModal('Delete', documentData);
  }

  const handleEdit = () => {
    openModal('Edit', documentData);
  }

  return (
    <tr>
      <td>{documentData.doc_name}</td>
      <td>{documentData.description}</td>
      <td>{documentData.type_of_req}</td>
      <td>{documentData.created_by}</td>
      <td>
      {(userType === 'admin' ? documentData.entity_id === 'all' : entityId === documentData.entity_id) && <>
        <FontAwesomeIcon onClick={handleEdit} className='hand-symbol' icon={faPenToSquare} color='#208DA9' />
        <FontAwesomeIcon onClick={handleDelete} className='ms-2 hand-symbol' icon={faTrash} color='#208DA9' /></>}
      </td>
    </tr>
  )
}

export default DocTypeRow