import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver';
import FileViewer from '../FileViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faChevronDown, faChevronUp, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';

const ReviewDocModal = (props) => {
  const { isOpen, closeModal, modalData, caseTitle, clientId, fetchCaseRequests, fetchCasesData } = props;
  const api = axiosRequestInterceptor(true);
  const user_name = sessionStorage.getItem('SDX_user_name');
  const [documentsData, setDocumentsData] = useState([]);
  const [remHistory, setRemHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorDoc, setErrorDoc] = useState('');
  const [viewFile, setViewFile] = useState({ file: null, view: false });
  const [remarks, setRemarks] = useState('');
  const [historyShow, setHistoryShow] = useState(false);
  const [remError, setRemError] = useState('Remarks is required');
  const [confirmSubmit, setConfirmSubmit] = useState({ action: '', confirm: false });

  const fetchAllDoc = async () => {
    try {
      setLoading(true);
      setErrorDoc('');
      const response = await api.get(`/documents/getAllDoc/${modalData.id}/${clientId}`);
      setDocumentsData(response.data.documents);
      setRemHistory(response.data.remarksData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if(err?.response?.data?.error) setErrorDoc(err.response.data.error)
      else setErrorDoc('Failed to connect with server')
    }
  }

  const viewSelectedFile = (file) => {
    const bufferData = file.file.content.data;
    const contentType = file.file.contentType;
    const uint8Array = new Uint8Array(bufferData);
    const blob = new Blob([uint8Array], { type: contentType });
    setViewFile({ file: blob, view: true });
  }

  const downloadSelectedFile = (file) => {
    const { file_name, file_type } = file;
    const bufferData = file.file.content.data;
    const contentType = file.file.contentType;
    const uint8Array = new Uint8Array(bufferData);
    const blob = new Blob([uint8Array], { type: contentType });
    saveAs(blob, `${file_name}${file_type}`);
  }

  const handleRemarks = (e) => {
    setRemarks(e.target.value);
    if (e.target.value.length === 0) {
      setRemError('Remarks is required')
    } else if (e.target.value.length < 2) {
      setRemError('Remarks should contain more than 1 character');
    } else {
      setRemError('');
    }
  }

  const handleHistory = () => {
    setHistoryShow(prevState => !prevState);
  }

  const clearData = () => {
    closeModal();
    setRemarks('');
  }

  const toggleConfirm = (action) => {
    if (action) {
      setConfirmSubmit({ action, confirm: true });
    } else {
      setConfirmSubmit({ action, confirm: false });
    }
  }

  const submitData = async () => {
    try {
      await api.post('/documents/review', { req_id: modalData.id, status: confirmSubmit.action, remarks, reviewed_by: user_name });
      fetchCaseRequests();
      fetchCasesData();
      clearData();
      setConfirmSubmit({ action: '', confirm: false });
    } catch (err) {
      console.error(err);
      if(err?.response?.data?.error) setRemError(err.response.data.error);
      else setRemError('Failed to connect with server');
      setConfirmSubmit({ action: '', confirm: false });
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchAllDoc();
    }
  }, [isOpen]) //eslint-disable-line

  return (
    <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          {viewFile.view ?
            <FileViewer
              file={viewFile.file}
              setViewFile={setViewFile} /> : <>
              <div className='modal-header'>
                <h1 className='modal-title fs-5'>
                  {caseTitle}
                  <FontAwesomeIcon icon={faAnglesRight} />
                  {modalData?.doc_name}
                </h1>
              </div>
              <div className='modal-body px-5'>
                {confirmSubmit.confirm ? <div className='action-content'>Are you sure you want to {confirmSubmit.action} the {modalData?.doc_name}?</div> :
                  <>
                    {loading ? <div className='no-data-banner fw-bold pt-5'>Loading...</div> :
                      errorDoc ? <div className='no-data-banner fw-bold pt-5'>{errorDoc}</div> :
                        documentsData.length === 0 ? <div className='no-data-banner fw-bold pt-5'>There is no document uploaded yet</div> :
                          <table className='users-table'>
                            <thead>
                              <tr>
                                <th className='table-head'>File name</th>
                                <th className='table-head'>File type</th>
                                <th className='table-head'>Date uploaded</th>
                                <th className='table-head'>Uploaded by</th>
                                <th className='table-head'>Status</th>
                                <th className='table-head'>Action</th>
                              </tr>
                            </thead>
                            <tbody className='table-body'>
                              {documentsData?.map((file) => {
                                const options = {
                                  day: 'numeric',
                                  month: 'numeric',
                                  year: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true
                                }
                                const createdAtDate = new Date(file.createdAt);
                                const uploaded_date = createdAtDate.toLocaleString('en-AU', options);
                                let statusColor = '';
                                if (file.status === 'Accepted' || file.status === 'Downloaded') {
                                  statusColor = 'green'
                                } else if (file.status === 'Rejected') {
                                  statusColor = 'red'
                                } else {
                                  statusColor = 'blue'
                                }
                                return (
                                  <tr key={file.id}>
                                    <td>{file.file_name}</td>
                                    <td>{file.file_type}</td>
                                    <td>{uploaded_date}</td>
                                    <td>{file.uploaded_by}</td>
                                    <td style={{color: statusColor}}>{file.status}</td>
                                    <td>
                                      <FontAwesomeIcon onClick={() => viewSelectedFile(file)} icon={faEye} className='hand-symbol me-2' size='lg' />
                                      <FontAwesomeIcon onClick={() => downloadSelectedFile(file)} className='hand-symbol' icon={faDownload} />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>}
                    <div className='req-text-area-field mt-4'>
                      <textarea
                        value={remarks}
                        onChange={handleRemarks}
                        className='ps-1 w-100 req-text-area'
                        placeholder='Remarks' />
                      <label className='req-text-area-label'>Remarks</label>
                    </div>
                    <div className='error-message'>{remError}</div>
                    <div className='mt-2 rem-history-title' onClick={handleHistory}>
                      Remarks history
                      <FontAwesomeIcon className='ms-2' icon={historyShow ? faChevronUp : faChevronDown} />
                    </div>
                    {historyShow && <div>
                      {remHistory?.length === 0 ? <div className='mt-4'>No remarks added yet</div> :
                        <table className='users-table'>
                          <thead>
                            <tr>
                              <th className='table-head'>Added by</th>
                              <th className='table-head'>Remarks</th>
                              <th className='table-head'>Date</th>
                            </tr>
                          </thead>
                          <tbody className='table-body'>
                            {remHistory?.map((rem, index) => {
                              const options = {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                              }
                              const createdAtDate = new Date(rem.createdAt);
                              const added_date = createdAtDate.toLocaleString('en-AU', options);
                              return (
                                <tr key={index}>
                                  <td>{rem.added_by}</td>
                                  <td>{rem.remark}</td>
                                  <td>{added_date}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>}
                    </div>}
                  </>}
              </div>
              <div className='modal-footer'>
                {confirmSubmit.confirm ? <>
                  <button
                    className='au-btn'
                    onClick={() => toggleConfirm('')}>
                    Cancel</button>
                  <button
                    className='au-btn'
                    onClick={submitData}>
                    Confirm</button>
                </> :
                  <>
                    <button
                      className='au-btn'
                      onClick={clearData}>
                      Cancel</button>
                    <button
                      disabled={modalData?.status !== 'Uploaded from Client' || loading || errorDoc || remError}
                      className={modalData?.status !== 'Uploaded from Client' || loading || errorDoc || remError ? 'au-btn-disabled' : 'au-btn'}
                      onClick={() => toggleConfirm('accept')}>
                      Accept</button>
                    <button
                      disabled={modalData?.status !== 'Uploaded from Client' || loading || errorDoc || remError}
                      className={modalData?.status !== 'Uploaded from Client' || loading || errorDoc || remError ? 'au-btn-disabled' : 'au-btn'}
                      onClick={() => toggleConfirm('reject')}>
                      Reject</button>
                  </>}
              </div>
            </>}
        </div>
      </div>
    </div>
  )
}

export default ReviewDocModal