import React from 'react';
import LogoutInfo from '../pages/LogoutInfo';

const ProtectedRoute = ({ component: Component }) => {
  const token = sessionStorage.getItem('SDX_token');

  if(token){
    return <Component />;
  }else{
    return <LogoutInfo />;
  }
};

export default ProtectedRoute;
