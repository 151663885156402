import React, { useEffect, useState } from 'react'
import './modifyCollectionModal.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const ModifyCollectionModal = (props) => {

    const initialState = {
        name: '',
        requests: [
            {
                doc_name: '',
                description: '',
                type_of_req: ''
            }
        ]
    }
    const api = axiosRequestInterceptor(true);
    const userName = sessionStorage.getItem('SDX_user_name');
    const { isOpen, closeModal, existingData, fetchCollectionData } = props;
    const [modalData, setModalData] = useState(initialState);
    const [validMsg, setValidMsg] = useState({ error: true, requests: [] });
    const [docTypes, setDocTypes] = useState([]);

    const fetchAllDocTypes = async (input, reqIndex) => {
        try {
          const response = await api.get(`/doc_types/data/${input}`)
          const tempData = [...docTypes];
          tempData[reqIndex] = response.data;
          setDocTypes(tempData);
        } catch (err) {
          console.error(err)
        }
      }

      const loadDocTypeData = (req, reqIndex) => {
        const tempReq = [...modalData.requests];
        tempReq[reqIndex] = { doc_name: req.doc_name, description: req.description, type_of_req: req.type_of_req }
        const tempData = {name: modalData.name, requests: tempReq}
        setModalData(tempData);
        const tempDocType = [...docTypes];
        tempDocType[reqIndex] = [];
        setDocTypes(tempDocType);
      }

      const resetOtherSuggestions = (e, reqIndex) => {
        if(e.target.name === 'doc_name'){
            const tempDocType = docTypes.map((reqs, index)=> {
                if(index === reqIndex) return reqs;
                else return [];
            })
            setDocTypes(tempDocType);
        }else {
            const tempDocType = docTypes.map(()=> [])
            setDocTypes(tempDocType);
        }
      }

    const handleChange = (e, reqIndex) => {
        if (e.target.name === 'name') {
            const tempData = { name: e.target.value, requests: modalData.requests }
            setModalData(tempData);
        } else {
            const updatedRequests = [...modalData.requests];
            updatedRequests[reqIndex][e.target.name] = e.target.value;
            const tempData = { ...modalData, requests: updatedRequests }
            setModalData(tempData);
        }
    }

    const addMoreReq = () => {
        const tempReq = [...modalData.requests, { doc_name: '', description: '', type_of_req: '' }]
        setModalData({ ...modalData, requests: tempReq })
        const tempArr = [...docTypes, []];
        setDocTypes(tempArr);
    }

    const removeRequest = (reqIndex) => {
        const tempData = modalData.requests.filter((req, index) => index !== reqIndex);
        setModalData({ ...modalData, requests: tempData });
        const tempArr = docTypes.filter((req, index) => index !== reqIndex);
        setDocTypes(tempArr);
    }

    const preReqIsVal = (reqIndex) => {
        if (validMsg?.requests[reqIndex]?.doc_name) {
            return false
        } else if (validMsg?.requests[reqIndex]?.description) {
            return false
        } else if (validMsg?.requests[reqIndex]?.type_of_req) {
            return false
        }
        return true
    }

    const clearData = () => {
        closeModal();
        setModalData(initialState);
        setDocTypes([]);
    }

    const newCollection = async () => {
        try {
            await api.post(`/collections/create`, {
                name: modalData.name,
                requests: modalData.requests,
                created_by: userName
            })
            fetchCollectionData();
            clearData();
        } catch (err) {
            console.error(err)
            if(err?.response?.data.name){
                setValidMsg({ error: true, requests: [], name: err.response.data.name });
            }else if(err?.response?.data?.error){
                setValidMsg({ error: true, requests: [], server: err.response.data.error });
            }else{
                setValidMsg({ error: true, requests: [], server: 'Failed to connect with server' });
            }
        }
    }

    const editCollection = async () => {
        try {
            await api.put(`/collections/edit`, {
                id: existingData.id,
                name: modalData.name,
                requests: modalData.requests,
                created_by: userName
            })
            fetchCollectionData();
            clearData();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, server: err.response.data.error });
            else setValidMsg({ error: true, server: 'Failed to connect with server' });
        }
    }

    const deleteCollection = async () => {
        try {
            await api.delete(`/collections/delete/${existingData.id}`);
            fetchCollectionData();
            clearData();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, server: err.response.data.error });
            else setValidMsg({ error: true, server: 'Failed to connect with server' });
        }
    }

    const submitData = () => {
        if (existingData.action === 'New') {
            newCollection();
        }
        else if (existingData.action === 'Edit') {
            editCollection();
        } else {
            deleteCollection();
        }
    }

    useEffect(() => {
        if (existingData?.action === 'Edit') {
            const tempReq = existingData?.requests?.map((req)=> {
                return {...req}
            })
            setModalData({ name: existingData.name, requests: tempReq });
        } else {
            setModalData(initialState);
        }
    }, [existingData, isOpen]) //eslint-disable-line

    useEffect(() => {
        let error = false;
        let name = '';
        let requests = [];

        if (!modalData?.name) {
            error = true;
            name = 'Collection name is required'
        } else if (modalData?.name.length < 3) {
            error = true;
            name = 'Collection name should contain more than 2 characters'
        }

        modalData?.requests?.forEach((req) => {
            let doc_name = '';
            let description = '';
            let type_of_req = '';
            if (!req.doc_name) {
                error = true;
                doc_name = 'Document name is required'
            } else if (req.doc_name.length < 3) {
                error = true;
                doc_name = 'Document name must contain more than 2 letters'
            }

            if (!req.description) {
                error = true;
                description = 'Description is required'
            } else if (req.description.length < 3) {
                error = true;
                description = 'Description must contain more than 2 letters'
            }

            if (!req.type_of_req) {
                error = true;
                type_of_req = 'Type is required'
            }

            requests.push({ doc_name, description, type_of_req });
        })

        setValidMsg({ error, name, requests });

    }, [modalData])

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            {existingData?.action} Collection
                        </h1>
                    </div>
                    <div className='modal-body'>
                        {existingData?.action === 'Delete' ? <div>
                            Are you sure you want to delete the collection {existingData?.name} ?
                        </div> : <>
                            <div className='d-flex mt-3'>
                                <span className='mt-2'>Collection Name:</span>
                                <div className='au-field mt-1 mx-1'>
                                    <input
                                        className='au-input ps-2'
                                        type='text'
                                        name='name'
                                        value={modalData?.name || ''}
                                        onFocus={(e)=> resetOtherSuggestions(e)}
                                        onChange={(e) => handleChange(e, '')}
                                        placeholder='Collection name'
                                        autoComplete='off'/>
                                    <label className='au-label'>Collection name</label>
                                    <div className='error-message mt-1'>{validMsg?.name}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-3 bg-light'>
                                <span className='col-md-3 fw-bold'>Document Name</span>
                                <span className='col-md-5 ms-2 fw-bold'>Description</span>
                                <span className='col-md-3 ms-3 fw-bold'>Request Type</span>
                                <button onClick={addMoreReq} className='add-more-req-btn'> + </button>
                            </div>
                            {modalData?.requests?.map((req, reqIndex) => {
                                return (
                                    <div key={reqIndex}>
                                        <div className='row'>
                                            <div className='au-field col-md-3 mt-3'>
                                                <input
                                                    className='au-input ps-2'
                                                    type='text'
                                                    name='doc_name'
                                                    value={req?.doc_name || ''}
                                                    onFocus={(e)=> resetOtherSuggestions(e, reqIndex)}
                                                    onChange={async (e) => {
                                                        await handleChange(e, reqIndex)
                                                        await fetchAllDocTypes(e.target.value, reqIndex)
                                                    }}
                                                    placeholder='Document name'
                                                    autoComplete='off' />
                                                {docTypes[reqIndex]?.length ? <div className='suggestion-list mt-2'>
                                                    {docTypes[reqIndex].map((req) => (
                                                        <div className='px-2' key={req.id}
                                                            onClick={() => loadDocTypeData(req, reqIndex)}
                                                        >
                                                            {req.doc_name}
                                                        </div>
                                                    ))}</div> : null}
                                                <label className='au-label'>Document name</label>
                                                {((reqIndex === 0 && !validMsg?.name) || (reqIndex !== 0 && preReqIsVal(reqIndex - 1))) &&
                                                    <div className='error-message mt-1 mb-2'>{validMsg?.requests[reqIndex]?.doc_name}
                                                    </div>}
                                            </div>
                                            <div className='au-field col-md-5 mt-3'>
                                                <input
                                                    className='au-input ps-2'
                                                    type='text'
                                                    name='description'
                                                    value={req?.description || ''}
                                                    onFocus={(e)=> resetOtherSuggestions(e, reqIndex)}
                                                    onChange={(e) => handleChange(e, reqIndex)}
                                                    placeholder='Description' />
                                                <label className='au-label'>Description</label>
                                                {!validMsg?.requests[reqIndex]?.doc_name && <div className='error-message mt-1'>
                                                    {validMsg?.requests[reqIndex]?.description}</div>}
                                            </div>
                                            <div className='col-md-3 mt-3'>
                                                <select
                                                    name='type_of_req'
                                                    value={req.type_of_req}
                                                    onFocus={(e)=> resetOtherSuggestions(e, reqIndex)}
                                                    onChange={(e) => handleChange(e, reqIndex)}
                                                    className='ms-2 type-box'>
                                                    <option value='' disabled>Select type</option>
                                                    <option value='Upload' >Upload from client</option>
                                                    <option value='Download & Upload'>Download & upload</option>
                                                    <option value='Download'>Download for client</option>
                                                </select>
                                                {!validMsg?.requests[reqIndex]?.description && <div className='error-message mt-1'>
                                                    {validMsg?.requests[reqIndex]?.type_of_req}</div>}
                                            </div>
                                            <div className='col-md-1 mt-3'>
                                                {modalData?.requests?.length !== 1 && 
                                                <FontAwesomeIcon onClick={() =>  removeRequest(reqIndex)} className='hand-symbol mt-2' icon={faTrash} color='#208DA9' size='lg' />}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </>}
                        <div className='error-message mt-1'>{validMsg?.server}</div>
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button
                            disabled={validMsg.error && existingData?.action !== 'Delete'}
                            className={validMsg.error && existingData?.action !== 'Delete' ? 'au-btn-disabled' : 'au-btn'}
                            onClick={submitData}>
                            {existingData?.action === 'New' ? 'Submit' :
                                existingData?.action === 'Edit' ? 'Update' :
                                    'Delete'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModifyCollectionModal