import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const LogoutInfo = () => {

    const navigate = useNavigate();
    const link = useLocation();
    const { userId } = useParams();

    const gotoLoginPage = async() => {
        const api = axiosRequestInterceptor(false);
        if(link.pathname.includes('/client_dashboard/')){           
            try{
                const response = await api.get(`/clients/token/${userId}`);
                navigate(`/verify/client/${response.data.entity_id}/${response.data.token}`)
            }catch(err){
                console.error(err);
            }
        } else if(link.pathname.includes('/prospect/')){
            try{
                const response = await api.get(`/prospects/token/${userId}`);
                navigate(`/verify/${response.data.prospect_type}/${response.data.entity_id}/${response.data.token}`)
            }catch(err){
                console.error(err);
            }
        }else{
            navigate('/login')
        }
    }

    return (
        <div className='error-container'>
            <h2>You Are Logged Out</h2>
            <p>Since you have logged out, you cannot visit this page, to continue please login again</p>
            <button onClick={gotoLoginPage} className='au-btn'>Login</button>
        </div>
    )
}

export default LogoutInfo