import React, { useEffect, useState } from 'react';
import './createEntityModal.css';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const CreateEntityModal = (props) => {

    const { isOpen, closeModal, fetchEntityData } = props;
    const initialState = {
        name: '',
        abn: '',
        acn: '',
        spoc_name: '',
        spoc_designation: '',
        spoc_mobile: '',
        email: '',
        address: '',
        pincode: '',
        status: 'Trial Period',
        logo: '',
        website: ''
    }
    const [validMsg, setValidMsg] = useState({ error: true });
    const [entityData, setEntityData] = useState(initialState);
    const [entityLogo, setEntityLogo] = useState(null);
    const [logoErrMsg, setLogoErrMsg] = useState('');
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let tempData = null
        if(name === 'spoc_mobile'){
            let tempMob = '';
            if(value[0] === '+'){
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 4 || value.length === 8 || value.length === 12) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`;
                } else {
                    tempMob = value;
                }
            } else {
                if(value[value.length - 1] === ' '){
                    tempMob = value.slice(0, -1);
                } else if(value.length === 5 || value.length === 9 ) {
                    tempMob = `${value.slice(0, -1)} ${value[value.length - 1]}`
                } else {
                    tempMob = value;
                }
            }
            tempData = {
                ...entityData,
                [name]: tempMob
            }
        } else {
            tempData = {
                ...entityData,
                [name]: value
            }
        }
        setEntityData(tempData);
        if (name === 'logo') {
            const file = e.target.files[0];
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.width <= 500 && img.height <= 500) {
                            setImage(e.target.result);
                            setLogoErrMsg('');
                        } else {
                            setLogoErrMsg('Image dimensions are too large. Please select a smaller image (500 X 500)');
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                setLogoErrMsg('Only JPEG or PNG images are allowed');
            }
        }
    }

    const handleCropClick = () => {
        if (image) {
            const croppedDataURL = getCroppedImage(image, crop);
            setEntityLogo(croppedDataURL);
            setImage(null);
            setCrop({});
        }
    };

    const cropIsValid = crop.width > 0 && crop.height > 0;

    const getCroppedImage = (image, crop) => {
        const img = new Image();
        img.src = image;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio || 1;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.drawImage(
            img,
            crop.x * pixelRatio,
            crop.y * pixelRatio,
            crop.width * pixelRatio,
            crop.height * pixelRatio,
            0,
            0,
            crop.width * pixelRatio,
            crop.height * pixelRatio
        );
        return canvas.toDataURL('image/png');
    }

    const deleteLogo = () => {
        const tempData = { ...entityData, logo: '' };
        setEntityData(tempData);
        setEntityLogo(null);
    }

    const clearData = () => {
        setEntityData(initialState);
        setImage(null);
        setEntityLogo(null);
        closeModal();
    }

    const submitData = async () => {
        try {
            let logo = entityLogo ? 'yes' : 'no';
            setSubmitting(true);
            const api = axiosRequestInterceptor(true);
            await api.post(`/entities/create`, { ...entityData, entityLogo, logo});
            setSubmitting(false);
            fetchEntityData();
            clearData();
        } catch (err) {
            if (err?.response?.data?.email) {
                let email = err.response.data.email;
                setValidMsg({ error: true, email });
            } else if(err?.response?.data?.error) {
                setErrMsg(err.response.data.error);
            } else {
                setErrMsg('Failed to connect with server');
            }
            setSubmitting(false);
        }
    }

    useEffect(() => {
        setErrMsg('');
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const websitePattern = /\w{3,}\.\w{2,}/;
        const mobilePatterns = [
            /^\+61 4\d{2} \d{3} \d{3}$/, // +61 4XX XXX XXX
            /^\+61 5\d{2} \d{3} \d{3}$/, // +61 5XX XXX XXX
            /^04\d{2} \d{3} \d{3}$/,    // 04XX XXX XXX
            /^05\d{2} \d{3} \d{3}$/     // 05XX XXX XXX
          ];
        let error = false;
        let name = '';
        let abn = '';
        let acn = '';
        let spoc_name = '';
        let spoc_designation = '';
        let spoc_mobile = '';
        let email = '';
        let address = '';
        let pincode = '';
        let website ='';

        if (!entityData.name) {
            error = true;
            name = 'Entity name is required';
        } else if (entityData.name.length < 3) {
            error = true;
            name = 'Entity name should contain more than 2 letters'
        }

        if (!entityData.abn) {
            error = true;
            abn = 'ABN is required'
        } else if (!/^[0-9]*$/.test(entityData.abn)) {
            error = true;
            abn = 'ABN should contain only numbers'
        } else if (entityData.abn.length !== 11) {
            error = true;
            abn = 'ABN should contain 11 digits'
        }

        if (!entityData.acn) {
            error = true;
            acn = 'ACN is required'
        } else if (!/^[0-9]*$/.test(entityData.acn)) {
            error = true;
            acn = 'ACN should contain only numbers';
        } else if (entityData.acn.length !== 9) {
            error = true;
            acn = 'ACN should contain 9 digits';
        }

        if (!entityData.spoc_name) {
            error = true;
            spoc_name = 'Primary Contact is required'
        } else if (entityData.spoc_name.length < 3) {
            error = true;
            spoc_name = 'Primary Contact should contain more than 2 letters';
        }

        if (!entityData.spoc_designation) {
            error = true;
            spoc_designation = 'Primary Contact Title is required'
        } else if (entityData.spoc_name.length < 3) {
            error = true;
            spoc_designation = 'Primary Contact Title should contain more than 1 letter';
        }

        if (!entityData.spoc_mobile) {
            error = true;
            spoc_mobile = 'Mobile is required'
        } else if (!mobilePatterns.some(pattern => pattern.test(entityData.spoc_mobile))) {
            error = true;
            spoc_mobile = 'Mobile is invalid';
        }

        if (!entityData.email) {
            error = true;
            email = 'Email address is required'
        } else if (!emailPattern.test(entityData.email)) {
            error = true;
            email = 'Email address is invalid';
        }

        if (!entityData.address) {
            error = true;
            address = 'Address is required'
        } else if (entityData.address.length < 3) {
            error = true;
            address = 'Address should contain more than 2 letters';
        }

        if (!entityData.pincode) {
            error = true;
            pincode = 'Post code is required'
        } else if (!/^[0-9]*$/.test(entityData.pincode)) {
            error = true;
            pincode = 'Post code should contain only numbers';
        } else if (entityData.pincode.length !== 4) {
            error = true;
            pincode = 'Post code should contain 4 digits';
        }

        if (!entityData.website) {
            error = true;
            website ='Website is required'
        } else if (!websitePattern.test(entityData.website)) {
            error = true;
            website ='Invalid website'
        }

        setValidMsg({ error, name, abn, acn, spoc_name, spoc_designation, spoc_mobile, email, address, pincode, website });

    }, [entityData, entityLogo]) //eslint-disable-line

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>Create New Entity</h1>
                    </div>
                    <div className='modal-body px-5'>
                        {submitting ? <div className='no-data-banner fw-bold pt-5'>Creating new entity...</div> : <><div className='row'>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='name'
                                        value={entityData.name}
                                        onChange={handleChange}
                                        placeholder='Entity name' />
                                    <label className='ce-label'>Entity name</label>
                                </div>
                                <div className='error-message'>{validMsg?.name}</div>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='abn'
                                        value={entityData.abn}
                                        onChange={handleChange}
                                        placeholder='ABN (Australian Business Number)' />
                                    <label className='ce-label'>ABN</label>
                                </div>
                                {!validMsg?.name && <div className='error-message'>{validMsg?.abn}</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='acn'
                                        value={entityData.acn}
                                        onChange={handleChange}
                                        placeholder='ACN (Australian Company Number)' />
                                    <label className='ce-label'>ACN</label>
                                </div>
                                {!validMsg?.abn && <div className='error-message'>{validMsg?.acn}</div>}
                            </div>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='spoc_name'
                                        value={entityData.spoc_name}
                                        onChange={handleChange}
                                        placeholder='Primary Contact' />
                                    <label className='ce-label'>Primary Contact</label>
                                </div>
                                {!validMsg?.acn && <div className='error-message'>{validMsg?.spoc_name}</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='spoc_designation'
                                        value={entityData.spoc_designation}
                                        onChange={handleChange}
                                        placeholder='Primary Contact Title' />
                                    <label className='ce-label'>Primary Contact Title</label>
                                </div>
                                {!validMsg?.spoc_name && <div className='error-message'>{validMsg?.spoc_designation}</div>}
                            </div>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='spoc_mobile'
                                        value={entityData.spoc_mobile}
                                        onChange={handleChange}
                                        placeholder='Mobile' />
                                    <label className='ce-label'>Mobile</label>
                                </div>
                                {!validMsg?.spoc_designation && <div className='error-message'>{validMsg?.spoc_mobile}</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input className='ce-input'
                                        type='text'
                                        name='email'
                                        value={entityData.email}
                                        onChange={handleChange}
                                        placeholder='Email address' />
                                    <label className='ce-label'>Email address</label>
                                </div>
                                {!validMsg?.spoc_mobile && <div className='error-message'>{validMsg?.email}</div>}
                            </div>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='address'
                                        value={entityData.address}
                                        onChange={handleChange}
                                        placeholder='Address' />
                                    <label className='ce-label'>Address</label>
                                </div>
                                {!validMsg?.email && <div className='error-message'>{validMsg?.address}</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='pincode'
                                        value={entityData.pincode}
                                        onChange={handleChange}
                                        placeholder='Post Code' />
                                    <label className='ce-label'>Post Code</label>
                                </div>
                                {!validMsg?.address && <div className='error-message'>{validMsg?.pincode}</div>}
                            </div>
                            <div className='col-md-6 mt-2'>
                                <div className='ce-field'>
                                    <input
                                        className='ce-input'
                                        type='text'
                                        name='website'
                                        value={entityData.link}
                                        onChange={handleChange}
                                        placeholder='Website' />
                                    <label className='ce-label'>Website</label>
                                </div>
                                {!validMsg?.pincode && <div className='error-message'>{validMsg?.website}</div>}
                            </div>
                        </div>
                        <div>
                            <div>Select entity status</div>
                            <label className='radio-input-field'>
                                <input
                                    type='radio'
                                    name='status'
                                    value='Trial Period'
                                    checked={entityData.status === 'Trial Period'}
                                    onChange={handleChange}
                                />
                                &nbsp;&nbsp;Trial period
                            </label>
                            <label className='radio-input-field'>
                                <input
                                    type='radio'
                                    name='status'
                                    value='Paid'
                                    checked={entityData.status === 'Paid'}
                                    onChange={handleChange}
                                />
                                &nbsp;&nbsp;Paid
                            </label>
                        </div>
                        {!entityLogo ? <div>
                            <span>{`Upload Logo of Entity (Image Dimension Limit is 500px X 500px) `}</span>
                            {!validMsg?.website && (
                                <div className='error-message mt-2'>
                                    {logoErrMsg}
                                </div>
                            )}
                            <input
                                className='form-control'
                                type='file'
                                name='logo'
                                accept='image/*'
                                value={entityData.logo}
                                onChange={handleChange} />
                            {image && (
                                <div className='mt-2'>
                                    {!cropIsValid && <div className='error-message mt-2'>Select the area Of the image to crop</div>}
                                    <ReactCrop crop={crop} onImageLoaded={setEntityLogo} onChange={setCrop} aspect={1 / 1}>
                                        <img className='border' src={image} alt='logo' />
                                    </ReactCrop>
                                    <button disabled={!cropIsValid} className='ms-5 crop-btn p-2' onClick={handleCropClick}>Crop</button>
                                </div>
                            )}
                        </div> :
                            <div>
                                <div>Uploaded logo is</div>
                                <img className='border p-auto' src={entityLogo} alt='logo' />
                                <button className='ms-5 crop-btn p-2' onClick={deleteLogo} >Delete</button>
                            </div>}</>}
                    <div className='error-message mt-2'>{errMsg}</div>
                    </div>
                    <div className='modal-footer'>
                        <button className={submitting ? 'ce-btn-disabled' : 'ce-btn'} onClick={clearData}>Cancel</button>
                        <button disabled={validMsg.error || logoErrMsg || submitting} className={validMsg.error || logoErrMsg || submitting ? 'ce-btn-disabled' : 'ce-btn'} onClick={submitData}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEntityModal
