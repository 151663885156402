import React from 'react'

const SendMailModal = (props) => {

    const { isOpen, serverMsg, closeModal } = props;

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        Send Mail
                    </div>
                    <div className='modal-body px-5'>
                        {serverMsg}
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={closeModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendMailModal