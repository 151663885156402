import React, { useEffect, useState } from 'react'
import LoginLeftHalf from '../../components/LoginLeftHalf'
import './forgotPassword.css';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { useLocation } from 'react-router-dom';

const RightHalf = () => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [linkSent, setLinkSent] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [countdown, setCountdown] = useState(30);
    const location = useLocation();
    const errorMessage = location.state?.error;

    useEffect(() => {
        const countdownInterval = setInterval(()=> {
            setCountdown(prevCount => prevCount - 1)
        }, 1000);

        return () => clearInterval(countdownInterval);
    })

    useEffect(()=> {
        if(countdown <= 0){
            setIsButtonDisabled(false);
        }else{
            setIsButtonDisabled(true);
        }
    }, [countdown])

    const handleSubmit = async () => {
        try {
            const api = axiosRequestInterceptor(false);
            await api.post('/users/mail', { email, expiresIn: '1d' });
            setLinkSent(true);
            setCountdown(30);
            setIsButtonDisabled(true);
            setError(false);
        } catch (error) {
            setError(true);
        }
    }

    const sendLinkAgain = () => {
        setEmail('');
        setLinkSent(false);
    }

    const handleChange = (e) => {
        const tempEmail = e.target.value;
        setEmail(tempEmail);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSubmit();
        }
    }

    return (
        <div className='container-fluid login-righthalf'>
            <div className='login-title'>
                <img className='login-logo' src='/assets/sdx-logo.png' alt='logo' />
                <h3 className='mb-5 greeting'>Welcome Back</h3>
            </div>
            {linkSent ?
                <div className='link-sent'>
                    <div className='fw-bold mb-4'>Link for Changing the Password is Sent to the Email</div>
                    <div>Not Received the Mail yet? {isButtonDisabled && <span>Resend in {countdown} seconds</span>}
                        <button
                        disabled={isButtonDisabled}
                        onClick={sendLinkAgain}
                        className={isButtonDisabled ? 'login-btn-disabled' : 'login-btn'} >
                            Send Again
                        </button>
                    </div>
                </div> :
                <div className='credentials-fp'>
                    {errorMessage && <div className='mb-2'>{errorMessage}</div>}
                    <div className='field-holder mb-3'>
                        <input
                            type='text'
                            value={email}
                            className='input-text'
                            placeholder='Enter Your Email'
                            onChange={handleChange}
                            onKeyDown={handleKeyDown} />
                        <label className='login-label'>Email</label>
                        {error && <div className='error-msg'>Invalid Email</div>}
                    </div>
                    <button onClick={handleSubmit} className='login-btn mt-3'>Send Link To Reset Password</button>
                </div>}
            <div className='login-footer'>
                Powered by Secure Document Exchange
            </div>
        </div>
    );
};

const ForgotPassword = () => {
    return (
        <div className='login'>
            <LoginLeftHalf tagLine='Thrive' />
            <RightHalf />
        </div>
    )
}

export default ForgotPassword