import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import Profilebar from '../../components/Profilebar'
import './adminDashboard.css'
import DoughnutChart from '../../components/DoughnutChart'
import BusinessEntitiesList from '../../components/BusinessEntitiesList'
import Backdrop from '../../components/Backdrop'
import CreateEntityModal from '../../components/CreateEntityModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { axiosRequestInterceptor } from '../../api/axiosInstance'

const AdminDashboard = () => {

  const selected = 'Dashboard';
  const userName = sessionStorage.getItem('SDX_user_name');
  const userType = 'admin'
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [entityData, setEntityData] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const gotoBusinessEntity = () => {
    navigate(`/business_entities/${userName}`)
  }

  const fetchEntityData = async () => {
    try {
      setLoading(true);
      let noOfEntities = 5;
      const api = axiosRequestInterceptor(true);
      const response = await api.get(`/entities/dashboardData/${noOfEntities}`);
      setDashboardData({
        consumedSize: response.data.totalSize,
        totalSpaceAlotted: response.data.totalSpaceAlotted,
        entitiesCount: response.data.entitiesCount,
        clientsCount: response.data.clientsCount,
        documentsCount: response.data.documentsCount
      })
      setEntityData(response.data.entityData);
      setLoading(false);
    } catch (err) {
      if(err?.response?.data?.error) setErrMsg(err.response.data.error);
      else setErrMsg('Failed to connect with server')
      setLoading(false);
    }
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    fetchEntityData();
  }, []) //eslint-disable-line

  const DashboardDetails = () => {
    return (
      <div className='row gap-3 gap-md-4 ms-md-1'>
        <div className='card col-md-5 ps-2 py-2 mb-2 chart-card'>
          <DoughnutChart
            consumedSize={dashboardData?.consumedSize ? dashboardData?.consumedSize : 0}
            alottedSize={dashboardData?.totalSpaceAlotted ? dashboardData?.totalSpaceAlotted : 0} />
          <div className='chart-title'>
            <div className='ms-4'>Total</div>
            <div className='ms-3'>Drive Size</div>
          </div>
        </div>
        <div className='card sm-card-holder col-md-2 mb-2'>
          <img src='/assets/building.png' alt='Business Entities' className='dashboard-icons' />
          <p className='dashboard-stats'>Total Business Entities</p>
          <p className='dahsboard-numbers'>{dashboardData?.entitiesCount}</p>
        </div>
        <div className='card sm-card-holder col-md-2 mb-2'>
          <img src='/assets/users.png' alt='Clients' className='dashboard-icons' />
          <p className='dashboard-stats'>Total Clients</p>
          <p className='dahsboard-numbers'>{dashboardData?.clientsCount}</p>
        </div>
        <div className='card sm-card-holder col-md-2 mb-2'>
          <img src='/assets/folder.png' alt='Documents' className='dashboard-icons' />
          <p className='dashboard-stats'>Total Documents</p>
          <p className='dahsboard-numbers'>{dashboardData?.documentsCount}</p>
        </div>
      </div>
    )
  }

  return (
    <div className='page-with-navbar'>
      <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
        <Profilebar userType={userType} collapsed={collapsed} />
        <div className='container px-4 pt-2 pb-4 scroll-window'>
          <DashboardDetails />
          <div className='db-modify-field'>
            <span className='title-db'>Business Entities</span>
            <button onClick={openModal} className='add-entity'>
            <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>
            Create New Entity</button>
            <span onClick={gotoBusinessEntity} className='view-more'
            >View More <FontAwesomeIcon className='mx-2' icon={faChevronDown} /></span>
          </div>
          {loading ? <div className='no-data-banner fw-bold pt-5'>Loading...</div>:
          errMsg ? <div className='no-data-banner fw-bold pt-5'>{errMsg}</div>:
          entityData.length === 0 ? <div className='no-data-banner fw-bold pt-5'>No Entities have been created yet</div>:
          entityData?.map((entity) => {
            return <BusinessEntitiesList
              key={entity.id}
              logo={entity.logo}
              name={entity.name}
              account_added={entity.createdAt}
              email={entity.email}
              status={entity.status}
              reference={entity.display_id}
              number_of_clients={entity.clientsCount}
              total_usage={entity.driveUsage}
              spaceAlotted={entity.spaceAlotted}
              folder_name={entity.folder_name}
              number_of_documents={entity.documentsCount}
              abn={entity.abn}
              acn={entity.acn}
              spoc_mobile={entity.spoc_mobile}
              spoc_name={entity.spoc_name}
              spoc_designation={entity.spoc_designation}
              address={entity.address}
              pincode={entity.pincode}
              location={entity.location}
              number_of_users={entity.usersCount}
              website={entity.website}/>
          })}
        </div>
      </div>
      <Backdrop isOpen={modalIsOpen} />
      {modalIsOpen && <CreateEntityModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        fetchEntityData={fetchEntityData}/>}
    </div>
  )
}

export default AdminDashboard