import React, { useEffect, useState } from 'react'
import './changePasswordModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const ChangePasswordModal = (props) => {

    const { isOpen, closeModal } = props;
    const initialState = {
        currentPwd: '',
        newPwd: '',
        confirmPwd: ''
    }
    const [pwdData, setPwdData] = useState(initialState);
    const [validMsg, setValidMsg] = useState({ error: true });
    const [touched, setTouched] = useState(initialState);
    const [visibility, setVisibility] = useState({ currentPwd: 'password', newPwd: 'password', confirmPwd: 'password' });

    const handleChange = (e) => {
        const tempData = {
            ...pwdData,
            [e.target.name]: e.target.value
        }
        setPwdData(tempData);
    }

    const inputIsTouched = (e) => {
        const tempTouched = {
            ...touched,
            [e.target.name]: true
        }
        setTouched(tempTouched);
    }

    const toggleVisibility = (inp) => {
        let newType = '';
        if (visibility[inp] === 'password') {
            newType = 'text'
        } else {
            newType = 'password'
        }
        const tempVisibility = {
            ...visibility,
            [inp]: newType
        }
        setVisibility(tempVisibility);
    }

    const clearData = () => {
        setPwdData(initialState);
        setTouched(initialState);
        closeModal();
    }

    const submitData = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            await api.put(`/users/change_password`,
                {
                    currentPwd: pwdData.currentPwd,
                    newPwd: pwdData.newPwd
                });
            setPwdData(initialState);
            setTouched(initialState);
            closeModal();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, currentPwd: err.response.data.error })
            else setValidMsg({ error: true, currentPwd: 'Failed to connect with server'})
        }
    }

    useEffect(() => {
        const pwdPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$])(?=.*\d).{8,}$/;
        let error = false;
        let currentPwd = '';
        let newPwd = '';
        let confirmPwd = '';
        if (!pwdData.currentPwd) {
            error = true;
            currentPwd = 'Current password is required';
        }

        if (!pwdData.newPwd) {
            error = true;
            newPwd = 'New password is required';
        } else if (!pwdPattern.test(pwdData.newPwd)) {
            error = true;
            newPwd = 'Invalid password';
        } else if (pwdData.newPwd === pwdData.currentPwd){
            error = true;
            newPwd = 'New password must be different from the current password';
        }

        if (pwdData.confirmPwd !== pwdData.newPwd) {
            error = true;
            confirmPwd = 'Password is not matching';
        }
        setValidMsg({ error, currentPwd, newPwd, confirmPwd });
    }, [pwdData]) //eslint-disable-line

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>Change Password</h1>
                    </div>
                    <div className='modal-body px-5'>
                        <div className='cp-field'>
                            <input
                                className='cp-input'
                                type={visibility.currentPwd}
                                name='currentPwd'
                                value={pwdData.currentPwd}
                                onFocus={inputIsTouched}
                                placeholder='Enter the current password'
                                onChange={handleChange} />
                            <label className='cp-label'>Current password</label>
                            <FontAwesomeIcon
                                className='visibility'
                                onClick={() => toggleVisibility('currentPwd')}
                                icon={visibility.currentPwd === 'text' ? faEye : faEyeSlash }
                                size='lg' />
                        </div>
                        {touched.currentPwd && (
                            <div className='error-message'>{validMsg?.currentPwd}</div>
                        )}
                        <div className='cp-field'>
                            <input
                                className='cp-input'
                                type={visibility.newPwd}
                                name='newPwd'
                                value={pwdData.newPwd}
                                onFocus={inputIsTouched}
                                placeholder='Enter the new password'
                                onChange={handleChange} />
                            <label className='cp-label'>New password</label>
                            <FontAwesomeIcon
                                className='visibility'
                                onClick={() => toggleVisibility('newPwd')}
                                icon={visibility.newPwd === 'text' ? faEye : faEyeSlash }
                                size='lg' />
                        </div>
                        {touched.newPwd && (
                            <div className='error-message'>{validMsg?.newPwd}</div>
                        )}
                        <div className='cp-field'>
                            <input
                                className='cp-input'
                                type={visibility.confirmPwd}
                                name='confirmPwd'
                                value={pwdData.confirmPwd}
                                onFocus={inputIsTouched}
                                placeholder='Confirm the new password'
                                onChange={handleChange} />
                            <label className='cp-label'>Confirm password</label>
                            <FontAwesomeIcon
                                className='visibility'
                                onClick={() => toggleVisibility('confirmPwd')}
                                icon={visibility.confirmPwd === 'text' ? faEye : faEyeSlash }
                                size='lg' />
                        </div>
                        {touched.confirmPwd && (
                            <div className='error-message'>{validMsg?.confirmPwd}</div>
                        )}
                        <div className='instructions'>
                            Please note:-<br />
                            Password must contain<br />
                            Minimum 8 characters<br />
                            Atleast one uppercase letter<br />
                            Atleast one lowercase letter<br />
                            Atleast one number<br />
                            Atleast one special character ( !, @, #, $)
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button className='cp-btn' onClick={clearData}>Cancel</button>
                        <button disabled={validMsg.error} className={validMsg.error ? 'cp-btn-disabled' : 'cp-btn'} onClick={submitData}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordModal