import React from 'react'
import './backdrop.css'

const Backdrop = (props) => {
    const { isOpen } = props;
  return (
    <div className={`modal-backdrop ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} />
  )
}

export default Backdrop