import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoginLeftHalf from '../../components/LoginLeftHalf'
import './passwordReset.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const RightHalf = () => {

  const initialState = {
    newPwd: '',
    confirmPwd: ''
  }
  const [pwdData, setPwdData] = useState(initialState);
  const [validMsg, setValidMsg] = useState({ error: true });
  const [focusedInput, setFocusedInput] = useState(null);
  const [touched, setTouched] = useState(initialState);
  const [visibility, setVisibility] = useState({ newPwd: 'password', confirmPwd: 'password' });
  const navigate = useNavigate();
  const authToken = useParams();

  const handleChange = (e) => {
    const tempData = {
      ...pwdData,
      [e.target.name]: e.target.value
    }
    setPwdData(tempData);
  }

  const inputIsTouched = (e) => {
    setFocusedInput(e.target.name);
    const tempTouched = {
      ...touched,
      [e.target.name]: true
    }
    setTouched(tempTouched);
  }

  const toggleVisibility = (inp) => {
    let newType = '';
    if (visibility[inp] === 'password') {
      newType = 'text'
    } else {
      newType = 'password'
    }
    const tempVisibility = {
      ...visibility,
      [inp]: newType
    }
    setVisibility(tempVisibility);
  }

  const handleSubmit = async () => {
    try {
      const api = axiosRequestInterceptor(false);
      const response = await api.put('/users/reset_password', { password: pwdData.newPwd }, { headers: { token: authToken.userToken }});
      const { id, token, role, entity_id, name, entity_name, entity_logo, add_prospect } = response.data;
      sessionStorage.setItem('SDX_user_id', id);
      sessionStorage.setItem('SDX_user_name', name);
      sessionStorage.setItem('SDX_token', token);
      sessionStorage.setItem('SDX_user_role', role);
      if (role === 'system_super_admin' || role === 'system_admin' || role === 'system_user') {
        navigate(`/dashboard/${name}`)
      } else if (role === 'entity_super_admin' || role === 'entity_admin' || role === 'entity_user') {
        sessionStorage.setItem('SDX_entity_id', entity_id);
        sessionStorage.setItem('SDX_entity_name', entity_name);
        sessionStorage.setItem('SDX_entity_logo', entity_logo);
        sessionStorage.setItem('SDX_prospect', add_prospect);
        navigate(`/entity_dashboard/${name}`)
      }
    } catch (err) {
      if (err.response.status === 419) {
        navigate('/forgot_password', { state: { error: 'Time Limit Exceeded, Enter the Email to Send Mail to Reset Password Again' } })
      }
      if(err?.response?.data?.error) setValidMsg({ error: true, confirmPwd: err.response.data.error })
      else setValidMsg({ error: true, confirmPwd: 'Failed to connect with server' })
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  useEffect(() => {
    const pwdPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$])(?=.*\d).{8,}$/;
    let error = false;
    let newPwd = '';
    let confirmPwd = '';

    if (!pwdData.newPwd) {
      error = true;
      newPwd = 'New Password Is Required';
    } else if (!pwdPattern.test(pwdData.newPwd)) {
      error = true;
      newPwd = 'Invalid Password';
    }

    if (pwdData.confirmPwd !== pwdData.newPwd) {
      error = true;
      confirmPwd = 'Password Is Not Matching';
    }
    setValidMsg({ error, newPwd, confirmPwd });
  }, [pwdData]) //eslint-disable-line

  return (
    <div className='container-fluid login-righthalf'>
      <div className='login-title'>
        <img className='login-logo' src='/assets/sdx-logo.png' alt='logo' />
        <h3 className='mb-2 greeting'>Welcome Back</h3>
      </div>
      <div className='credentials'>
        <div className='field-holder mb-3'>
          <input
            className='input-text'
            type={visibility.newPwd}
            name='newPwd'
            value={pwdData.newPwd}
            onFocus={inputIsTouched}
            onBlur={() => setFocusedInput('')}
            placeholder='Enter The New Password'
            onChange={handleChange}
            onKeyDown={handleKeyDown} />
          <label className='login-label'>New Password</label>
          {focusedInput !== 'newPwd' && touched.newPwd && (
            <div className='error-msg'>{validMsg?.newPwd}</div>
          )}
          <FontAwesomeIcon
            className='visibility'
            onClick={() => toggleVisibility('newPwd')}
            icon={visibility.newPwd === 'text' ? faEye : faEyeSlash}
            size='lg' />
        </div>
        <div className='field-holder mb-3'>
          <input
            className='input-text'
            type={visibility.confirmPwd}
            name='confirmPwd'
            value={pwdData.confirmPwd}
            onFocus={inputIsTouched}
            onBlur={() => setFocusedInput('')}
            placeholder='Confirm The New Password'
            onChange={handleChange}
            onKeyDown={handleKeyDown} />
          <label className='login-label'>Confirm Password</label>
          {focusedInput !== 'confirmPwd' && touched.confirmPwd && (
            <div className='error-msg'>{validMsg?.confirmPwd}</div>
          )}
          <FontAwesomeIcon
            className='visibility'
            onClick={() => toggleVisibility('confirmPwd')}
            icon={visibility.confirmPwd === 'text' ? faEye : faEyeSlash}
            size='lg' />
        </div>
        <div className='instructions'>
          Please Note:-<br />
          Password Must Contain<br />
          Minimum 8 Characters<br />
          Atleast One Uppercase Letter<br />
          Atleast One Lowercase Letter<br />
          Atleast One Number<br />
          Atleast One Special Character ( !, @, #, $)
        </div>
        <button
          disabled={validMsg.error}
          onClick={handleSubmit}
          className={`login-btn mt-3 ${validMsg.error ? 'btn-disabled' : 'btn-active'}`}>
          Login</button>
      </div>
      <div className='login-footer'>
        Powered by Secure Document Exchange
      </div>
    </div>
  );
};

const PasswordReset = () => {
  return (
    <div className='login'>
      <LoginLeftHalf tagLine='Thrive' />
      <RightHalf />
    </div>
  )
}

export default PasswordReset