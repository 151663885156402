import React from 'react'
import { Link } from 'react-router-dom'
import './businessEntitiesList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faDatabase, faFile, faFolderOpen, faLocationDot, faPenToSquare, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';

const BusinessEntitiesList = (props) => {

    const { logo, name, email, account_added, status, reference, number_of_clients, total_usage,
        folder_name, number_of_documents, spoc_mobile, spoc_name, spoc_designation, location, number_of_users } = props;

    const createdAtDate = new Date(account_added);
    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }
    const formattedDateTime = createdAtDate.toLocaleString('en-AU', options);

    let storageSize = '';
    if (total_usage >= 1) {
        storageSize = total_usage.toFixed(2) + ' GB';
    } else if (total_usage * 1024 >= 1) {
        storageSize = (total_usage * 1024).toFixed(2) + ' MB';
    } else {
        storageSize = (total_usage * 1024 * 1024).toFixed(2) + ' KB';
    }

    return (
        <div className='card mt-2 py-2 col-12'>
            <div className='row'>
                <div className='col-12 col-md-3 entity-info'>
                    <img className='entity-logo' src={logo ? logo : '/assets/def-logo.png'} alt='logo' />
                    <span className='entity-title'>{name}</span>
                    <span className='entity-contact'>{email}</span>
                    <div className='accnt-added-date'>Entity since : {formattedDateTime}</div>
                    <span className='entity-type-be'>{status} &nbsp;|&nbsp; Reference: {reference}</span>
                    <div className='entity-options'>
                        <Link to={`/business_entity/${reference}`} state={{ ...props }} className='px-1 mx-2'>
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Link>
                    </div>
                </div>
                <div className='other-entity-details pt-md-3 ms-2'>
                    <div className='row mt-4'>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faUser} size='lg' color='#208DA9'/>
                            <span className='ms-2'>
                                Number Of clients
                                <p className='ms-4 fw-bold'>{number_of_clients}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faFile} size='lg' color='#208DA9' />
                            <span className='ms-2'>
                                Number of documents
                                <p className='ms-4 fw-bold'>{number_of_documents}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faAddressBook} size='lg' color='#208DA9' />
                            <span className='ms-2'>
                                Primary Contact (Title)
                                <p className='ms-4 fw-bold'>{`${spoc_name} (${spoc_designation})`}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faFolderOpen} size='lg' color='#208DA9' />
                            <span className='ms-2'>
                                Folder name
                                <p className='ms-4 fw-bold'>{folder_name}</p>
                            </span>
                        </span>
                    </div>
                    <div className='row mt-2'>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faDatabase} size='lg' color='#208DA9'/>
                            <span className='ms-2'>
                                Drive usage
                                <p className='ms-4 fw-bold'>{storageSize}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faUser} size='lg' color='#208DA9'/>
                            <span className='ms-2'>
                                Number of users
                                <p className='ms-4 fw-bold'>{number_of_users}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faPhone} size='lg' color='#208DA9' />
                            <span className='ms-2'>
                                Mobile
                                <p className='ms-4 fw-bold'>{spoc_mobile}</p>
                            </span>
                        </span>
                        <span className='col-6 col-md-3'>
                            <FontAwesomeIcon icon={faLocationDot} size='lg' color='#208DA9' />
                            <span className='ms-2'>
                                Location
                                <p className='ms-4 fw-bold'>{location}</p>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessEntitiesList