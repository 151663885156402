import React, { useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom';
import LoginLeftHalf from '../../components/LoginLeftHalf';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const RightHalf = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [ errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const isVisible = visibility ? 'text' : 'password'
  const navigate = useNavigate();
  
  const handleemail = (e) => {
    setEmail(e.target.value);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const toggleVisibility = () => {
    setVisibility(prevState => !prevState);
  }

  const handleSubmit = async() => {
    try{
      setSubmitting(true);
      const api = axiosRequestInterceptor(false);
      const response = await api.post(`/users/login`, { email, password });
      const { id, token, role, entity_id, name, entity_name, entity_logo, add_prospect } = response?.data;
      sessionStorage.setItem('SDX_user_id', id);
      sessionStorage.setItem('SDX_user_name', name);
      sessionStorage.setItem('SDX_token', token);
      sessionStorage.setItem('SDX_user_role', role);
      if (role === 'system_super_admin' || role === 'system_user' || role === 'system_admin') {
        navigate(`/dashboard/${name}`)
      } else if (role === 'entity_super_admin' || role === 'entity_admin' || role === 'entity_user') {
        sessionStorage.setItem('SDX_entity_id', entity_id);
        sessionStorage.setItem('SDX_entity_name', entity_name);
        sessionStorage.setItem('SDX_entity_logo', entity_logo);
        sessionStorage.setItem('SDX_prospect', add_prospect);
        navigate(`/entity_dashboard/${name}`)
      }
      setSubmitting(false);
    } catch(err){
      if(err?.response?.data?.error) setErrorMsg(err.response.data.error);
      else setErrorMsg('Failed to connect with server');
      setSubmitting(false);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  const gotoForgotPassword = () => {
    navigate('/forgot_password');
  }

  return (
    <div className='container-fluid login-righthalf'>
      <div className='login-title'>
        <img className='login-logo' src='/assets/sdx-logo.png' alt='logo' />
        <h3 className='mb-5 greeting'>Welcome Back</h3>
      </div>
      {submitting ? <div className='no-data-banner fw-bold pt-5'>Logging In...</div> : <div className='credentials'>
        <div className='field-holder mb-3'>
          <input
            type='text'
            value={email}
            className='input-text'
            placeholder='Enter Your Email'
            onChange={handleemail}
            onKeyDown={handleKeyDown} />
          <label className='login-label'>Email</label>
        </div>
        <div className='field-holder mb-3'>
          <input
            type={isVisible}
            value={password}
            className='input-text'
            placeholder='Enter Your Password'
            onChange={handlePassword}
            onKeyDown={handleKeyDown} />
          <label className='login-label'>Password</label>
          {errorMsg && <div className='error-msg'>{errorMsg}</div>}
          <span onClick={gotoForgotPassword} className='forgot-password mt-2'>Forgot password?</span>
          <FontAwesomeIcon onClick={toggleVisibility} icon={ visibility ? faEye : faEyeSlash } className='visibility' size='lg' />
        </div>
        <button onClick={handleSubmit} className='login-btn mt-3'>Login</button>
      </div>}
      <div className='login-footer'>
      Powered by Secure Document Exchange
      </div>
    </div>
  );
};

const Login = () => {
  return (
    <div className='login'>
      <LoginLeftHalf tagLine='Thrive'/>
      <RightHalf />
    </div>
  )
}

export default Login