import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useState } from 'react'

const NotifyClientModal = (props) => {

    const { isOpen, case_id, client_id, closeModal } = props;
    const [ error, setError] = useState('');

    const notifyClient = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            await api.post('/clients/notify', { id: case_id, client_id });
            closeModal();
        } catch (err) {
            console.error(err)
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server');
        }
    }

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>
                            Notify Client
                        </h1>
                    </div>
                    <div className='modal-body m-auto'>
                        <div>Are you sure to send notification via email to the client regarding the pending action?</div>
                        <div className='error-message'>{error}</div>
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={closeModal}>Cancel</button>
                        <button disabled={error} className='au-btn' onClick={notifyClient}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifyClientModal