import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoginLeftHalf from '../../components/LoginLeftHalf';
import './verifyUser.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const RightHalf = () => {

  const [otp, setOtp] = useState(['', '', '', '']);
  const api = axiosRequestInterceptor(false);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otpSection, setOtpSection] = useState(false);
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userType, userId, userToken } = useParams();

  const verifyClient = async() => {
    try{
      await api.post(`/${userType}s/verify`, {email, userId }, {headers: {token: userToken}});
      setOtpSection(true);
      setCountdown(30);
      setIsButtonDisabled(true);
    }catch(err){
      setOtpSection(true);
      setCountdown(30);
      setIsButtonDisabled(true);
    }
  }

  const submitOTP = async() => {
    try{
      const response = await api.post(`/${userType}s/login`, { otp: otp.join(''), userId }, {headers: {token: userToken}});
      const { id, token, entity_id, name, entity_name, entity_logo, role, status } = response?.data;
      sessionStorage.setItem('SDX_user_name', name);
      sessionStorage.setItem('SDX_token', token);
      sessionStorage.setItem('SDX_entity_id', entity_id);
      sessionStorage.setItem('SDX_role', role);
      sessionStorage.setItem('SDX_entity_name', entity_name);
      sessionStorage.setItem('SDX_entity_logo', entity_logo);
      if(userType === 'client'){
        navigate(`/client_dashboard/${id}`);
      } else {
        sessionStorage.setItem('SDX_status', status);
        navigate(`/prospect/${id}`);
      }
    }catch(err){
      console.error(err);
      if(err?.response?.data?.error) setError(err.response.data.error);
      else setError('Failed to connect with server');
    }
  }

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    const newOtp = [...otp];
    if (newValue === '') {
      let i = index;
      while (i < 3) {
        newOtp[i] = newOtp[i + 1];
        i++;
      }
      newOtp[3] = '';
    } else if (newValue.length === 1) {
      newOtp[index] = newValue;
      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    } else {
      newOtp[index] = newValue[1];
      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
    setOtp(newOtp);
  }

  const resendOTP = () => {
    if(!isButtonDisabled){
      setOtpSection(false);
      setEmail('');
      setError('');
      setOtp(['', '', '', '']);
    }
  }

  const handleSubmit = () => {
    if(otpSection){
      let valid = true;
      otp.forEach((digit) => {
        if (digit === '') {
          setError('Invalid OTP');
          valid = false;
        }
      })
      if (valid) {
        submitOTP();
      }
    }else{
      verifyClient();
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  useEffect(() => {
    const countdownInterval = setInterval(()=> {
        setCountdown(prevCount => prevCount - 1)
    }, 1000);

    return () => clearInterval(countdownInterval);
  })

  useEffect(()=> {
    if(countdown <= 0){
        setIsButtonDisabled(false);
    }else{
        setIsButtonDisabled(true);
    }
  }, [countdown])

  return (
    <div className='container-fluid verify-righthalf'>
      {otpSection ? <>
        <div className='verify-title'>
          <img src='/assets/sdx-logo.png' alt='logo' width='200px' />
          <h3 className='greeting'>OTP Verification</h3>
          <p className='mb-3'>Enter the otp sent to your email address</p>
        </div>
        <div className='credentials pt-5'>
          <div className='d-flex gap-3'>
            {otp.map((digit, index) => (
              <input
                key={index}
                type='number'
                maxLength='1'
                className='otp-input ps-4'
                placeholder='-'
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={handleKeyDown}
                ref={inputRefs[index]} />
            ))}
          </div>
          <div className='error-msg'>{error}</div>
          <button onClick={handleSubmit} className='login-btn mt-3'>Login</button>
          <span onClick={resendOTP} className='resend-otp mt-2'>Re-send the OTP {countdown > 0 ? `again in ${countdown} seconds`  : ''}</span>
        </div></> :
        <>
          <div className='verify-title'>
            <img src='/assets/sdx-logo.png' alt='logo' width='200px' />
            <h3 className='greeting'>Welcome</h3>
            <p className='mb-3'>Enter your email address to send the OTP</p>
          </div>
          <div className='credentials-fp'>
            <div className='field-holder mb-3'>
              <input
                type='text'
                value={email}
                className='input-text'
                placeholder='Enter Your Email'
                onChange={(e)=> setEmail(e.target.value)}
                onKeyDown={handleKeyDown} />
              <label className='login-label'>Email</label>
              <div className='fs mt-2'>If your account is linked with the application, you will receive an OTP</div>
            </div>
            <button onClick={handleSubmit} className='login-btn mt-3'>Send OTP</button>
          </div>
        </>
      }
      <div className='verify-footer'>
        Powered by Secure Document Exchange
      </div>
    </div>
  )
}

const VerifyUser = () => {
  return (
    <div className='verify'>
      <LoginLeftHalf tagLine='Encrypt' />
      <RightHalf />
    </div>
  )
}

export default VerifyUser