import React, { useState } from 'react'
import Backdrop from '../Backdrop'
import ReviewDocModal from '../ReviewDocModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const ClientsDocTable = (props) => {
    const { reqDocData, caseTitle, clientId, fetchCaseRequests, fetchCasesData } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    
    const openModal = (req) => {
        setModalIsOpen(true);
        setModalData(req);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    return (
        <div className='table-holder'>
            <table className='table'>
                <thead className='table-head'>
                    <tr>
                        <th className='table-head'>Document name</th>
                        {window.innerWidth < 768 && <th className='table-head'>Status</th>}
                        <th className='table-head'>Initiated by</th>
                        <th className='table-head'>Description</th>
                        <th className='table-head'>Date requested</th>
                        <th className='table-head'>Date uploaded</th>
                        {window.innerWidth > 768 && <th className='table-head'>Status</th>}
                        <th className='table-head'>Remarks</th>
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {reqDocData?.map((req) => {
                        let statusColor = null;
                        if (req.status === 'Uploaded from Client') {
                            statusColor = {
                                color: 'red',
                            }
                        } else if (req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' || req.status === 'Rejected') {
                            statusColor = {
                                color: 'blue'
                            }
                        } else {
                            statusColor = {
                                color: 'green'
                            }
                        }
                        const options = {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                        }
                        const createdAtDate = new Date(req.createdAt);
                        const requested_date = createdAtDate.toLocaleString('en-AU', options);
                        const uploadedAtDate = new Date(req.date_up);
                        const uploaded_date = uploadedAtDate.toLocaleString('en-AU', options);
                        return (
                            <tr key={req.id}>
                                <td className='no-wrap-columns'>
                                    <FontAwesomeIcon className='me-2' icon={faFile} size='lg' />
                                    {req.doc_name}
                                </td>
                                {window.innerWidth < 768 && <td className='no-wrap-columns'>
                                    <button className='action-btn' style={statusColor} onClick={() => openModal(req)}>
                                        <span className='ms-2 fw-bold status'>
                                            {req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' ? 'Requested' :
                                                req.status === 'Uploaded from Client' ? 'Pending' :
                                                    req.status === 'Rejected' ? 'Re-requested' : req.status}</span>
                                    </button>
                                </td>}
                                <td>{req.initiated_by}</td>
                                <td>{req.description}</td>
                                <td>{requested_date}</td>
                                <td>{req.date_up ? uploaded_date : '-'}</td>
                                {window.innerWidth > 768 && <td className='no-wrap-columns'>
                                    <button className='action-btn' style={statusColor} onClick={() => openModal(req)}>
                                        <span className='ms-2 fw-bold status'>
                                            {req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' ? 'Requested' :
                                                req.status === 'Uploaded from Client' ? 'Pending' :
                                                    req.status === 'Rejected' ? 'Re-requested' : req.status}</span>
                                    </button>
                                </td>}
                                <td>{req.remark}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Backdrop isOpen={modalIsOpen} />
            {modalIsOpen && <ReviewDocModal
                isOpen={modalIsOpen}
                closeModal={closeModal}
                modalData={modalData}
                caseTitle={caseTitle}
                clientId={clientId}
                fetchCaseRequests={fetchCaseRequests}
                fetchCasesData={fetchCasesData}
            />}
        </div>
    )
}

export default ClientsDocTable