import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import BusinessEntities from './pages/BusinessEntities';
import Error from './pages/Error';
import EditBusinessEntity from './pages/EditBusinessEntity';
import ManageUsers from './pages/ManageUsers';
import ClientDashboard from './pages/ClientDashboard';
import VerifyUser from './pages/VerifyUser';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import EntityDashboard from './pages/EntityDashboard';
import ClientDetails from './pages/ClientDetails';
import Clients from './pages/Clients';
import Documents from './pages/Documents';
import ProtectedRoute from './components/ProtectedRoute';
import ProspectDashboard from './pages/ProspectDashboard';
import Prospects from './pages/Prospects';
import AdminDashboard from './pages/AdminDashboard';
import ViewProspectData from './pages/ViewProspectData';

function App() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/'){
      navigate('/login');
    }
  }, []); //eslint-disable-line

  return (
    <Routes>
      <Route path='/login' element={<Login />}/>
      <Route path='/forgot_password' element={<ForgotPassword />} />
      <Route path='/password_reset/:userToken' element={<PasswordReset />} />
      <Route path='/dashboard/:adminName' element={<ProtectedRoute component={AdminDashboard} />}/>
      <Route path='/business_entities/:adminName' element={<ProtectedRoute component={BusinessEntities} />}/>
      <Route path='/business_entity/:entityId' element={<ProtectedRoute component={EditBusinessEntity} />}/>
      <Route path='/documents/:userType' element={<ProtectedRoute component={Documents} />}/>
      <Route path='/manage_users/:userType' element={<ProtectedRoute component={ManageUsers} />}/>
      <Route path='/verify/:userType/:userId/:userToken' element={<VerifyUser />}/>
      <Route path='/client_dashboard/:userId' element={<ProtectedRoute component={ClientDashboard} />}/>
      <Route path='/entity_dashboard/:userName' element={<ProtectedRoute component={EntityDashboard} />} />
      <Route path='/clients/:userName' element={<ProtectedRoute component={Clients} />} />
      <Route path='/client_details/:clientId' element={<ProtectedRoute component={ClientDetails} />} />
      <Route path='/prospect/:userId' element={<ProtectedRoute component={ProspectDashboard} />} />
      <Route path='/prospects' element={<ProtectedRoute component={Prospects} />} />
      <Route path='/prospectData/:prospectId' element={<ProtectedRoute component={ViewProspectData} />} />
      <Route path='*' element={<Error/ >} />
    </Routes>
  );
}

export default App;
