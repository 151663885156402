import React, { useEffect, useState } from 'react'
import './clientDetails.css'
import Navbar from '../../components/Navbar'
import Profilebar from '../../components/Profilebar';
import { useLocation, useNavigate, } from 'react-router-dom';
import CaseCard from '../../components/CaseCard';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ClientDetails = () => {

    const location = useLocation();
    const { caseData } = location.state;
    const selected = 'Clients';
    const userType = 'entity';
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
    const [clientData, setClientData] = useState(null);
    const [casesData, setCasesData] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const navigate = useNavigate();

    const fetchClientData = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/clients/clientData/${caseData.client_id}`)
            setClientData(response.data.clientData);
            setCasesData(response.data.casesData);
        } catch (err) {
            if(err?.response?.data?.error) setErrMsg(err.response.data.error);
            else setErrMsg('Failed to connect with server')
        }
    }

    const gotoPrevious = () => {
        navigate(-1);
    }

    useEffect(() => {
        fetchClientData();
    }, []) //eslint-disable-line

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar userType={userType} collapsed={collapsed} />
                <div className='container-fluid scroll-window'>
                    <div className='title-edit-entity'>
                        <div onClick={gotoPrevious} className='hand-symbol'>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        &nbsp;&nbsp;Details
                    </div>
                    {errMsg ? <div className='no-data-banner fw-bold pt-5' >{errMsg}</div> :
                        <>{casesData
                            ?.filter(clickedCaseData => clickedCaseData.id === caseData.id)
                            .map(clickedCaseData => (
                                <CaseCard key={clickedCaseData.id}
                                clientId={clientData.id}
                                clientData={clientData}
                                caseData={clickedCaseData}
                                fetchClientData={fetchClientData}/>
                            ))}
                            {casesData
                                ?.filter(otherCaseData => otherCaseData.id !== caseData.id)
                                .map(otherCaseData => (
                                    <CaseCard key={otherCaseData.id}
                                    clientId={clientData.id}
                                    caseData={otherCaseData}
                                    fetchClientData={fetchClientData} />
                                ))}
                        </>}
                </div>
            </div>
        </div>
    )
}

export default ClientDetails