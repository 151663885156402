import React, { useEffect, useState } from 'react'
import './manageUsers.css'
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import UserTable from '../../components/UserTable';
import AddUserModal from '../../components/AddUserModal';
import Backdrop from '../../components/Backdrop';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const ManageUsers = () => {

    const selected = "Manage Users";
    const [users, setUsers] = useState([]);
    const { userType } = useParams();
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ error, setError] = useState('');

    const fetchUsers = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/users/userdata`)
            setUsers(response.data);
        } catch (err) {
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server');
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
            fetchUsers();
    }, []); //eslint-disable-line

    return (
        <div className="page-with-navbar">
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar collapsed={collapsed} userType={userType} />
                <div className="container px-md-5 scroll-window">
                    <div className="mu-modify-field">
                        <span className="title-mu">Manage Users</span>
                        <span className="group-ip-mu">
                            <button onClick={openModal} className="add-user ms-md-5">
                            <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>
                            Add User</button>
                        </span>
                    </div>
                    <div className="table-holder">
                        {error ? <div className="m-auto p-5" >{error}</div>:
                        <UserTable users={users} fetchUsers={fetchUsers} actions={true}/>}
                    </div>
                </div>
            </div>
            <Backdrop isOpen={modalIsOpen} />
            {modalIsOpen && <AddUserModal isOpen={modalIsOpen} closeModal={closeModal} fetchUsers={fetchUsers}/>}
        </div>
    )
}

export default ManageUsers