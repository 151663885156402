import React, { useEffect, useState } from 'react';
import './userCaseCards.css'
import UpdateDocModal from '../UpdateDocModal';
import Backdrop from '../Backdrop';
import { Tooltip } from 'react-tooltip';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import DownloadModal from '../DownloadModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faChevronUp, faCirclePlus, faClock, faDownload, faFile, faHashtag, faSquarePhone, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';

const UserCaseCards = (props) => {

    const { caseData } = props;
    const [reqDocData, setReqDocData] = useState([]);
    const [detailsSection, setDetailSection] = useState(window.innerWidth < 768 ? false : true);
    const [docSection, setDocSection] = useState(caseData.status === 'Active' ? true : false);
    const [docError, setDocError] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState({upload: false, download: false});
    const [modalData, setModalData] = useState(null);

    const fetchRequestsData = async () => {
        try {
            setDocError('');
            const api = axiosRequestInterceptor(true);
            const response = await api.get(`/clients/requestsData/${caseData.id}`,);
            setReqDocData(response.data);
        } catch (err) {
            if(err?.response?.data?.error) setDocError(err.response.data.error);
            else setDocError('Failed to connect with server');
        }
    }

    const openModal = (req, action) => {
        if(action === 'Upload'){
            setModalIsOpen({upload: true, download: false});
            setModalData(req);
        }else{
            setModalIsOpen({upload: false, download: true});
            setModalData(req);
        }
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const toggleDetailsSection = () => {
        if (window.innerWidth < 768) {
            setDetailSection(prevState => !prevState);
        }
    }

    const toggleDocSection = () => {
        setDocSection(prevState => !prevState);
    }

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }
    const createdAtDate = new Date(caseData.createdAt);
    const case_created_date = createdAtDate.toLocaleString('en-AU', options);
    const closedDate = new Date(caseData.closed_date);
    const case_closed_date = closedDate.toLocaleString('en-AU', options);

    useEffect(() => {
        if (docSection) {
            fetchRequestsData();
        }
    }, [docSection]) //eslint-disable-line

    return (
        <div className='card m-2 m-md-5'>
            <div className='user-sections'>
                <div onClick={toggleDetailsSection} className='matter-title my-2 ms-4'>
                    {caseData?.title}
                    {window.innerWidth < 768 &&
                    <FontAwesomeIcon className='ms-2' icon={detailsSection ? faChevronUp : faChevronDown}/>}</div>
                <div className={detailsSection ? 'row px-5 pb-3' : 'd-none'}>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faHashtag} size='lg' color='#208DA9' />
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Reference number</div>
                            <div>{caseData.id}</div>
                        </span>
                    </div>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faUser} size='lg' color='#208DA9'/>
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Name</div>
                            <div>{`${caseData?.spoc_name ? caseData.spoc_name : '-'}`}</div>
                        </span>
                    </div>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faSquarePhone} size='lg' color='#208DA9' />
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Contact number</div>
                            <div>{caseData?.spoc_mobile ? caseData.spoc_mobile : '-'}</div>
                        </span>
                    </div>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faClock} size='lg' color='#208DA9' />
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Status</div>
                            <div className={caseData.status === 'Active' ? 'badge text-bg-success' :
                                caseData.status === 'Inactive' ? 'badge text-bg-secondary' : 'badge text-bg-danger'}>
                                {caseData.status}</div>
                        </span>
                    </div>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faCalendar} size='lg' color='#208DA9' />
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Open date</div>
                            <div>{case_created_date}</div>
                        </span>
                    </div>
                    <div className='col-6 col-md-2 mb-2 d-flex align-items-center'>
                        <span>
                        <FontAwesomeIcon icon={faCalendar} size='lg' color='#208DA9' />
                        </span>
                        <span className='matter-details ms-4'>
                            <div>Close date</div>
                            <div>{caseData?.closed_date ? case_closed_date : '-'}</div>
                        </span>
                    </div>
                </div>
            </div>
            <div className='user-sections px-4'>
                <div onClick={toggleDocSection} className='section-title my-2'>Documents
                <FontAwesomeIcon className='ms-2' icon={docSection ? faChevronUp : faChevronDown}/>
                </div>
                <div className={docSection ? 'table-holder' : 'd-none'}>
                    {docError ? <div className='no-data-banner fw-bold pt-5'>{docError}</div> :
                        reqDocData.length === 0 ? <div className='no-data-banner fw-bold pt-5'>There is no request added yet</div> :
                            <table className='table pb-3 '>
                                <thead className='table-head'>
                                    <tr>
                                        <th className='table-head'>Document name</th>
                                        {window.innerWidth < 768 && <th className='table-head'>Status</th>}
                                        {window.innerWidth < 768 && <th className='table-head'>Action</th>}
                                        <th className='table-head'>Description</th>
                                        <th className='table-head'>Date requested</th>
                                        <th className='table-head'>Date uploaded</th>
                                        {window.innerWidth > 768 && <th className='table-head'>Status</th>}
                                        {window.innerWidth > 768 && <th className='table-head'>Action</th>}
                                        <th className='table-head'>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody className='table-body'>
                                    {reqDocData.map((req) => {
                                        let statusColor = null;
                                        if (req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' || req.status === 'Rejected') {
                                            statusColor = {
                                                color: 'red'
                                            }
                                        } else if (req.status === 'Uploaded from Client') {
                                            statusColor = {
                                                color: 'blue'
                                            }
                                        } else {
                                            statusColor = {
                                                color: 'green'
                                            }
                                        }
                                        const options = {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        }
                                        const createdAtDate = new Date(req.createdAt);
                                        const requested_date = createdAtDate.toLocaleString('en-AU', options);
                                        const uploadedAtDate = new Date(req.date_up);
                                        const uploaded_date = uploadedAtDate.toLocaleString('en-AU', options);
                                        return (
                                            <tr key={req.id}>
                                                <td className='no-wrap-columns'>
                                                    <FontAwesomeIcon className='me-2' icon={faFile} size='lg' />
                                                    &nbsp;&nbsp;{req.doc_name}
                                                </td>
                                                {window.innerWidth < 768 && <td>
                                                    <div className='status' style={statusColor}>
                                                        {req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' || req.status === 'Rejected' ? 'Pending':
                                                        req.status === 'Uploaded from Client' ? 'Submitted' : req.status}
                                                    </div>
                                                </td>}
                                                {window.innerWidth < 768 && <td className='no-wrap-columns'>
                                                    {req.type_of_req !== 'Upload' && <button
                                                        onClick={() => openModal(req, 'Download')}
                                                        className='action-btn me-2'>
                                                        <FontAwesomeIcon icon={faDownload} size='lg' color='#0b3043' />
                                                    </button>}
                                                    {(req.type_of_req === 'Upload' ||
                                                    (req.type_of_req === 'Download & Upload' && req.status !== 'Download Pending from Client')) && <button
                                                        onClick={() => openModal(req, 'Upload')}
                                                        className='action-btn'>
                                                        <FontAwesomeIcon icon={faUpload} size='lg' color='#0b3043' />
                                                    </button>}
                                                </td>}
                                                <td>{req.description}</td>
                                                <td>{requested_date}</td>
                                                <td>{req.date_up ? uploaded_date : '-'}</td>
                                                {window.innerWidth > 768 && <td>
                                                    <div className='status' style={statusColor}>
                                                    {req.status === 'Upload Pending from Client' || req.status === 'Download Pending from Client' || req.status === 'Rejected' ? 'Pending':
                                                    req.status === 'Uploaded from Client' ? 'Submitted' : req.status}
                                                    </div>
                                                </td>}
                                                {window.innerWidth > 768 && <td className='no-wrap-columns'>
                                                    {req.type_of_req !== 'Upload' && <button
                                                        onClick={() => openModal(req, 'Download')}
                                                        data-tooltip-id='action-tooltip'
                                                        data-tooltip-content={req.type_of_req}
                                                        className='action-btn me-2'>
                                                        <FontAwesomeIcon icon={faDownload} size='lg' color='#0b3043' />
                                                    </button>}
                                                    <Tooltip id='action-tooltip' place='top' effect='solid' />
                                                    {(req.type_of_req === 'Upload' ||
                                                    (req.type_of_req === 'Download & Upload' && req.status !== 'Download Pending from Client')) && <button
                                                        onClick={() => openModal(req, 'Upload')}
                                                        data-tooltip-id='action-tooltip'
                                                        data-tooltip-content={req.type_of_req}
                                                        className='action-btn'>
                                                        <FontAwesomeIcon icon={faUpload} size='lg' color='#0b3043' />
                                                    </button>}
                                                </td>}
                                                <td>{req.remark}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                    {reqDocData.length !== 0 && <div>
                        <button onClick={() => openModal('newDoc', 'Upload')} className='add-new-btn mb-2'>
                            <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>
                            Add new document</button>
                    </div>}
                </div>
            </div>
            <Backdrop isOpen={modalIsOpen.upload || modalIsOpen.download} />
            {modalIsOpen.upload && <UpdateDocModal
                isOpen={modalIsOpen.upload}
                closeModal={closeModal}
                modalData={modalData}
                caseTitle={caseData.title}
                caseId={caseData.id}
                fetchRequestsData={fetchRequestsData} />}
            {modalIsOpen.download && <DownloadModal
                isOpen={modalIsOpen.download}
                closeModal={closeModal}
                modalData={modalData}
                fetchRequestsData={fetchRequestsData}
                />}
        </div>
    )
}

export default UserCaseCards