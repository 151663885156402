import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Profilebar from '../../components/Profilebar';
import Backdrop from '../../components/Backdrop';
import ModifyCollectionModal from '../../components/ModifyCollectionModal';
import { useDebounce } from 'use-debounce';
import CollectionAccordian from '../../components/CollectionAccordian';
import DocTypeRow from '../../components/DocTypeRow';
import ModifyDocTypeModal from '../../components/ModifyDocTypeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faFile, faFolder, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './documents.css'
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const Documents = () => {

    const selected = 'Documents';
    const { userType } = useParams();
    const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false)
    const [modalIsOpen, setModalIsOpen] = useState({ collection: false, document: false });
    const [inputValue, setInputValue] = useState('');
    const [viewType, setViewType] = useState('Collections')
    const [documentsData, setDocumentsData] = useState([]);
    const [collectionsData, setCollectionsData] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [error, setError] = useState('');

    const fetchCollectionData = async () => {
        try {
            const api = axiosRequestInterceptor(true);
            if (viewType === 'Collections') {
                const response = await api.get(`/collections/getCollectionsData/${inputValue}`);
                setCollectionsData(response.data)
            } else {
                const response = await api.get(`/doc_types/data/${inputValue}`);
                setDocumentsData(response.data)
            }
        } catch (err) {
            console.error(err);
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server');
        }
    }

    const openModal = (action, data) => {
        if (viewType === 'Collections') {
            if (action === 'Edit') {
                setModalData({ id: data.id, name: data.name, requests: data.requests, action });
            } else if (action === 'Delete') {
                setModalData({ id: data.id, name: data.name, action });
            } else {
                setModalData({ action });
            }
            setModalIsOpen({ collection: true, document: false });
        } else {
            if (action === 'Edit') {
                setModalData({
                    id: data.id,
                    doc_name: data.doc_name,
                    description: data.description,
                    type_of_req: data.type_of_req,
                    action
                });
            } else if (action === 'Delete') {
                setModalData({ id: data.id, doc_name: data.doc_name, action });
            } else {
                setModalData({ action });
            }
            setModalIsOpen({ collection: false, document: true });
        }
    };

    const closeModal = () => {
        if (viewType === 'Collections') {
            setModalData({ name: '', requests: [] });
        } else {
            setModalData({ doc_name: '', description: '', type_of_req: '' });
        }
        setModalIsOpen({ collection: false, document: false });
    };

    const [debouncedInput] = useDebounce(inputValue, 500);

    useEffect(() => {
        fetchCollectionData();
    }, [debouncedInput, viewType]) //eslint-disable-line

    return (
        <div className='page-with-navbar'>
            <Navbar userType={userType} selected={selected} collapsed={collapsed} setCollapsed={setCollapsed} />
            <div className={`${window.innerWidth < 768 && !collapsed ? 'blank-screen' : 'right-section'}`}>
                <Profilebar collapsed={collapsed} userType={userType} />
                <div className='container px-0 scroll-window'>
                    <div className='active-filter fw-bold'>
                        <span>
                            <span className={`ms-3 pb-1 ${viewType === 'Collections' ? 'doc-tabs-active' : 'doc-tabs-inactive'}`}
                                onClick={()=> setViewType('Collections')}>
                                <FontAwesomeIcon icon={faFolder} className='me-2' />
                                Collections
                            </span>
                            <span className={`ms-3 pb-1 ${viewType === 'Documents' ? 'doc-tabs-active' : 'doc-tabs-inactive'}`}
                                onClick={()=> setViewType('Documents')}>
                                <FontAwesomeIcon icon={faFile} className='me-2' />
                                Documents</span>
                        </span>
                    </div>
                    <div className='clients-action-tab mt-2 mx-md-5'>
                        <span className='search-holder px-1 ms-auto mb-3'>
                            <FontAwesomeIcon className='mx-1' color='gray' icon={faMagnifyingGlass} />
                            <input
                                className='input-clients-search'
                                type='text'
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder='Search' />
                        </span>
                        <button
                            onClick={() => openModal('New')}
                            className='add-clients-btn ms-md-5 mb-3'>
                            <FontAwesomeIcon icon={faCirclePlus} className='me-2' size='lg'/>
                            {viewType === 'Collections' ? 'New Collection' : 'New Document Type'}</button>
                    </div>
                    <div className='scrollable-hor card mt-2 pt-1 mx-md-5'>
                        {viewType === 'Collections' ? <>
                            {error ? <div className='m-auto p-5'>{error}</div> :
                                collectionsData.length ? <>
                                    {collectionsData.map((collection) => {
                                        return (
                                            <CollectionAccordian
                                                key={collection.id}
                                                userType={userType}
                                                collectionData={collection}
                                                openModal={openModal} />
                                        )
                                    })}</> :
                                    <div className='m-auto p-5'>There is no Collection added yet</div>}</> : <>
                            {error ? <div className='m-auto p-5'>{error}</div> :
                                documentsData.length > 0 ? <>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Document Name</th>
                                                <th>Description</th>
                                                <th>Type of Request</th>
                                                <th>Created By</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentsData.map((document) => {
                                                return (
                                                    <DocTypeRow
                                                        key={document.id}
                                                        userType={userType}
                                                        documentData={document}
                                                        openModal={openModal} />
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </> :
                                    <div className='m-auto p-5'>There is no document type added yet</div>}
                        </>}
                    </div>
                </div>
            </div>
            <Backdrop isOpen={modalIsOpen.collection || modalIsOpen.document} />
            {modalIsOpen.collection && <ModifyCollectionModal
                isOpen={modalIsOpen.collection}
                closeModal={closeModal}
                existingData={modalData}
                fetchCollectionData={fetchCollectionData}
            />}
            {modalIsOpen.document && <ModifyDocTypeModal
                isOpen={modalIsOpen.document}
                closeModal={closeModal}
                existingData={modalData}
                fetchCollectionData={fetchCollectionData}
            />}
        </div>
    )
}

export default Documents