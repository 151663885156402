import React, { useEffect, useState } from 'react';
import './clientDashboard.css';
import UserCaseCards from '../../components/UserCaseCards';
import { axiosRequestInterceptor } from '../../api/axiosInstance';

const ClientDashboard = () => {

    const userName = sessionStorage.getItem('SDX_user_name');
    const entityLogo = sessionStorage.getItem('SDX_entity_logo');
    const entityName = sessionStorage.getItem('SDX_entity_name');
    const [ casesData, setCasesData ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError] = useState('');

    const fetchClientData = async() => {
        try{
            setLoading(true);
            setError('');
            const api = axiosRequestInterceptor(true);
            const response = await api.get('/clients/getAllData');
            setCasesData(response.data.cases);
            setLoading(false);
        }catch(err){
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server');
        }
    }

    useEffect(()=> {
        fetchClientData();
    },[]) //eslint-disable-line

    return (
        <div className='user-page'>
            <div className='profile-bar'>
                <div>
                    <img src={entityLogo ? entityLogo : '/assets/def-logo.png'} alt='logo' className='user-header-logo' />
                    <span className='ms-2 fw-bold'>{entityName}</span>
                </div>
                <div className='user-detail align-items-center'>
                    <span className='fw-bold'>{userName}</span>
                </div>
            </div>
            <div className='scroll-window'>
                <div className='main-content'>
                {loading ? <div className='no-data-banner fw-bold pt-5'>Loading...</div>:
                error? <div className='no-data-banner fw-bold pt-5'>Falied to Fetch the Cases</div>:
                casesData.length === 0 ? <div className='no-data-banner fw-bold pt-5'>There is no Cases created yet</div>:
                casesData.map((caseData)=> {
                    return (
                        <UserCaseCards
                        key={caseData.id}
                        caseData={caseData} />
                    )
                })}
                </div>
                <div className='user-footer d-flex'>
                    <div className='m-auto'>Powered by Secure Document Exchange</div>
                </div>
            </div>
        </div>
    )
}

export default ClientDashboard