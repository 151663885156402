import React from 'react'
import './loginLeftHalf.css'

const LoginLeftHalf = (props) => {

    return (
      <div className='d-none d-md-block container-fluid login-lefthalf'>
        <div className='ellipse-box'>
          <div className='elipse-1'>
          </div>
          <div className='elipse-2'>
          </div>
        </div>
        <div className='banner'>
          <h1 style={{ color: 'lightblue' }}>{props.tagLine}</h1>
          <h2 style={{ color: 'white' }}>Welcome to the Future <img src='/assets/rocket.png' height='40px' alt='rocket' /></h2>
        </div>
      </div>
    );
  };

export default LoginLeftHalf