import { axiosRequestInterceptor } from '../../api/axiosInstance';
import React, { useEffect, useState } from 'react'

const ModifyCaseModal = (props) => {

    const initialState = { spoc_name: '', description: '', status: '' }
    const { isOpen, openModal, closeModal, caseData, fetchClientData } = props;
    const api = axiosRequestInterceptor(true);
    const [modalData, setModalData] = useState(initialState);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [validMsg, setValidMsg] = useState({ error: true });

    const fetchUsers = async () => {
        try {
            const response = await api.get(`/users/userdata`)
            setUsers(response.data);
        } catch (err) {
            if(err?.response?.data?.error) setError(err.response.data.error);
            else setError('Failed to connect with server')
        }
    };

    const handleChange = (e) => {
        const tempData = {
            ...modalData,
            [e.target.name]: e.target.value
        }
        setModalData(tempData);
    }

    const submitData = async () => {
        try {
            await api.put(`/clients/editCase`, { ...modalData, id: caseData.id })
            fetchClientData();
            clearData();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, tags: err.response.data.error })
            else setValidMsg({ error: true, tags:'Failed to connect with server' })
        }
    }

    const clearData = () => {
        closeModal();
        setModalData(initialState);
    }

    useEffect(() => {
        fetchUsers();
        let spoc_name = '';
        let description = '';
        let status = '';
        if (props.caseData.spoc_name !== null) {
            spoc_name = props.caseData.spoc_name;
        }
        if (props.caseData.description !== null) {
            description = props.caseData.description
        }
        status = props.caseData.status;
        setModalData({ spoc_name, description, status })
    }, [openModal]) //eslint-disable-line

    useEffect(() => {
        let error = false;
        let spoc_name = '';
        let description = '';
        if (!modalData.spoc_name) {
            error = true;
            spoc_name = 'Assignee is required'
        }

        if (!modalData.description) {
            error = true;
            description = 'Description is required'
        } else if (modalData.description.length < 3) {
            error = true;
            description = 'Description should contain more than 2 characters'
        }

        setValidMsg({ error, spoc_name, description });

    }, [modalData])

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h1 className='modal-title fs-5'>{caseData?.title}</h1>
                    </div>
                    <div className='modal-body px-5'>
                        <div>
                            <span>Assignee :</span>
                            <select
                                name='spoc_name'
                                value={modalData.spoc_name}
                                onChange={handleChange}
                                className='ms-2 dropdown-box'>
                                <option disabled value=''>Select assignee</option>
                                {users.map(user => <option key={user.id} value={user.name}>{user.name}</option>)}
                            </select>
                        </div>
                        {error && <div className='error-message'>{error}</div>}
                        <div className='error-message mt-2'>{validMsg?.spoc_name}</div>
                        <div>
                            <span>
                                Case Status: 
                            </span>
                            <select
                            name='status'
                            value={modalData.status}
                            onChange={handleChange}
                            className='ms-2 dropdown-box'>
                            <option value='Active' >Active</option>
                            <option value='Inactive'>Inactive</option>
                            <option value='Closed'>Closed</option>
                        </select>
                        </div>
                        <div className='req-text-area-field'>
                            <textarea
                                className='req-text-area w-100 ps-2'
                                type='text'
                                name='description'
                                value={modalData.description}
                                onChange={handleChange}
                                placeholder='Description' />
                            <label className='req-text-area-label'>Description</label>
                        </div>
                        {!validMsg?.spoc_name && <div className='error-message'>{validMsg?.description}</div>}
                    </div>
                    <div className='modal-footer'>
                        <button className='au-btn' onClick={clearData}>Cancel</button>
                        <button disabled={validMsg.error} className={validMsg.error ? 'au-btn-disabled' : 'au-btn'} onClick={submitData}>Confirm</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ModifyCaseModal