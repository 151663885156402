import React, { useEffect, useState } from 'react'
import './updateDocModal.css'
import FileViewer from '../FileViewer';
import { axiosRequestInterceptor } from '../../api/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faChevronDown, faChevronUp, faCloudArrowUp, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

const UpdateDocModal = (props) => {

    const initialState = { doc_name: '', description: '', remarks: '', files: '' }
    const { isOpen, closeModal, modalData, caseTitle, caseId, fetchRequestsData } = props;
    const api = axiosRequestInterceptor(true);
    const userName = sessionStorage.getItem('SDX_user_name');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorLoading, setErrorLoading] = useState('')
    const [fileList, setFileList] = useState([]);
    const [validMsg, setValidMsg] = useState({ error: true });
    const [historyShow, setHistoryShow] = useState(false);
    const [remHistory, setRemHistory] = useState([]);
    const [newData, setNewData] = useState(initialState);
    const [submitting, setSubmitting] = useState(false);
    const [viewFile, setViewFile] = useState({ file: null, view: false });

    const fetchUploadedDocs = async () => {
        try {
            const response = await api.get(`/documents/getDocData/${modalData.id}`);
            setUploadedFiles(response.data.documentsData)
            setRemHistory(response.data.remarksData)
        } catch (err) {
            console.error(err);
            if(err?.response?.data?.error) setErrorLoading(err.response.data.error);
            else setErrorLoading('Failed to connect with server');
        }
    }

    const viewSelectedFile = (file) => {
        setViewFile({ file, view: true });
    }

    const handleFiles = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const newList = [...fileList, ...selectedFiles];
        setFileList(newList);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        const droppedFiles = e.dataTransfer.files;
        const newList = [...fileList, ...droppedFiles];
        setFileList(newList);
    };

    const deleteFile = (selFile) => {
        const newList = fileList.filter((file) => file.name !== selFile.name);
        setFileList(newList);
    }

    const handleChange = (e) => {
        const tempData = {
            ...newData,
            [e.target.name]: e.target.value
        }
        setNewData(tempData);
    }

    const handleHistory = () => {
        setHistoryShow(prevState => !prevState);
    }

    const clearData = () => {
        closeModal();
        setNewData(initialState);
        setFileList([]);
        setSubmitting(false);
        setHistoryShow(false);
    }

    const submitData = async () => {
        try {
            setSubmitting(true);
            const formData = new FormData();
            fileList.forEach((file) => {
                formData.append('files', file);
            });
            formData.append('type_Req', modalData === 'newDoc' ? 'new' : 'old');
            formData.append('case_id', caseId);
            formData.append('req_id', modalData?.id);
            formData.append('doc_name', newData.doc_name);
            formData.append('description', newData.description);
            formData.append('remark', newData.remarks);
            formData.append('initiated_by', userName);
            await api.post('/documents/upload', formData);
            fetchRequestsData();
            clearData();
        } catch (err) {
            if(err?.response?.data?.error) setValidMsg({ error: true, remarks: err.response.data.error })
            else setValidMsg({ error: true, remarks: 'Failed to connect with server' })
            setSubmitting(false);
        }
    }

    useEffect(() => {
        if (modalData !== 'newDoc' && isOpen) {
            fetchUploadedDocs();
        }
    }, [isOpen]) //eslint-disable-line

    useEffect(() => {
        let error = false;
        let doc_name = '';
        let description = '';
        let remarks = '';
        let files = '';

        if (modalData === 'newDoc' && !newData.doc_name) {
            error = true;
            doc_name = 'Document name is required'
        } else if (modalData === 'newDoc' && newData.doc_name.length < 3) {
            error = true;
            doc_name = 'Document name should contain more than 2 characters'
        }

        if (modalData === 'newDoc' && !newData.description) {
            error = true;
            description = 'Description is required'
        } else if (modalData === 'newDoc' && newData.description.length < 3) {
            error = true;
            description = 'Description should contain more than 2 characters'
        }

        if (!newData.remarks) {
            error = true;
            remarks = 'Remarks is required'
        } else if (newData.remarks.length < 2) {
            error = true;
            remarks = 'Remarks should contain more than 1 character'
        }

        if (fileList.length === 0) {
            error = true;
            files = 'File is required'
        }
        setValidMsg({ error, doc_name, description, remarks, files })

    }, [modalData, newData, fileList]) //eslint-disable-line

    return (
        <div className={`modal ${isOpen ? 'modal-lg show d-block' : ''}`} tabIndex='-1' role='dialog'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    {viewFile.view ?
                        <FileViewer
                            file={viewFile.file}
                            setViewFile={setViewFile} /> : <>
                            <div className='modal-header'>
                                <h1 className='modal-title fs-5'>
                                    {caseTitle}
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                    {modalData?.doc_name}
                                </h1>
                            </div>
                            <div className='modal-body px-5'>
                                {submitting ? <div className='no-data-banner fw-bold pt-5'>Submitting the documents...</div> :
                                    <>
                                        {modalData === 'newDoc' ?
                                            <div>
                                                <div className='au-field'>
                                                    <input
                                                        className='au-input w-50 ps-2'
                                                        type='text'
                                                        name='doc_name'
                                                        value={newData.doc_name}
                                                        onChange={handleChange}
                                                        placeholder='Document name' />
                                                    <label className='au-label'>Document name</label>
                                                </div>
                                                <div className='error-message'>{validMsg?.doc_name}</div>
                                                <div className='req-text-area-field'>
                                                    <textarea
                                                        className='req-text-area w-100 ps-2'
                                                        type='text'
                                                        name='description'
                                                        value={newData.description}
                                                        onChange={handleChange}
                                                        placeholder='Description' />
                                                    <label className='req-text-area-label'>Description</label>
                                                </div>
                                                {!validMsg?.doc_name && <div className='error-message'>{validMsg?.description}</div>}
                                            </div> :
                                            <>
                                                {errorLoading ? <div>{errorLoading}</div> :
                                                    uploadedFiles?.length !== 0 && <table className='users-table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='table-head'>File name</th>
                                                                <th className='table-head'>File type</th>
                                                                <th className='table-head'>Date uploaded</th>
                                                                <th className='table-head'>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='table-body'>
                                                            {uploadedFiles?.map((file) => {
                                                                const options = {
                                                                    day: 'numeric',
                                                                    month: 'numeric',
                                                                    year: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                    hour12: true
                                                                }
                                                                const createdAtDate = new Date(file.createdAt);
                                                                const uploaded_date = createdAtDate.toLocaleString('en-AU', options);
                                                                let statusColor = '';
                                                                if(file.status === 'Accepted'){
                                                                    statusColor = 'green'
                                                                }else if(file.status === 'Rejected'){
                                                                    statusColor = 'red'
                                                                }else{
                                                                    statusColor = 'blue'
                                                                }
                                                                return (
                                                                    <tr key={file.id}>
                                                                        <td>{file.file_name}</td>
                                                                        <td>{file.file_type}</td>
                                                                        <td>{uploaded_date}</td>
                                                                        <td style={{color: statusColor}}>{file.status}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>}
                                            </>}
                                        <div className='mt-2'>
                                            Upload document
                                            <div>
                                                <label htmlFor='file-upload' className='label-for-input'>
                                                    <div
                                                        className='input-card mt-2'
                                                        onDragOver={(e) => handleDragOver(e)}
                                                        onDrop={(e) => handleDrop(e)}>
                                                        <FontAwesomeIcon icon={faCloudArrowUp} size='4x' />
                                                        <div>Drag and drop files here or click to select files</div>
                                                    </div>
                                                </label>
                                                <input
                                                    type="file"
                                                    id='file-upload'
                                                    style={{ display: 'none' }}
                                                    onChange={handleFiles}
                                                    multiple
                                                />
                                            </div>
                                            {!validMsg?.description && <div className='error-message mt-2'>{validMsg?.files}</div>}
                                        </div>
                                        {fileList.length > 0 && <div className='mt-2'>
                                            <table className='users-table mt-2'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-head'>File name</th>
                                                        <th className='table-head'>File type</th>
                                                        <th className='table-head'>Date uploaded</th>
                                                        <th className='table-head'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='table-body'>
                                                    {fileList?.map((file, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{file.name}</td>
                                                                <td>{file.type.slice(0, 20)}</td>
                                                                <td>{new Date().toLocaleString()}</td>
                                                                <td>
                                                                    <FontAwesomeIcon onClick={() => viewSelectedFile(file)} icon={faEye} className='hand-symbol me-2' size='lg' />
                                                                    <FontAwesomeIcon onClick={() => deleteFile(file, index)} className='hand-symbol' icon={faTrash} color='#208DA9' size='lg' />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <div className='req-text-area-field mt-4'>
                                            <textarea
                                                name='remarks'
                                                value={newData.remarks}
                                                onChange={handleChange}
                                                className='ps-1 w-100 req-text-area'
                                                placeholder='Remarks' />
                                            <label className='req-text-area-label'>Remarks</label>
                                        </div>
                                        {!validMsg?.files && <div className='error-message'>{validMsg?.remarks}</div>}
                                        {modalData !== 'newDoc' && <>
                                            <div className='mt-2 rem-history-title' onClick={handleHistory}>
                                                Remarks history
                                                <FontAwesomeIcon className='ms-2' icon={historyShow ? faChevronUp : faChevronDown} />
                                            </div>
                                            {historyShow && <div>
                                                {remHistory?.length === 0 ? <div className='mt-4'>No remarks history</div> :
                                                    <table className='users-table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='table-head'>Added by</th>
                                                                <th className='table-head'>Remarks</th>
                                                                <th className='table-head'>Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='table-body'>
                                                            {remHistory?.map((rem, index) => {
                                                                const options = {
                                                                    day: 'numeric',
                                                                    month: 'numeric',
                                                                    year: 'numeric',
                                                                    hour: 'numeric',
                                                                    minute: 'numeric',
                                                                    hour12: true
                                                                }
                                                                const createdAtDate = new Date(rem.createdAt);
                                                                const added_date = createdAtDate.toLocaleString('en-AU', options);
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{rem.added_by}</td>
                                                                        <td>{rem.remark}</td>
                                                                        <td>{added_date}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>}
                                            </div>}
                                        </>}
                                    </>}
                            </div>
                            <div className='modal-footer'>
                                <button
                                    disabled={submitting}
                                    className={submitting ? 'au-btn-disabled' : 'au-btn'}
                                    onClick={clearData}>
                                    Cancel</button>
                                <button
                                    disabled={validMsg.error || submitting}
                                    className={validMsg.error || submitting ? 'au-btn-disabled' : 'au-btn'}
                                    onClick={submitData}>
                                    Submit</button>
                            </div>
                        </>}
                </div>
            </div>
        </div>
    )
}

export default UpdateDocModal